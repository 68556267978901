import { useState, useEffect } from 'react';

export default function useIntersect(ref, rootMargin = '0px', threshold = 0.3) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => { setIntersecting(entry.isIntersecting); },
            { rootMargin, threshold }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            observer.unobserve(ref.current);
        };
    }, []);

    return isIntersecting;
}
