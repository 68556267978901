/**
 * Finds the highest row number in a placement.
 * @param {array} placement The array of col/row configs.
 */
const findRowsNeeded = placement => {
    let rows = 1;
    placement.forEach(p => {
        if (p.row.e > rows) {
            rows = p.row.e;
        }
    });
};

export const getRowCount = converted => {
    const rows = findRowsNeeded(converted.placement);
    const templateRows = 'auto '.repeat(rows);

    return {
        converted,
        templateRows
    };
};
