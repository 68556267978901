// import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
// import fetch from 'unfetch';
import React, { useState, useEffect, useContext, useCallback } from 'react';
//import { createContext, useContext } from 'solid-js';
//import { createStore } from 'solid-js/store';
import md5 from 'md5';
import { isServer } from '../lib/is-server';

//const GraphqlContext = createContext({client: {}, cache: {}});
export const GraphqlContext = React.createContext({ client: {}, cache: {} });

export const GraphqlContextProvider = (props) => {

    // Super simple cache
    const [cache, setCache] = useState(props.cache || {});

    const setCacheCallback = (key, value) => {
        setCache({
            ...cache,
            [key]: value
        });
    };

    const store = {
        client: props.client,
        cache,
        setCache: setCacheCallback,
    };

    return (
        <GraphqlContext.Provider value={store}>
            { props.children }
        </GraphqlContext.Provider>
    );
};

export const useQuery = (query, variables = {}, options = {}, asPromise = false) => {

    // Make it compatible with apollo 
    if (variables?.variables) {
        variables = variables.variables;
    }

    const context = useContext(GraphqlContext);

    const hash = md5(query + JSON.stringify(variables));

    let content = context.cache[hash];
    const [state, setState] = useState({data: content || null, loading: !content, error: false, fetchMore: () => {}});

    if (!isServer) {
        const fetchMore = useCallback((fetchMoreOptions) => {
            context.client(query, { ...variables, ...fetchMoreOptions.variables}, options).then((result) => {
                if (fetchMoreOptions.updateQuery) {
                    //console.log('state.data', state.data);
                    const newData = fetchMoreOptions.updateQuery(state.data, { fetchMoreResult: result });
                    //console.log('new newData', newData, state);
                    setState({ ...state, data: newData, loading: false, fetchMore });
                }
            });
        }, [state]);
        useEffect(() => {
            context.client(query, variables, options).then((result) => {
                setState({ data: result, loading: false, fetchMore });
                context.setCache(hash, result);
            });
        }, [hash]);
        return state;
    }

    if (state.content) {
        //setState({ data: content, loading: false });
    } else {

        if (asPromise) {
            return context.client(query, variables, options)
                .then(result => {
                    context.setCache(hash, result.data);
                    return { data: result.data };
                });
        }
        if (!options.skip) {
            //setState({ ... state, loading: true });
            context.client(query, variables, options).then((result) => {
                if (result) {
                    setState({ data: result.data, loading: false });
                    context.setCache(hash, result.data);
                }
                context.setCache(hash, null);
            });
        } else {
            setState({...state, loading: false});
        }
    }

    return state;

};

