import styled from '@emotion/styled';

export const StyledImage = styled('div')`

    width: 100%;

    .image {
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;

    .image--fit {
        display: block;
    }

    .image--picture {
        width: 100%;
    }

    .image--text {
        text-align: center;
    }

    .image--lowres {
        width: 100%;
        object-fit: cover;
        left: 0;
        top: 0;
        opacity: 0;
        animation: image--animate 0.5s ease-out forwards;
    }

    @keyframes image--animate {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .image--lowres--transition-ended {
        display: none;
        opacity: 0;
        animation: none;
    }

    .image--fullres {
        width: 100%
        height: 100%
        object-fit: cover;
        left: 0;
        top: 0;
        position: absolute;
        opacity: 0;
    }

    .image--fullres-transition-ended {
        position: static;
        height: auto;
    }

    .image--fullres-external {
        object-fit: cover;
        width: 100%;
        height: auto;
    }

    .image--fullres-loaded {
        animation: image--animate 0.8s ease-out forwards;
        display: block;
    }
`;
