/**
 * Detects whether a certain CSS rule has support in the users browser
 * The returned object is available in our react context
 * @returns {object} - {featureOne: true, featureTwo: false}
 */
function getFeatureSupport(userAgent) {

    if (!userAgent) {
        return {};
    }

    const browser = userAgent.browser;

    const featureSupport = {};

    featureSupport.objectFit = supportsObjectFit(browser);
    featureSupport.cssVars = supportsCSSVars(browser);
    featureSupport.canUseWebP = canUseWebP(browser);
    featureSupport.fullFlexSupport = supportsFlexBoxFully(browser);

    return featureSupport;

}


function supportsObjectFit(browser) {
    if (browser.name === 'IE') {
        return false;
    }
    if (browser.name === 'Edge' && browser.version < 16) {
        return false;
    }
    return true;
}

function supportsCSSVars(browser) {
    if (browser.name === 'IE') {
        return false;
    }
    if (browser.name === 'Edge' && browser.version < 16) {
        return false;
    }
    return true;
}

function canUseWebP(browser) {

    if (browser.name === 'Chrome') {
        return true;
    }
 
    const elem = typeof window !== 'undefined' && document.createElement('canvas');

    if (elem && elem.getContext && elem.getContext('2d')) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }
    else {
        // very old browser like IE 8, canvas not supported
        return false;
    }

}

function supportsFlexBoxFully(browser) {
    if (browser.name === 'IE') {
        return false;
    }
    return true;
}


export default getFeatureSupport;
