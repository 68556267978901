import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';
import Text from '../text/text';

const alignMap = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
};

const paragraphStyles = (theme, alignSelf, last, direction, textAlignment) => ({
    small: css`
        width: ${direction === 'column' ? '100%' : 'inherit'};
        align-self: ${alignMap[alignSelf]};
        max-width: ${theme.contentMaxWidth};
        padding-bottom: ${direction === 'row' || last ? 0 : theme.spacing['base']};
        padding-left: ${direction === 'column' ? theme.spacing['base'] : '0'};
        padding-right: ${direction === 'column' ? theme.spacing['base'] : '0'};
        margin-left: ${direction === 'row' ? 'auto' : '0'};
        margin-right: ${direction === 'row' ? 'auto' : '0'};
        align-self: ${direction === 'row' ? 'center' : ''};
        text-align: ${textAlignment};
    `,
    normal: css`
        width: ${direction === 'column' ? '100%' : 'inherit'};
        align-self: ${alignMap[alignSelf]};
        max-width: ${theme.contentMaxWidth};
        padding-bottom: ${direction === 'row' || last ? 0 : theme.spacing['base']};
        margin-left: ${direction === 'column' ? 'auto' : '0'};
        margin-right: ${direction === 'column' ? 'auto' : '0'};
        padding-left: ${direction === 'row' ? theme.spacing['base'] : '0'};
        padding-right: ${direction === 'row' ? theme.spacing['base'] : '0'};
        font-weight: 300;
        align-self: ${direction === 'row' ? 'center' : ''};
        text-align: ${textAlignment};
    `,
    large: css`
        width: ${direction === 'column' ? '100%' : 'inherit'};
        align-self: ${alignMap[alignSelf]};
        max-width: ${theme.contentMaxWidth};
        padding-bottom: ${direction === 'row' || last ? 0 : theme.spacing['large']};
        @media (${theme.breakpoints.mobile}) {
            padding-bottom: ${last ? 0 : theme.spacing.base};
        }
        margin-left: ${direction === 'column' ? 'auto' : '0'};
        margin-right: ${direction === 'column' ? 'auto' : '0'};
        padding-left: ${direction === 'row' ? theme.spacing['base'] : '0'};
        padding-right: ${direction === 'row' ? theme.spacing['base'] : '0'};
        font-weight: 300;
        align-self: ${direction === 'row' ? 'center' : ''};
        text-align: ${textAlignment};
    `
});

// note: useSetInnerHtml defaults to true
const Paragraph = ({ children, theme, fontSize, alignSelf = 'left', useSetInnerHtml, last = false, direction = 'column', textAlignment }) => {

    const pStyle = paragraphStyles(theme, alignSelf, last, direction, textAlignment)[fontSize];

    return (
        <Text as='p' size={fontSize} css={pStyle} useSetInnerHtml={ useSetInnerHtml }>
            {children}
        </Text>
    );

};

Paragraph.defaultProps = {
    fontSize: 'normal',
    textAlignment: null,
};

Paragraph.propTypes = {
    fontSize: PropTypes.oneOf(['small', 'normal', 'large']).isRequired,
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            lighterText: PropTypes.string.isRequired,
            darkerText: PropTypes.string.isRequired,
            black: PropTypes.string.isRequired
        })
    }).isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    alignSelf: PropTypes.oneOf(['center', 'left', 'right', null]),
    direction: PropTypes.oneOf(['row', 'column', null]),
    useSetInnerHtml: PropTypes.bool,
    last: PropTypes.bool,
    textAlignment: PropTypes.oneOf(['center', 'left', 'right', null]),
};

Paragraph.vcConvertToProps = atts => {
    return {
        fontSize: atts.fontSize,
        textAlignment: atts.textAlignment
    };
};

export default withTheme(Paragraph);
