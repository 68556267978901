import { css, jsx, keyframes } from '@emotion/core';
import styled from '@emotion/styled';

import React, { useState, useEffect } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import Image from '../image';
import Heading from '../heading/heading';
import Text from '../text/text';
import IntersectionObserver from '../intersection-observer';
import { AppContext }  from '../../app-context-provider/provider';
import { withTheme } from '@emotion/react';

import calculateGrid from './calculate-grid';
import { innerCss, textCss, contentCss, typeCss } from './masonry.style';

const StyledAnimationContainer = styled.div`
    width: 100%;
    min-height: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    //padding-left: 30px;
    //margin-left: -30px;
`;

const StyledBackAnimationElement1 = styled.div`
    background-color: ${p => p.theme.colors.sky};
    width: 100%;
    min-height: 1005;
    height: 100%;
    height: 30px;
    position: absolute;
    transition: transform .3s ease-out;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    bottom: -30px;
`;

const StyledBackAnimationElement2 = styled.div`
    background-color: ${p => p.theme.colors.pear};
    width: 100%;
    min-height: 1005;
    height: 100%;
    position: absolute;
    height: 30px;
    transition: transform .3s ease-out .05s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    bottom: -30px;
`;

const StyledBackAnimationElement3 = styled.div`
    background-color: ${p => p.theme.colors.lemon};
    width: 100%;
    min-height: 1005;
    height: 100%;
    position: absolute;
    height: 30px;
    transition: transform .3s ease-out .1s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    bottom: -30px;
`;

const StyledBackAnimationElement4 = styled.div`
    background-color: ${p => p.theme.colors.tomato};
    width: 100%;
    min-height: 1005;
    height: 100%;
    position: absolute;
    height: 30px;
    transition: transform .3s ease-out .15s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    bottom: -30px;
`;

const preambleCss = css`
    padding-top: .5rem;
`;

const switchPoints = {
    tablet: 1100,
    mobile: 650,
};

const gap = 48;

class Masonry extends React.Component {

    static contextType = AppContext;

    constructor (props) {

        super(props);

        let currentItems = props.items || [];
        let currentItemsMap = props.itemsMap || {};

        let newItems = props.items
            .filter(item => !currentItemsMap[item._id])
            .map(item => {
                currentItemsMap[item._id] = true;
                return { ...item };
            });

        newItems.forEach((item) => {
            if (!currentItems.find(currentItem => currentItem._id === item._id)) {
                currentItems.push(item);
            }
        });

        this.state = {
            visibles: {},
            animated: {},
            items: currentItems,
            timeoutRef: {},
            itemsMap: currentItemsMap,
        };

    }

    static getDerivedStateFromProps(nextProps, prevState){

        let currentItems = [...prevState.items] || [];
        let currentItemsMap = prevState.itemsMap || [];

        let newItems = nextProps.items
            .filter(item => !currentItemsMap[item._id])
            .map(item => {
                currentItemsMap[item._id] = true;
                return { ...item };
            });

        newItems.forEach((item) => {
            if (!currentItems.find(currentItem => currentItem._id === item._id)) {
                currentItems.push(item);
            }
        });

        if (currentItems && prevState.items && currentItems.length === prevState.items.length) {
            return null;
        }

        return {
            items: currentItems,
            itemsMap: currentItemsMap,
        };

    }

    goto (e, url) {
        this.props.onClick(e, url);
    }

    visible (_id) {
        return () => {
            this.setState({
                visibles: {...this.state.visibles, [_id]: true},
            });
        };
    }

    getPostList () {

        const { items: preparsedItems } = this.state;
        const { isMultiTypeList } = this.props;

        const gridWidth = getGridWidth(this.context);
        const gridSingleWidth = getSingleGridWidth(this.props, this.context);
        let { items, heights } = calculateGrid(gridSingleWidth, preparsedItems, gridWidth, this.props.uneven, isMultiTypeList);

        items = items || [];

        const postList = items.map((item, index) => {

            let image = null;
            const imageObject = {...item.image};
            delete imageObject.width;
            delete imageObject.height;

            let imageCss = css`
                overflow: hidden;
                min-height: 150px;
                background-color: #aaa;
                transition: height .3s ease-out;
            `;

            const height = item.grid.size.sizes.imageHeight;
            const itemWidthString = item.grid.itemWidthString;
            const itemHeight = item.grid.itemHeight;
            const posY = item.grid.position.y;

            if (item.image) {
                image = (<Image alt_text={item.title} defaultWidth={1000} key={'image-' + item._id} jwt={item.image.jwt} src={item.image.url} cover={false} height={height} threshold={.01} css={css`height: 100;transition: height .3s ease-out;`} />);
                imageCss = css`
                    ${imageCss};
                `;
            } else {
                imageCss = css`
                    ${imageCss};
                    background-color: ${item.backgroundColor};
                `;
            }

            let debugInfo = (
                <div style={{backgroundColor: 'rgba(255, 255, 255, .5)', borderRadius: '2px', padding: '3px 5px 2px', lineHeight: '1em', top: '4px', color: 'black', position: 'absolute', right: '4px', zIndex: 1000, fontSize: '10px', }}>
                    {item.grid.size.width}x{item.grid.size.height} 
                    &nbsp;{item.grid.__masonryId}
                    &nbsp;h{item.grid.size.sizes.imageHeight}px
                    &nbsp;wi{item.grid.size.width}
                    &nbsp;a{item.grid.size.sizes.aspectRatio.toFixed(2)}
                    &nbsp;id{item._id}
                    &nbsp;
                    ({item.grid.size.sizes.imageSize}+{item.grid.size.sizes.titleSize}+{item.grid.size.sizes.preambleSize})
                </div>
            );

            const linkCss = css`
                position: absolute;
                display: flex;
                width: calc( ${itemWidthString} );
                height: ${itemHeight}px;
                transform: translate3d(calc( 100% * ${item.grid.position.x} + ${48 * (item.grid.position.x)}px), ${posY}px, 0);
                &:hover ${StyledBackAnimationElement1} {
                    transform: translate3d(0, -24px, 0);
                }
                &:hover ${StyledBackAnimationElement2} {
                    transform: translate3d(0, -22px, 0);
                }
                &:hover ${StyledBackAnimationElement3} {
                    transform: translate3d(0, -19px, 0);
                }
                &:hover ${StyledBackAnimationElement4} {
                    transform: translate3d(0, -15px, 0);
                }
            `;


            let animatedContent = css`
                width: 100%;
                height: 100%;
                flex: 1;
                display: flex;
                flex-direction: column;
                background-color: #aaa;
                box-shadow: 0px 6px 20px rgba(0, 0, 0, .05);
           `;

            if (index >= 0) {

                const visibleItem = this.state.visibles[item._id];

                const showAnimation = keyframes`
                    0% {
                        opacity: .1;
                        transform: translate3d(0, ${item.grid.displacement}rem, 0);
                    }
                    100% {
                        opacity: 1;
                        transform: translate3d(0, 0, 0);
                    }
                `;

                animatedContent = css`
                    ${animatedContent}
                    animation: ${showAnimation} 1s ease forwards;
                    display: ${visibleItem ? 'flex' : 'none'};
                    opacity: .1;
                    top: 0;
                    transform: translate3d(0, 2rem, 0);
                `;

            }

            const typeLabel = <div css={ typeCss }>{ postNamesDictionary[item.type] }</div>;

            return (
                <a href={item.permalink} aria-label={'Till ' + item.title} css={linkCss} key={item._id} onClick={(e) => this.goto(e, item.permalink)}>
                    { process.env.NODE_ENV !== 'production' ? debugInfo : null }
                    <div css={innerCss}>
                        <IntersectionObserver css={contentCss} onVisible={this.visible(item._id)} threshold={0.01}>
                            <div css={animatedContent}>
                                <div css={imageCss}>{image}</div>
                                <div css={textCss}>
                                    { isMultiTypeList ? typeLabel : null }
                                    <Heading as="h6" noPadding={true}>{item.title}</Heading>
                                    { item.preamble && (<div css={preambleCss}><Text>{item.preamble}</Text></div>) }
                                </div>
                                <StyledAnimationContainer>
                                    <StyledBackAnimationElement1 />
                                    <StyledBackAnimationElement2 />
                                    <StyledBackAnimationElement3 />
                                    <StyledBackAnimationElement4 />
                                </StyledAnimationContainer>
                            </div>
                        </IntersectionObserver>
                    </div>
                </a>
            );

        });

        return {
            heights,
            postList,
        };

    }

    render () {

        const {
            heights,
            postList,
        } = this.getPostList();

        const masonryCss = css`
            display: block;
            position: relative;
            height: ${Math.max.apply(null, heights)}px;
            transition: height 1s ease-out;
        `;

        let moreButton = null;
        if (this.props.loadMoreButton) {
            moreButton = (
                <div css={css`margin-top: 2rem;`}>{this.props.loadMoreButton}</div>
            );
        }

        return (
            <>
                <div css={masonryCss}>
                    {postList}
                </div>
                {moreButton}
            </>
        );

    }

}

const getSingleGridWidth = (props, context) => {
    const gridWidth = getGridWidth(context);
    const gridSingleWidth = (props.width - gap * ( gridWidth - 1 )) / gridWidth;
    return gridSingleWidth;
};

const getGridWidth = (context) => {
    let gridWidth = 3;
    if (context.meta.viewport.width < switchPoints.tablet) {
        gridWidth = 2;
    }
    if (context.isMobile() || context.meta.viewport.width < switchPoints.mobile) {
        gridWidth = 1;
    }
    return gridWidth;
};

const postNamesDictionary = {
    post: 'BLOGG',
    project: 'PROJEKT',
    news: 'NYHET',
    podcast: 'PODCAST',
    page: 'SIDA',
};


Masonry.propTypes = {
    viewport: PropTypes.object,
    items: PropTypes.array.isRequired,
    uneven: PropTypes.bool,
    onClick: PropTypes.func,
    width: PropTypes.number,
    isMultiTypeList: PropTypes.bool,
    loadMoreButton: PropTypes.object,
    theme: PropTypes.object,
};

Masonry.calculateGrid = calculateGrid;

export default withTheme(Masonry);
