import React from 'react';
import Heading from '../heading/heading';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import Text from '../text/text';
import PropTypes from 'prop-types';

const ResponsiveImage = styled.img`
    max-width: 100%;
    height: auto;
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
`;

const ParagraphContainer = styled.div`
    display: flex;
    margin: 1rem 0 5rem;
    justify-content: center;
`;

const ErrorPage = ({ goBack }) => {

    const theme = useTheme();

    const headingProps = {
        as: 'h1',
        alignSelf: 'center',
    };

    const paragraphProps = {
        size: 'large',
        useSetInnerHtml: false,
        alignSelf: 'center',
    };

    const Page = styled.div`
        background-color: ${theme.colors.plummyLight};
        width: 100%;
        height: 100%;
        justify-content: center;
        padding: 0 3rem;
    `;

    const Backlink = styled.span`
        font-weight: 400;
        border-bottom: 2px solid rgba(0, 0, 0, 0.25);
        padding-bottom: 0.15em;
        transition: padding-bottom .1s ease-in, border-bottom-color .1s ease-in;
        cursor: pointer;
        &:hover {
            padding-bottom: 0.5rem;
            border-bottom-color: rgb(0, 0, 0);
        }
    `;

    return (
        <Page>
            <Heading { ...headingProps }>Fel på datan</Heading>
            <ParagraphContainer>
                <Text { ...paragraphProps }>
                    Här har det blivit fel på datan! <Backlink onClick={ goBack }>Ta mig tillbaka.</Backlink>
                </Text>
            </ParagraphContainer>
            <ImageContainer>
                <ResponsiveImage src="/assets/images/vintage-car-wrecked.jpg" />
            </ImageContainer>
        </Page>
    );

};

ErrorPage.propTypes = {
    goBack: PropTypes.func,
};

export default ErrorPage;