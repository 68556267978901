import React from 'react';
import PropTypes from 'prop-types';
import { componentList } from './components';
import styled from '@emotion/styled';

import convertToVirtualSections from './convert-to-virtual-sections';

const Dynamic = styled.div`
    width: 100%;
    z-index: 1;
`;

const DynamicError = styled(Dynamic)`
    width: 100%;
    border: 1px solid red;
    background-color: #ffe9e9;
    padding: 3rem;
    color: red;
    text-align: center;
    box-sizing: border-box;
    > span {
        font-weight: 800;
    }
`;


function convertToProps (component, attributes, parentProps, childrenData) {
    let Module = componentList[component];
    if (Module && typeof Module.parseProps === 'function') {
        return Module.parseProps(Object.assign({}, parentProps, attributes), childrenData); // Override components attributes with parent (if they both share the name attribute)
    } else if (Module && typeof Module.vcConvertToProps === 'function') {
        return Module.vcConvertToProps(Object.assign({}, parentProps, attributes), childrenData); // Old name of function
    }
    return attributes;
}

class DynamicModuleGenerator extends React.Component {

    static propTypes = {
        content: PropTypes.any,
        viewport: PropTypes.object,
        mobile: PropTypes.string,
        permalink: PropTypes.string
    };

    renderComponent(components, parentProps = {}) {

        if (!components) {
            return;
        }

        let items = components.map((component, index) => {

            let componentName = componentList[component.blockName];

            if (!componentName) {
                console.error('Could not match VC module of type "%s"', component.blockName); // eslint-disable-line no-console
                return (
                    <DynamicError>
                        Could not ind a matching component for <span>{component.blockName}</span>
                    </DynamicError>
                );
            }

            let outerProps = {};

            if (component.blocks && component.blocks.length > 0) {
                outerProps.numberOfChildren = component.blocks.length;
            }

            let attributes = { ...component };
            delete attributes.blocks; // We dont want to pass the array of children, that goes as prop called children
            let passProps = Object.assign(attributes || {}, outerProps);

            // Combine all the props based on what the current module wants
            passProps = convertToProps(component.blockName, passProps, parentProps, component.blocks);


            // If there are some props we want to pass down to every child, we do it here
            passProps.permalink = parentProps.permalink;
            passProps.moduleName = component.blockName;
            passProps.viewport = this.props.viewport;

            let children = component.blocks && component.blocks.length > 0 ? this.renderComponent(component.blocks, passProps) : null;
            if (children === null && component.content) {
                children = component.content;
            }

            return React.createElement(componentName, {
                key: index,
                children: children,
                itemIndex: index,
                itemTotal: components.length,
                ...passProps
            });
        });

        return items;
    }

    render() {
        let content = convertToVirtualSections(this.props.content);
        return (
            <Dynamic>{this.renderComponent(content, { permalink: this.props.permalink, mobile: this.props.mobile, viewport: this.props.viewport })}</Dynamic>
        );
    }
}

export default DynamicModuleGenerator;
