import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const GTM = ({gtmCode}) => {

    if (!gtmCode) {
        return null;
    }

    const scripts = `
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            originalLocation: document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search
        }); 
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmCode}');
    `;
 
    return (
        <Helmet>
            <script>{scripts}</script>
        </Helmet>
    );

};

GTM.propTypes = {
    gtmCode: PropTypes.string,
};

export default GTM;
