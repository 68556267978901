import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Link from '../link';
// import { setDataLayer } from '../../lib/gtm';
import Section from '../section/section';
import theme from '../../emotion/theme';
import Anime from 'react-anime';

const style = css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    background-color: ${theme.colors.lightGray};
`;

const textStyle = css`
    font-size: ${theme.fontSizes.xlarge};
    font-family: ${theme.fonts.header1};
    color: ${theme.colors.almostBlack};
    margin-top: ${theme.spacing.base};
    margin-bottom: ${theme.spacing.base};
    @media (${theme.breakpoints.mobile}) {
        font-size: 3.5rem;
    }
`;

const textWrapperStyle = css`
    display: flex;
    align-items: center;
    height: 80vh;
`;

const HeaderContainer = styled.div`
    padding: 3vw 0;
`;

const startPageLinkStyle = css`
    font-size: ${theme.fontSizes.xsmall};
    color: ${theme.colors.almostBlack};
`;

const ColorBox = styled.div`
    background: ${theme.gradients['blue-purple']};
    position: fixed;
    right: 0;
    border-color: black;
    height: 50vh;
    width: 33vw;
    z-index: -1;
`;

const TextInnerWrapper = styled.div`
    display: flex;
    @media (${theme.breakpoints.mobile}) {
        flex-direction: column;
    }
`;

const animeProps = {
    translateX: 10,
    easing: 'easeInOutBack(1, .2)',
    delay: (el, i) => i * 100,
    direction: 'alternate',
    loop: true,
};

const splitNotFoundTextchars = (string, viewportWidth) => {
    const splitChar = viewportWidth < 769 ? ' ' : ''; // could not use variable from theme as it is not a number

    return string.split(splitChar).map( (char,i) => {
        if( char === ' ') {
            return <div key={`not-found-letter-${i}`} css={textStyle}>&nbsp;</div>;
        } else {
            return <div key={`not-found-letter-${i}`} css={textStyle}>{char}</div>;
        }
    });
};

const NotFound = ({viewport}) => {
    return (
        <div css={style}>
            <Section template={[{ width: '1/1' }]} viewport={viewport} verticalPadding="none">
                <div>
                    <HeaderContainer>
                        <Link to="/">
                            <div css={startPageLinkStyle}>Gå tillbaka till startsidan</div>
                        </Link>
                    </HeaderContainer>
                    <div css={textWrapperStyle}>
                        <TextInnerWrapper>
                            <Anime {...animeProps}>
                                {splitNotFoundTextchars('Sidan finns inte', viewport.width)}
                            </Anime>
                        </TextInnerWrapper>
                        <ColorBox />
                    </div>
                </div>
            </Section>
        </div>
    );
};

NotFound.propTypes = {
    viewport: PropTypes.object,
};

export default NotFound;
