import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { withTheme } from '@emotion/react';

import Image from '../image';
import Link from '../link';
import Text from '../text/text';

class WrappedImage extends React.Component {

    static parseProps(atts) {
        let newProps = {
            width: '100%',
            useNativeWidth: atts.use_native_width,
            imageText: atts.image_text ? atts.image_text : '',
            customAltText: atts.custom_src_alt_text,
            customImageHeight: atts.custom_image_height,
            imageIsLink: atts.image_is_link ? true : false,
            imageLinkUrl: atts.image_link_url,
            imageVersion: atts.imageVersion,
            src: atts.image?.url,
            jwt: atts.image?.jwt,
            ratio: atts.image?.width / atts.image?.height,
            focus: atts.image?.focus,
            widthType: atts.widthType,
            widthInPixels: atts.widthInPixels,
            caption: atts.caption,
            copyright: atts.copyright,
        };
        return newProps;
    }

    static propTypes = {
        src: PropTypes.string,
        service: PropTypes.oneOf(['aws-s3', 'local']),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        ratio: PropTypes.number,
        imageText: PropTypes.string,
        alt_text: PropTypes.string,
        className: PropTypes.string,
        useNativeWidth: PropTypes.string,
        original_width: PropTypes.number,
        original_height: PropTypes.number,
        minHeight: PropTypes.number,
        customImageHeight: PropTypes.string,
        imageIsLink: PropTypes.bool,
        imageLinkUrl: PropTypes.string,
        imageVersion: PropTypes.string,
        theme: PropTypes.any,
        last: PropTypes.bool,
        rounded: PropTypes.bool,
        jwt: PropTypes.string,
        isBackground: PropTypes.bool,
        widthType: PropTypes.string,
        widthInPixels: PropTypes.number,
        caption: PropTypes.string,
        copyright: PropTypes.string,
        columnCount: PropTypes.number,
        columnIndex: PropTypes.number,
        itemIndex: PropTypes.number,
        itemTotal: PropTypes.number,
    };

    static defaultProps = {
        rounded: true,
        isBackground: false,
    };

    constructor(props) {
        super(props);
    }

    imageElement() {
        const { minHeight, customImageHeight } = this.props;

        if (minHeight) {
            return <Image fit='false' height={minHeight} {...this.props} />;
        }
        if (customImageHeight) {
            return <Image fit='false' height={customImageHeight} {...this.props} />;
        }
        return <Image {...this.props} width={null} height={null} />;
    }

    render() {
        const { 
            imageLinkUrl, 
            imageIsLink, 
            theme, 
            last, 
            isBackground,
            widthType,
            widthInPixels,
            caption,
            copyright,
            itemIndex,
            itemTotal,
        } = this.props;

        const imageElement = this.imageElement();

        const height = isBackground ? '100%' : 'auto';

        let mainCss = css`
            height: ${height};
            width: 100%;
            padding: ${last ? 0 : '0rem 0rem 3rem 0rem'};
            @media screen and (${theme.breakpoints.mobile}) {
                padding: ${last ? 0 : '0rem 0rem 4rem 0rem'};
            }
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex-shrink: 0;
            }
        `;

        if (widthType === 'text-width' || !widthType ) {
            mainCss = css`
                ${mainCss}
                margin-left: auto;
                margin-right: auto;
                max-width: ${theme.contentMaxWidth};
            `;
        } else if (widthType === 'wider') {
            mainCss = css`
                ${mainCss}
                margin-left: auto;
                margin-right: auto;
                max-width: 64rem;
            `;
        } else if (widthType === 'custom') {
            mainCss = css`
                ${mainCss}
                margin-left: auto;
                margin-right: auto;
                max-width: ${widthInPixels}px;
            `;
        } else if (widthType === 'outsideOfFlow') {
            if (itemIndex === 0) {
                mainCss = css`
                    ${mainCss}
                    width: unset;
                    min-width: 100%;
                    margin-left: -75%;
                    margin-top: auto;
                    margin-bottom: auto;
                    @media screen and (${theme.breakpoints.mobile}) {
                        margin-left: auto;
                    }
                 `;
            } else if (itemIndex === itemTotal - 1) {
                mainCss = css`
                    ${mainCss}
                    width: unset;
                    min-width: 100%;
                    margin-right: -75%;
                    margin-top: auto;
                    margin-bottom: auto;
                    @media screen and (${theme.breakpoints.mobile}) {
                        margin-right: auto;
                    }
                `;
            }
        }

        let captionElement;
        if (caption) {
            const elementCss = css`
                padding-right: 2rem;
                color: ${theme.colors.darkGray};
                min-width: 60%;
            `;
            captionElement = (
                <div css={elementCss}><Text size="small">{caption}</Text></div>
            );
        }

        let copyrightElement;
        if (copyright) {
            const elementCss = css`
                text-align: right;
                width: 100%;
            `;
            copyrightElement = (
                <div css={elementCss}><Text size="small" color={theme.colors.darkerGray} italic={true}>{copyright}</Text></div>
            );
        }

        let textElement;
        if (captionElement || copyrightElement) {
            const elementCss = css`
                padding-top: .5rem;
                display: flex;
            `;
            textElement = (
                <div css={elementCss}>{captionElement}{copyrightElement}</div>
            );
        }

        if (imageIsLink) {
            return (
                <Link to={imageLinkUrl} css={mainCss}>
                    {imageElement}
                    {captionElement}
                </Link>
            );
        } else {
            return (
                <div css={mainCss}>
                    {imageElement}
                    {textElement}
                </div>
            );
        }
    }
}

export default withTheme(WrappedImage);
