import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const PageTypeContext = React.createContext({});

const PageTypeProvider = ({ children }) => {
    const [currentPageType, setCurrentPageTypeState] = useState('page');

    const setCurrentPageType = newPageType => {
        setCurrentPageTypeState(newPageType);
    };

    const value = {
        setCurrentPageType,
        currentPageType,
    };

    return <PageTypeContext.Provider value={value}>{children}</PageTypeContext.Provider>;
};

PageTypeProvider.propTypes = {
    children: PropTypes.any
};

// A 'useMenu' hook that can be used by any of the sub children to the menu provider.
export const usePageTypeContext = () => {
    const pageType = useContext(PageTypeContext);
    return pageType;
};

export default PageTypeProvider;