import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useMenu } from '../menu-provider';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { menuAnimations } from './keyframes';
import { useQuery } from '../../../graphql-client/graphql-client';
import gql from 'graphql-tag';
import LinkWrapper from '../../../components/link';
import { useAppContext } from '../../../app-context-provider/provider';
import { setDataLayer } from '../../../lib/gtm';

import svgFacebook from '../../../../client/assets/svg/facebook-app-symbol.svg';
import svgTwitter from '../../../../client/assets/svg/twitter-logo.svg';
import svgInstagram from '../../../../client/assets/svg/instagram.svg';

const SMicons = {
    facebook: {
        url: svgFacebook,
        alt: 'Facebook icon'
    },
    twitter: {
        url: svgTwitter,
        alt: 'Twitter icon'
    },
    instagram: {
        url: svgInstagram,
        alt: 'Instagram icon'
    }
};

const GlobalMenuContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const MenuContainer = styled.div`
    color: ${props => props.theme.colors.yellow};
    height: 100%;
    margin-right: 20%;
    margin-top: 80px;
    margin-left: 20%;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const ColoredEdge = styled.div`
    animation: ${props => {
        switch (props.status) {
            case 'open':
                return props.animIn;
            case 'closed':
                return props.animOut;
        }
        return 'none';
    }};
    animation-delay: ${props => {
        switch (props.status) {
            case 'open':
                return props.delay;
            case 'closed':
                return '0s';
        }
        return 'none';
    }};
    animation-duration: ${props => {
        switch (props.status) {
            case 'open':
                return '0.3s';
            case 'closed':
                return props.duration;
        }
        return 'none';
    }};
    animation-fill-mode: forwards;
    transform: translateX(100%);
    transition-timing-function: ease-in-out;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    z-index: 10;
    height: 100vh;
    background: ${props => props.color};
    width: ${props => (50 - props.widthOffset).toString() + '%'};
    transform: translate3d(100%, 0, 0);
    right: 0;
    @media screen and (${props => props.theme.breakpoints?.mobile}) {
        width: ${props => (100 - props.widthOffset).toString() + '%'};
    }
    overflow-y: scroll;
`;

const LinkList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

const StyledLink = styled(NavLink)`
    position: relative;
    left: 0;
    animation: ${props => {
        switch (props.status) {
            case 'open':
                return menuAnimations.fadeSlideIn;
            case 'closed':
                return menuAnimations.fadeSlideOut;
        }
        return 'none';
    }};
    animation-delay: ${props => {
        switch (props.status) {
            case 'open':
                return props.delay;
            case 'closed':
                return '0s';
        }
        return 'none';
    }};
    font-size: ${props => props.fontSize};
    font-family: ${props => props.theme.fonts.header1};
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    transition-duration: 0.22s;
    padding: ${props => props.linkpadding} 0;
    opacity: 0;
    color: ${props => props.theme.colors.orange};
    text-decoration: none;
    &:hover {
        color: ${props => props.theme.colors.yellow};
    }
`;

const SMiconsContainer = styled.div`
    animation: ${props => {
        switch (props.status) {
            case 'open':
                return menuAnimations.fadeSlideIn;
            case 'closed':
                return menuAnimations.fadeSlideOut;
        }
        return 'none';
    }};
    animation-delay: ${props => {
        switch (props.status) {
            case 'open':
                return '0.5s';
            case 'closed':
                return '0s';
        }
        return 'none';
    }};
    font-size: 2.3rem;
    font-family: ${props => props.theme.fonts.header1};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    padding: 0.9rem 0;
    align-self: flex-start;
    opacity: 0;
    padding: 1rem 0;
`;

// TODO: The hover effect is very hacky - it emulates the same color as the links using a filter (because of svg use)
const StyledSMicons = styled.img`
    position: relative;
    width: 25px;
    height: 25px;
    cursor: pointer;
    box-sizing: content-box;
    padding: 0.6rem 0.9rem 0.6rem 0.9rem;
    transition: 0.22s;
    margin-left: -0.6rem;
    &:hover {
        filter: brightness(1.5) saturate(65%) hue-rotate(-10deg);
    }
`;

const GlobalMenu = () => {
    const theme = useTheme();
    const { menuState, toggleMenu } = useMenu();

    const { socialMediaUrls } = useAppContext().meta.info;

    const { loading, error, data } = useQuery(gql`
        query {
            menu {
                flyout
                flyoutSub
            }
        }
    `);

    useEffect(() => {
        const escFunction = event => {
            if (event.keyCode == 27) {
                if (menuState == 'open') {
                    toggleMenu();
                }
            }
        };
        document.addEventListener('keydown', escFunction, false);
        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [menuState]);

    useEffect(() => {
        if (menuState == 'open') {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
        return () => {
            document.body.style.overflow = 'visible';
        };
    }, [menuState]);

    if (loading || error) return null;

    const flyoutMenu = (data && data.menu && data.menu.flyout) || [];
    const flyoutSubMenu = (data && data.menu && data.menu.flyoutSub) || [];

    const flyoutItems = flyoutMenu.map((menu, index) => {
        let delay = 0.24;
        const additionalDelay = index * 0.05;
        delay = delay + additionalDelay;
        delay = delay.toString() + 's';

        return (
            <StyledLink
                status={menuState}
                delay={delay}
                key={menu.ID}
                to={menu.url}
                onClick={() => {
                    setDataLayer({ action: 'click', gtmCategory: 'flyout-menu', gtmLabel: menu.url });
                    toggleMenu();
                }}
                exact
                activeStyle={{
                    color: '#f2de65',
                    textDecoration: 'underline'
                }}
                fontSize={'2.3rem'}
                linkpadding={'0.9rem'}
            >
                {menu.title.toUpperCase()}
            </StyledLink>
        );
    });

    const flyoutSubItems = flyoutSubMenu.map((menu, index) => {
        let delay = 0.25;
        const additionalDelay = index * 0.05;
        delay = delay + additionalDelay;
        delay = delay.toString() + 's';

        return (
            <StyledLink
                status={menuState}
                delay={delay}
                key={menu.ID}
                to={menu.url}
                onClick={() => {
                    setDataLayer({ action: 'click', gtmCategory: 'flyout-submenu', gtmLabel: menu.url });
                    toggleMenu();
                }}
                exact
                activeStyle={{
                    color: '#f2de65',
                    textDecoration: 'underline'
                }}
                fontSize={'1.3rem'}
                linkpadding={'0.5rem'}
            >
                {menu.title.toUpperCase()}
            </StyledLink>
        );
    });

    const SMiconItems = Object.keys(SMicons)
        .map(key => {
            if (socialMediaUrls[key]) {
                return (
                    <LinkWrapper to={socialMediaUrls[key]} target='_blank' key={key}>
                        <StyledSMicons src={SMicons[key].url} alt={SMicons[key].alt} status={menuState} onClick={() => toggleMenu()} />
                    </LinkWrapper>
                );
            }
            return null;
        })
        .filter(x => x);

    return (
        <GlobalMenuContainer>
            <ColoredEdge
                color={theme.colors.blue}
                animIn={menuAnimations.BlueOpen}
                animOut={menuAnimations.BlueClose}
                duration={'0.4s'}
                delay={'0s'}
                widthOffset={0}
                status={menuState}
            />
            <ColoredEdge
                color={theme.colors.green}
                animIn={menuAnimations.GreenOpen}
                animOut={menuAnimations.GreenClose}
                duration={'0.4s'}
                widthOffset={0}
                delay={'0.05s'}
                status={menuState}
            />
            <ColoredEdge
                color={theme.colors.yellow}
                animIn={menuAnimations.YellowOpen}
                animOut={menuAnimations.YellowClose}
                duration={'0.4s'}
                widthOffset={0}
                delay={'0.1s'}
                status={menuState}
            />
            <ColoredEdge
                color={theme.colors.tomato}
                widthOffset={0}
                animIn={menuAnimations.OrangeOpen}
                animOut={menuAnimations.OrangeClose}
                duration={'0.3s'}
                delay={'0.1s'}
                status={menuState}
            >
                <MenuContainer>
                    <div>
                        <LinkList>{flyoutItems}</LinkList>
                        <LinkList
                            css={css`
                                padding: 2rem 0 0 0;
                            `}
                        >
                            {flyoutSubItems}
                        </LinkList>
                    </div>
                    <SMiconsContainer status={menuState}>{SMiconItems}</SMiconsContainer>
                </MenuContainer>
            </ColoredEdge>
        </GlobalMenuContainer>
    );
};

GlobalMenu.propTypes = {};

export default GlobalMenu;
