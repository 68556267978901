import { css } from '@emotion/core';
import React from 'react';
import HeaderMenu from '../header-menu';

class Header extends React.PureComponent {
    render() {
        // const {
        //     header: { headerData },
        //     meta: { info }
        // } = this.context;

        const headerMenuProps = {
            // marketMenu: headerData.menu,
            // loginButton: headerData.loginButton,
            // marketType: info.marketType,
            // topMenu: headerData.topMenu
        };

        return (
            <div
                css={css`
                    z-index: 2;
                `}
            >
                <HeaderMenu {...headerMenuProps} />
            </div>
        );
    }
}

export default Header;
