import gql from 'graphql-tag';
import { useQuery } from '../../graphql-client/graphql-client';
import fetchHighlightedPosts from './fetch-highlighted-posts';

function fetchPosts(postTypes, highlightedPosts) {

    const LIST_QUERY = gql`
        query List($postTypes: postTypesSpecification, $includeNews: Boolean!, $includePosts: Boolean!, $includeProjects: Boolean!, $includePodcasts: Boolean!) {
            list(siteId: "rawb", postTypes: $postTypes) {
                news @include(if: $includeNews) {
                    _id
                    title
                    permalink
                    info
                    type
                    resourceLastUpdated
                }
                post @include(if: $includePosts) {
                    _id
                    title
                    permalink
                    info
                    type
                    resourceLastUpdated
                }
                project @include(if: $includeProjects) {
                    _id
                    title
                    permalink
                    info
                    type
                    resourceLastUpdated
                }
                podcast @include(if: $includePodcasts) {
                    _id
                    title
                    permalink
                    info
                    type
                    resourceLastUpdated
                }
            }
        }
    `;


    const { loading, error, data, fetchMore } = useQuery(LIST_QUERY, {
        variables: {
            postTypes,
            includeNews: Object.keys(postTypes).includes('news'),
            includePosts: Object.keys(postTypes).includes('post'),
            includeProjects: Object.keys(postTypes).includes('project'),
            includePodcasts: Object.keys(postTypes).includes('podcast'),
        },
    });

    // get all posts in one array
    const detailedItemList = (data && data.list) ? Object.values(data.list).flat().filter(post => typeof post === 'object') : [];

    const actuallyHighlightedPosts = [];

    // mark highlighted ones
    detailedItemList.forEach(item => {

        if (highlightedPosts?.some(({ id }) => String(id) === String(item._id) || Number(id) === Number(item._id))) {
            // item.width = 2;
            item.prioritized = true;

            actuallyHighlightedPosts.push({ id: item._id, type: item.type });
        }
    });

    // if there are any highlighted posts that were not included, fetch them aswell
    // because this uses the useQuery hook we must call it unconditionally
    const { loading: loadingHighlighted, error: errorHighlighted, data: dataHighlighted } = fetchHighlightedPosts(highlightedPosts, actuallyHighlightedPosts);

    if (!loadingHighlighted && !errorHighlighted && dataHighlighted) {

        // do the highlighting
        const resources = dataHighlighted.resources?.map(post => ({ ...post, prioritized: true })) || [];
        const selectEpisodes = dataHighlighted.selectEpisodes?.map(episode => ({ ...episode, prioritized: true })) || [];

        // combine with other posts
        detailedItemList.push( ...resources, ...selectEpisodes );

    }

    let even = detailedItemList.find(item => item.prioritized);

    // sort by date and priority
    detailedItemList.sort((a, b) => {

        if (a.prioritized && !b.prioritized) {

            return -1;

        } else if (b.prioritized && !a.prioritized) {

            return 1;

        }

        return new Date(b.resourceLastUpdated).getTime() - new Date(a.resourceLastUpdated).getTime();

    });

    return {
        data,
        fetchMore,
        even,
        detailedItemList,
        loading: loading || loadingHighlighted,
        error: error || errorHighlighted,
    };

}

export default fetchPosts;
