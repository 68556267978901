import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';
import { LoadingContainer } from './loading-styling';

const Loading = ({ size, stroke, theme }) => {
    const { primaryColor, secondaryColor, yellow } = theme.colors;
    return (
        <LoadingContainer size={size} stroke={stroke}>
            <svg>
                <circle cx='50%' cy='50%' r='10' fill={primaryColor} />
                <circle cx='50%' cy='50%' r='10' fill={secondaryColor} />
                <circle cx='50%' cy='50%' r='10' fill={yellow} />
            </svg>
        </LoadingContainer>
    );
};

Loading.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    stroke: PropTypes.string,
    theme: PropTypes.object,
};

export default withTheme(Loading);
