import React from 'react';
import PropTypes from 'prop-types';
import Masonry from '../../components/masonry/masonry';
import { useAppContext } from '../../app-context-provider/provider';
import { withRouter } from 'react-router-dom';
import { useQuery } from '../../graphql-client/graphql-client';
import gql from 'graphql-tag';

const LIST_QUERY = gql`
        query List($paths: [String!], $slugs: [String!], $includeEpisodes: Boolean!, $includeResources: Boolean!) {
            resources(siteId: "rawb", paths: $paths) @include(if: $includeResources) {
                _id
                permalink
                title
                type
                info
                resourceLastUpdated
            }
            selectEpisodes(slugs: $slugs) @include(if: $includeEpisodes) {
                _id
                permalink
                title
                type
                info
                resourceLastUpdated
            }
        }
    `;

const List = props => {

    const context = useAppContext();

    const {
        meta: { viewport }
    } = context;

    const { posts } = props;

    const onClick = (e, link) => {
        e.preventDefault();
        props.history.push(link);
    };

    const podcastsToFetch = posts.filter(({ type }) => type === 'podcast');
    const regularPostsToFetch = posts.filter(({ type }) => type !== 'podcast');

    const slugs = podcastsToFetch.map(({ id }) => id);
    const paths = regularPostsToFetch.map(({ permalink }) => permalink);

    const { loading, error, data } = useQuery(LIST_QUERY, {
        variables: {
            slugs,
            paths,
            includeEpisodes: slugs.length > 0,
            includeResources: paths.length > 0,
        },
    });

    if (!data || loading || !viewport.initiated || error) return null;

    const fetchedList = [ ...(data.resources || []), ...(data.selectEpisodes || []) ];

    // restore the original order by mapping from posts prop - all posts SHOULD be there but filter out nulls just in case
    const list = posts.map(({ id }) => fetchedList.find(({ _id }) => String(_id) === String(id))).filter(Boolean);

    const itemList = list.map(item => {
        return {
            ...item.info,
            _id: item._id,
            permalink: item.permalink,
            type: item.type,
        };
    });


    return (<Masonry width={props.gridItemWidth} uneven={props.uneven} viewport={viewport} onClick={onClick} items={itemList || []} isMultiTypeList={ true } />);

};

List.propTypes = {
    history: PropTypes.object,
    highlightedPosts: PropTypes.array,
    gridItemWidth: PropTypes.number,

    // list of post types to display
    posts: PropTypes.array,
    uneven: PropTypes.bool,
};

export default withRouter(List);
