import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import ReactPlayer from 'react-player';
import { css } from '@emotion/core';
import { withTheme } from '@emotion/react';

import TextBlock from '../text-block';

class Video extends React.Component {

    static propTypes = {
        aspectRatio: PropTypes.string,
        autoplay: PropTypes.bool,
        controls: PropTypes.bool,
        url: PropTypes.string,
        start: PropTypes.number,
        theme: PropTypes.object,
        last: PropTypes.bool,
        widthType: PropTypes.string,
        widthInPixels: PropTypes.string,
    };

    componentDidMount() {
        let { reactPlayer } = this.refs;
        if (reactPlayer) {
            let playerNode = ReactDOM.findDOMNode(reactPlayer);
            if (playerNode) {
                //let width = playerNode.clientWidth;
                playerNode.style.aspectRatio = '16 / 9';
                if (this.props.aspectRatio === '43') {
                    playerNode.style.aspectRatio = '4 / 3';
                }
            }
        }
    }

    componentDidUpdate() {
        const { reactPlayer } = this.refs;
        if (reactPlayer) {
            let playerNode = ReactDOM.findDOMNode(reactPlayer);
            if (playerNode) {
                playerNode.style.aspectRatio = '16 / 9';
                if (this.props.aspectRatio === '43') {
                    playerNode.style.aspectRatio = '4 / 3';
                }
            }
        }
    }

    hhMMSSToSeconds (timestamp = '') {
        const [hour, minute, seconds] = timestamp.split(':');
        return (+hour) * 60 * 60 + (+minute) * 60 + (+seconds);
    }


    render () {

        const {
            url,
            autoplay,
            // controls,
            // start,
            theme,
            widthType,
            widthInPixels,
            last,
        } = this.props;

        let mainCss = css`
            padding: ${last ? 0 : '0rem 0rem 3rem 0rem'};
            @media screen and (${theme.breakpoints.mobile}) {
                padding: ${last ? 0 : '0rem 0rem 4rem 0rem'};
            }
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex-shrink: 0;
            }
        `;

        if (widthType === 'text-width' || !widthType ) {
            mainCss = css`
                ${mainCss}
                margin-left: auto;
                margin-right: auto;
                max-width: ${theme.contentMaxWidth};
                width: 100%;
            `;
        } else if (widthType === 'wider') {
            mainCss = css`
                ${mainCss}
                margin-left: auto;
                margin-right: auto;
                max-width: 64rem;
            `;
        } else if (widthType === 'custom') {
            mainCss = css`
                ${mainCss}
                margin-left: auto;
                margin-right: auto;
                max-width: ${widthInPixels}px;
                width: 100%;
            `;
        }


        let player = null;
        if (url && url.length > 0) {
            player = (
                <ReactPlayer ref="reactPlayer" url={url} playing={autoplay} controls={true} width="100%" height="auto" />
            );
        } else {
            player = (
                <div className="react-player--error">
                    <TextBlock>
                        Could not start the video
                    </TextBlock>
                </div>
            );
        }

        return (
            <div css={mainCss}>
                {player}
            </div>
        );
    }
}

export default withTheme(Video);
