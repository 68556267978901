
import WrappedImage         from '../components/wrapped-image';
import Button               from '../components/button/button';
import Block                from '../components/block';
import Section              from '../components/section/section';
import SectionColumn        from '../components/section/column';
import Paragraph            from '../components/paragraph/paragraph';
import Heading              from '../components/heading/heading';
import List                 from '../components/list/list';
import CustomHTML           from '../components/custom-html/custom-html';

import Video                from '../components/video/video';
import OldBlogPost          from '../components/old-blog-post/old-blog-post';

import PostList             from '../features/list/list';
import PostListCurated      from '../features/list-curated/list-curated';

import Jmodule              from '../components/jmodule/jmodule';

import DateDisplay          from '../components/date-display';
import PostInfo             from '../components/post-info/post-info';
import WeekNumber           from '../components/week-number/week-number';

import ProcessMap           from '../components/process-map/process-map';

const componentList = {

    'core/block': Block,
    'core/classic': OldBlogPost,

    'core/paragraph': Paragraph,
    'core/heading': Heading,
    'core/list': List,
    'core/image': WrappedImage,

    'core/embed': Video,
    'core/html': CustomHTML,

    'next24hr/image': WrappedImage,
    'next24hr/button': Button,
    'next24hr/section': Section,
    'next24hr/column': SectionColumn,
    'next24hr/paragraph': Paragraph,
    'next24hr/postlist': PostList,
    'next24hr/postlist-curated': PostListCurated,
    'next24hr/jmodule': Jmodule,
    'next24hr/date-display': DateDisplay,
    'next24hr/post-info': PostInfo,
    'next24hr/week-number': WeekNumber,
    'next24hr/process-map': ProcessMap,
    'next24hr/paragraph-next': Paragraph,
    'next24hr/heading': Heading,
 

};

export {
    componentList,
};
