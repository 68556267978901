/* eslint-disable indent */
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

const bounce1 = keyframes`
  from, 0%, 40%, to {
    transform: translate3d(0,0,0);
  }

  40%, 66% {
    transform: translate3d(-15px, -15px, 0);
  }
`;

const bounce2 = keyframes`
  from, 0%, 40%, to {
    transform: translate3d(0,0,0);
  }

  40%, 66% {
    transform: translate3d(15px, -15px, 0);
  }
`;

const bounce3 = keyframes`
  from, 0%, 40%, to {
    transform: translate3d(0,0,0);
  }

  40%, 66% {
    transform: translate3d(0, 15px, 0);
  }
`;

const rotate = keyframes`
  from, 0%, 50% to {
    transform: rotate(0);
  }

  50%, 66% {
    transform: rotate(-180deg);
  }
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    min-height: 60vh;
    width: 100%;
    flex: 1;

    svg {
        animation: ${rotate} 1.5s ease-in-out infinite;
        circle {
            &:nth-of-type(1) {
                animation: ${bounce1} 1.5s ease-in-out infinite;
            }
            &:nth-of-type(2) {
                animation: ${bounce2} 1.5s ease-in-out infinite;
            }
            &:nth-of-type(3) {
                animation: ${bounce3} 1.5s ease-in-out infinite;
            }
        }
    }
`;
