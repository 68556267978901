import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

const colorValues = {
    'blue-purple': 'rgba(200, 195, 232)',
    'red-yellow': 'rgb(247, 236, 170)',
    'green-blue': 'rgb(189, 225, 187)',
};

const pageBackgrounds = {
    'effect-2': 'rgb(58, 200, 252)',
    podcast: colorValues['red-yellow'],
};

const MenuBackgroundContext = React.createContext({});

const MenuBackgroundProvider = ({ children, theme }) => {

    const intialState = {
        pageBackground: null,
        menuBackground: 'transparent',
        sectionBackground: null,
    };

    function reducer(state, action) {

        if (action.type === 'setPageBackground') {

            let { menuBackground } = state;

            if (!state.sectionBackground || typeof state.sectionBackground === 'object') {

                menuBackground = pageBackgrounds[action.payload] || theme.colors.lightGray;

            }

            return {
                ...state,
                pageBackground: action.payload,
                menuBackground,
            };

        } else {

            let newColor;

            const sectionBackground = action.payload;

            // if the section background is image
            if (typeof sectionBackground === 'object') {

                newColor = pageBackgrounds[state.pageBackground] || theme.colors.lightGray;

            // if section bg is a (most likely) string
            } else if (sectionBackground) {

                newColor = colorValues[sectionBackground] || theme.colors[sectionBackground] || sectionBackground;

            // if section bg is undefined/null
            } else {

                newColor = pageBackgrounds[state.pageBackground] || theme.colors.lightGray;

            }

            if (newColor === state.menuBackground && sectionBackground === state.sectionBackground) return state;

            return {
                ...state,
                sectionBackground,
                menuBackground: newColor,
            };

        }

    }

    const [state, dispatch] = useReducer(reducer, intialState);

    const value = {
        value: state.menuBackground,
        dispatch,
    };

    return <MenuBackgroundContext.Provider value={ value }>{ children }</MenuBackgroundContext.Provider>;
};

MenuBackgroundProvider.propTypes = {
    children: PropTypes.any,
    theme: PropTypes.object,
};

export const useMenuBackground = () => {
    const menuBackground = useContext(MenuBackgroundContext);
    return menuBackground;
};

export default withTheme(MenuBackgroundProvider);