import React from 'react';
import { Global, css } from '@emotion/core';
import { withTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import meyerCssReset from './meyer-css-reset';
import FontFaces from './font-faces';

const GlobalStyles = ({theme}) => {
    return (
        <Global
            styles={css`
                ${meyerCssReset}
                ${FontFaces}
                html {
                    background-color: ${theme.colors.lightGray};
                }
                * {
                    box-sizing: border-box;
                }
            `}
        />
    );
};

GlobalStyles.propTypes = {
    theme: PropTypes.object.isRequired
};

export default withTheme(GlobalStyles);
