import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../grid/grid';
import Heading from '../heading/heading';
import Paragraph from '../paragraph/paragraph';
import List from '../list/list';
import Image from '../image';


const Jmodule = ({ viewport, image, reverseHorisontal, children, theme, last }) => {

    const GridContent = styled.div`
        height: 100%;
        padding: ${ reverseHorisontal ?  '0rem ' + theme.spacing.xlarge + ' 0rem 0rem': '0rem 0rem 0rem ' + theme.spacing.xlarge };
        display: flex;
        flex-direction: column;
        @media screen and (${theme.breakpoints.mobile}) {
            padding: ${ theme.spacing.large + ' 0rem 0rem 0rem'};
        }
        ${ last ? 'padding-bottom: 0px' : ''}
    `;

    const isMobile = viewport.width < 768 ? true : false;

    let gridItemWidth;
    if (isMobile) {
        gridItemWidth = [
            {
                col: { s: 1, e: 13 },
                row: { s: 1, e: 2 }
            },
            {
                col: { s: 1, e: 13 },
                row: { s: 3, e: 4 }
            }
        ];

    } else {
        gridItemWidth = [
            {
                col: reverseHorisontal ? {s: 6, e: 13} : { s: 1, e: 8 },
                row: reverseHorisontal ? { s: 1, e: 2 } : {s: 1, e: 2}
            },
            {
                col: reverseHorisontal ? { s: 1, e: 6 } : {s: 8, e: 13},
                row: reverseHorisontal ? { s: 1, e: 2 } : {s: 1, e: 2}
            }
        ];
    }

    let gridItem = gridItemWidth.map(itemWidth => {
        return itemWidth;
    });

    const mapModule = children.map((child, index) => {
        let key = `key-${index}`;
        const { children, ...props } = child.props;
        if (child.props.moduleName === 'next24hr/paragraph') {
            return (<Paragraph {...props} key={key}>{children}</Paragraph>);
        } else if (child.props.moduleName === 'core/heading') {
            return (<Heading {...props} key={key}>{children}</Heading>);
        } else if (child.props.moduleName === 'core/list') {
            return (<List {...props} key={key}>{children}</List>);
        }
    });
    
    return (
        <div>
            <Grid cols={12}>
                <Grid.Item col={gridItem[0].col} row={gridItem[0].row} viewport={viewport}>
                    <Image src={image?.url} ratio={image?.width / image?.height} jwt={image?.jwt} focus={image?.focus} cover={false} />
                </Grid.Item>
                <Grid.Item col={gridItem[1].col} row={gridItem[1].row} viewport={viewport}>
                    <GridContent>
                        {mapModule}
                    </GridContent>
                </Grid.Item>
            </Grid>
        </div>
    );

};

Jmodule.propTypes = {
    reverseHorisontal: PropTypes.bool,
    viewport: PropTypes.object,
    image: PropTypes.object,
    children: PropTypes.array,
    theme: PropTypes.any,
    last: PropTypes.bool,
};

export default withTheme(Jmodule);
