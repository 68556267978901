import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Masonry from '../../components/masonry/masonry';
import LoadMore from '../../components/button/load-more';
import { useAppContext } from '../../app-context-provider/provider';
import { withRouter } from 'react-router-dom';
import fetchPosts from './fetch-posts';
import loadMore from './load-more-posts';


const List = props => {

    const context = useAppContext();

    const { postTypes, highlightedPosts } = props;

    const {
        meta: { viewport }
    } = context;

    const [moreToLoad, setMoreToLoad] = useState(true);

    if (!postTypes || Object.keys(postTypes).length === 0) {
        return null;
    }

    const onClick = (e, link) => {
        e.preventDefault();
        props.history.push(link);
        window.scrollTo(0, 0);
    };

    const { loading, error, data, fetchMore, even, detailedItemList } = fetchPosts(postTypes, highlightedPosts);

    if (loading || !viewport.initiated || error) return null;

    const loadMoreButton = moreToLoad ? (<LoadMore onClick={() => loadMore({ postTypes, data, fetchMore, setMoreToLoad })} text="Ladda mer" />) : null;

    const itemList = detailedItemList.map(item => {
        return {
            ...item.info,
            _id: item._id,
            permalink: item.permalink,
            type: item.type,
        };
    });

    return (
        <Masonry
            width={props.gridItemWidth}
            loadMoreButton={loadMoreButton}
            uneven={!even}
            viewport={viewport}
            onClick={onClick}
            items={itemList || []}
            isMultiTypeList={ Object.keys(postTypes).length > 1 }
        />
    );

};

List.propTypes = {
    history: PropTypes.object,
    highlightedPosts: PropTypes.array,
    gridItemWidth: PropTypes.number,

    // list of post types to display
    postTypes: PropTypes.object,
};

export default withRouter(List);
