// STYLUS!
//import './style.styl';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IntersectionObserver from '../intersection-observer';

import { StyledImage } from './image.style';

import { css } from '@emotion/core';

import { AppContext }  from '../../app-context-provider/provider';

// const lowResSize = 1;

class ImageComponent extends React.Component {

    static contextType = AppContext;

    static vcConvertToProps(atts){
        let newProps = {
            width: '100%',
            useNativeWidth: atts.use_native_width,
            imageText: atts.image_text ? atts.image_text : '',
            ...atts.image
        };

        return newProps;
    }

    static propTypes = {
        src: PropTypes.string,
        service: PropTypes.oneOf(['aws-s3', 'local']),
        fit: PropTypes.oneOf([true, 'true', null]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        ratio: PropTypes.number,
        imageText: PropTypes.string,
        alt_text: PropTypes.string,
        // TODO: This is not being used. Need to make sure the callers who use it (.. or atleast think they do...) wont break when we add it.
        className: PropTypes.string,
        useNativeWidth: PropTypes.string,
        original_width: PropTypes.number,
        original_height: PropTypes.number,
        defaultWidth: PropTypes.number,
        defaultHeight: PropTypes.number,
        showRetinaSource: PropTypes.bool,
        customAltText: PropTypes.string,
        focus: PropTypes.object,
        imageVersion: PropTypes.string,
        forceRender: PropTypes.bool,
        cover: PropTypes.bool,
        threshold: PropTypes.number,
        classNameIntersectionObserver: PropTypes.string,
        rounded: PropTypes.bool,
        isBackground: PropTypes.bool,
    };

    static defaultProps = {
        rounded: false,
        isBackground: false
    };

    constructor (props, context) {
        super(props, context);
        this.userAgent = context.meta.settings.userAgent;
    }

    componentDidMount() {
        this.loadedOnce = false;
        this.lowResImage = this.refs.lowResImage;
        if (this.lowResImage && this.lowResImage.complete && this.lowResImage.naturalHeight !== 0) {
            //this.loaded();
        }
        if (this.image) {
            this.image.addEventListener('animationend', () => {
                this.image.classList.add('image--fullres-transition-ended');
                this.lowResImage.classList.add('image--lowres--transition-ended');
                this.image.style.display = 'block';
                if (this.props.cover) {
                    this.imageFig.style.height = '100%';
                } else {
                    this.imageFig.style.height = 'auto';
                }
            }, false);
        }
    }

    visible () {
        if (this.image && !this.image.loaded) {
            let src = this.getImageSrc(this.props, this.context);
            let img = new Image();
            img.addEventListener('load', () => {
                if (this.image) {
                    this.image.src = img.src;
                    this.image.loaded = true;
                    this.image.classList.add('image--fullres-loaded');
                    this.image.style.display = 'block';
                }
            }, false);
            img.src = src;
        }
    }

    // isElementInViewport (el) {
    //     if (!el) {
    //         return false;
    //     }
    //     var rect = el.getBoundingClientRect();
    //     return (
    //         rect.top >= 0 &&
    //         rect.left >= 0 &&
    //         rect.bottom - rect.height / 2 <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
    //         rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    //     );
    // }

    componentWillUnmount() {
        this.loadedOnce = false;
    }

    //     componentWillReceiveProps (nextProps) {
    //         if (this.image && this.src !== nextProps.src) {
    //             this.image.src = this.getImageSrc(nextProps);
    //         }
    //     }

    isAbsoluteUrl (url) {
        let pattern = /^https?:\/\//i;
        return pattern.test(url);
    }

    getImageSrc (props) {

        let width = props.defaultWidth || 1920;

        if (props.width) {
            width = props.width && props.width.toString().indexOf('%') > -1 ? 1200 : parseInt(props.width, 10);
        }

        // If we have a bad connection
        if (this.context.connectionType() != '4g') {
            width = width * .50;
        }

        // Mobile
        if (this.context.isMobile()) {
            width = width * .50;
        }

        return this.getImageServiceSrcPath(props, width, 'AUTO', props.useNativeWidth);

    }

    getImageServiceSrcPath (props, width, height, useNativeWidth) {

        //let host = this.context.imageHost() + '/image/version/' + props.imageVersion || 'v1';
        let host = this.context.imageHost() + '/image'; // /version/' + props.imageVersion || 'v1';
        let src = props.src;

        if (!src.match(/\.png/) && this.context.imageFormats.avif && !src.match(/\.avif$/)) {
            src += '.avif';
        } else if (this.context.imageFormats.webp && !src.match(/\.webp/)) {
            src += '.webp';
        }

        if (useNativeWidth) {
            // If browser rendering and the device is a retina or similair DPI, we load the original img
            if (process.title === 'browser' && devicePixelRatio === 2) {
                return `${host}/original${src}`;
            }
            // Otherwise we load the image with half with and height
            const halfOriginalWidth = Math.ceil(props.original_width / 2);
            const halfOriginalHeight = Math.ceil(props.original_height / 2);
            return `${host}/${halfOriginalWidth}x${halfOriginalHeight}${src}`;
        }

        let imageUrl = `${host}/${width}x${height}${src || '/'}`;

        if (props.jwt) {
            imageUrl += `?jwt=${props.jwt}`;
        }

        return  imageUrl;

    }

    setImageFigHeight(ref) {
        if (this.imageFig && this.props.ratio) {
            let calculatedHeight = !this.props.height ? ref.clientWidth / (this.props.ratio || 1) : (this.props.cover ? '100%' : this.props.height);
            this.imageFig.style.height = calculatedHeight + 'px';
        }
    }

    loaded () {

        if (this.loadedOnce === false) {
            this.loadedOnce = true;
            this.setImageFigHeight(this.refs.lowResImage);

            // let visible = this.isElementInViewport(this.lowResImage);
            // if (visible || this.props.forceRender) {
            //     // this.visible();
            // }

        }
    }

    getPictureElement(retinaSrc, src, altText, imgStyle) {

        if (this.context.imageFormats.avif && !src.match(/\.avif$/)) {
            src += '.avif';
        } else if (this.context.imageFormats.webp && !src.match(/\.webp/)) {
            src += '.webp';
        }

        const srcSet = `${retinaSrc} 2x, ${src}`;
        // const srcWebpSet = `${retinaSrc}.webp 2x, ${src}.webp`;
        // <source srcSet={srcWebpSet} type="image/webp" />
        return (
            <picture className="image--picture">
                <source srcSet={srcSet} />
                <img title={altText} style={imgStyle} ref={(image) => { this.image = image; }} className="image--fullres" alt={altText} />
            </picture>
        );
    }

    render () {

        const {
            useNativeWidth,
            height,
            width,
            src,
            imageText,
            original_width,
            original_height,
            alt_text,
            fit,
            showRetinaSource,
            customAltText,
            threshold,
            rounded,
            isBackground,
        } = this.props;

        let figureStyle = {
            height: height ? (height.toString().match(/%/) ? height : `${height}px`) : 'auto',
            borderRadius: rounded ? '4px' : '0',
        };

        if (width) {
            figureStyle.width = width ? (width.toString().match(/%/) ? width : `${width}px`) : 'auto';
        }

        let imgStyle = {
            height: height ? (height.toString().match(/%/) ? height : `${height}px`) : 'intrinsic'
        };


        const halfOriginalWidth = Math.ceil(this.props.original_width / 2);
        const halfOriginalHeight = Math.ceil(this.props.original_height / 2);

        if (useNativeWidth) {
            imgStyle.width = `${halfOriginalWidth}px`;
            imgStyle.left = `calc(50% - ${halfOriginalWidth}px / 2)`;
            imgStyle.height = `${halfOriginalHeight}px`;
            imgStyle.maxWidth = '100%';
            imgStyle.objectFit = 'contain';
        } else {
            imgStyle.width = '100%';
        }

        const altText = customAltText ? customAltText : alt_text;

        if (!this.context.featureSupport.objectFit) {
            imgStyle.height = height || 'auto';
        } else if (this.props.focus) {
            imgStyle.objectPosition = `${this.props.focus.x * 100}% ${this.props.focus.y * 100}%`;
        }

        let imageWrapperStyle = {};
        const lowResImgStyle = Object.assign({}, imgStyle);


        if (this.props.cover) {
            imgStyle.objectFit = 'cover';
            imgStyle.height = '100%';
            imageWrapperStyle.height = '100%';
            figureStyle.height = '100%';
            lowResImgStyle.height = '100%';
            lowResImgStyle.objectFit = 'cover';
        }

        let imageLowResComponent = null;
        let imageHighResComponent = null;

        if (this.isAbsoluteUrl(src)) {
            imageHighResComponent = (
                <img title={altText} style={imgStyle} className="image--fullres-external" src={ src } alt={alt_text} />
            );
        } else {

            const lowRes = this.getImageServiceSrcPath(this.props, 1, 1);
            // const lowRes = this.getImageServiceSrcPath(this.props, lowResSize, Math.ceil(lowResSize / (this.props.ratio || 1)));

            lowResImgStyle.paddingBottom = this.props.height ? this.props.height : 1 / (this.props.ratio || 1) * 100 + '%';
            lowResImgStyle.background = `url(${lowRes})`;
            lowResImgStyle.height = 0;

            imageLowResComponent = (
                <img style={lowResImgStyle} ref="lowResImage" onLoad={this.loaded.bind(this)} className="image--lowres" src={ lowRes } alt={altText} />
            );

            imgStyle.display = 'none';

            if (useNativeWidth) {
                const retinaSrc = this.getImageServiceSrcPath(this.props, original_width, original_height, true);
                const src = this.getImageServiceSrcPath(this.props, halfOriginalWidth, halfOriginalHeight, false);
                imageHighResComponent = this.getPictureElement(retinaSrc, src, altText, imgStyle);
            } else if (showRetinaSource) {
                const retinaSrc = this.getImageServiceSrcPath(this.props, width * 2, height * 2, false);
                const src = this.getImageServiceSrcPath(this.props, width, height, false);
                imageHighResComponent = this.getPictureElement(retinaSrc, src, altText, imgStyle);
            } else {
                imageHighResComponent = (
                    <img title={altText} style={imgStyle} ref={(image) => { this.image = image; }} className="image--fullres" alt={altText} />
                );
            }

        }

        const imageTextContainer = imageText && imageText.length > 0 ? (
            <p className="image--text">
                {imageText}
            </p>
        ) : null;


        if (width) {
            imageWrapperStyle.width = width;
        }

        // If we dont support object fit, just fallback to a bakcground image instead, with no fancy loading effects
        if (!this.context.featureSupport.objectFit) {
            if (fit !== 'true') {
                let backgroundSource = this.isAbsoluteUrl(src) ? src : this.getImageSrc(this.props);
                figureStyle.background = `url(${backgroundSource}) no-repeat center center`;
                figureStyle.backgroundSize = 'cover';
                imageLowResComponent = null;
                imageHighResComponent = null;
            }
        }

        let figureClasses = classNames(
            'image',
            {'image--fit': fit === 'true'}
        );

        const divHeight = isBackground ? '100%' : 'auto';

        figureStyle.display = 'flex';

        if (isBackground) {
            imageWrapperStyle.height = '100%';
            figureStyle.height = '100%';
        }

        const wrapperCss = css`
            height: ${divHeight};
        `;

        const key = this.getImageSrc(this.props).replace(/\?.*$/, '');
        return (
            <IntersectionObserver css={wrapperCss} root=".main-content" onVisible={this.visible.bind(this)} threshold={threshold || .1} className={this.props.classNameIntersectionObserver} key={key}>
                <StyledImage className="image--wrapper" style={imageWrapperStyle}>
                    <figure ref={(imageFig) => { this.imageFig = imageFig; }} className={figureClasses} style={figureStyle}>
                        {imageLowResComponent}
                        {imageHighResComponent}
                    </figure>
                    {imageTextContainer}
                </StyledImage>
            </IntersectionObserver>
        );

    }
}


export default ImageComponent;
