import { keyframes } from '@emotion/core';

export const menuAnimations = {
    BlueOpen: keyframes`
        0% {
            transform: translate3d(100%, 0, 0);
        }
        70% {
            transform: translate3d(-9%, 0, 0);
        }
        100% {
            transform: translate3d(0, 0, 0);
        }        
    `,
    BlueClose: keyframes`
        0% {
            transform: translate3d(0, 0, 0);
        }
        20% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(100%, 0, 0);
        }
    `,
    GreenOpen: keyframes`
        0% {
            transform: translate3d(100%, 0, 0);
        }
        70% {
            transform: translate3d(-6%, 0, 0);
        }
        100% {
            transform: translate3d(0, 0, 0);
        }
    `,
    GreenClose: keyframes`
        0% {
            transform: translate3d(0, 0, 0);
        }
        10% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(105%, 0, 0);
        }
    `,
    YellowOpen: keyframes`
        0% {
            transform: translate3d(100%, 0, 0);
        }
        70% {
            transform: translate3d(-3%, 0, 0);
        }
        100% {
            transform: translate3d(0, 0, 0);
        }
    `,
    YellowClose: keyframes`
        0% {
            transform: translate3d(0, 0, 0);
        }
        5% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(110%, 0, 0);
        }
    `,
    OrangeOpen: keyframes`
        0% {
            transform: translate3d(100%, 0, 0);
        }

        100% {
            transform: translate3d(0, 0, 0);
        }       
    `,
    OrangeClose: keyframes`
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(115%, 0, 0);
        }
    `,
    fadeSlideIn: keyframes`
        from {
            opacity: 0;
            transform: translateX(50%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    `,
    fadeSlideOut: keyframes`
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    `,
};