import React from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

import './style.styl';
import StripesLight from './stripes-light/stripes-light';
import Stripes from './stripes/stripes';

const Effect = ({ effect }) => {

    // const [effectVisible, setEffectVisible] = useState(false);

    const theme = useTheme();

    //     useEffect(() => {
    //         let timer = setTimeout(() => {
    //             clearTimeout(timer);
    //             window.requestAnimationFrame(() => {
    //                 setEffectVisible(true);
    //             });
    //         }, 1000);
    //         return () => clearTimeout(timer);
    //     }, []);

    const effectName = effect?.name || 'none';
    let effectCss = css`
        position: absolute;
        top: -170px;
        width: 100%;
        overflow-x: hidden;
        z-index: 0;
        height: calc(170px + 100%);
        @media (${theme.breakpoints.mobile}) {
            top: 0;
        }
    `;

    let content = null;

    // if (true || effectVisible) {
    if (effectName !== 'none') {
        if (effectName === 'effect-1') {
            content = (
                <div className="effect--effect-1">
                    <div className="effect--effect-1-part-1"/>
                </div>
            );
        } else if (effectName === 'effect-2') {

            content = (
                <div className="effect--effect-2">
                    <div className="effect--effect-2-part-1"/>
                    <div className="effect--effect-2-part-2"/>
                    <div className="effect--effect-2-part-3"/>
                    <div className="effect--effect-2-part-4"/>
                    <div className="effect--effect-2-part-5"/>
                    <div className="effect--effect-2-part-6"/>
                </div>
            );

        } else if (effectName === 'stripes-light') {
            content = (<StripesLight />);
        } else if (effectName === 'stripes') {
            content = (<Stripes />);
        }
    }
    // }

    return (
        <div css={effectCss}>
            {content}
        </div>
    );

};

Effect.propTypes = {
    viewport: PropTypes.object,
    effect: PropTypes.object,
};

Effect.getCSS = (effect) => {
    const effectName = effect?.name || 'none';
    if (effectName !== 'none') {
        if (effectName === 'effect-2') {
            return css`
                background: #00c9ff;
                top: -12vw;
            `;
        } else if (effectName === 'stripes-light') {
            return css`
                top: 0;
            `;
        } else if (effectName === 'stripes') {
            return css`
                top: 0;
            `;
        }
    } 
    return '';
};

export default Effect;
