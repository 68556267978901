import styled from '@emotion/styled';

import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from '../snackbar';
import Button from '../button/button';
import cookies from 'react-cookies';

import Text from '../text/text';

const SnackBarContainer = styled.div`
`;

const Container = styled.div`
    padding: 1rem 2rem;
`;

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-content: flex-end;
    padding: 0rem 2rem 1rem 2rem;
`;

const TextColor = styled.span`
    color: #fff;
`;

class CookieWarning extends React.Component {

    static propTypes = {
        open: PropTypes.bool.isRequired,
        handleAccept: PropTypes.func.isRequired,
        cookieMessageContent: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: props.open,
            wait: true,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            isOpen: nextProps.open
        });
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                wait: false
            });
        }, 5000);
    }

    handleOkClick(event, reason) {
        // Only set the cookie and dissmiss the msg if user clicked the button, not just anywhere on screen
        if (reason === 'clickaway') {
            return;
        }
        // Expires a year from now
        const expireDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        cookies.save('accepted_cookie_terms', true, { path: '/', expires: expireDate });
        this.props.handleAccept();
    }

    render() {
        const { cookieMessageContent } = this.props;

        const snackbarAction = [
            <ButtonWrapper key='cookie-warning'>
                <Button onClick={this.handleOkClick.bind(this)} text={cookieMessageContent?.buttonText} variant='default' color="secondary" />
            </ButtonWrapper>
        ];

        const cookieMessageElement = (
            <Container>
                <Text useSetInnerHtml={false} noPadding={true} as='div'>
                    <TextColor>{cookieMessageContent?.title}</TextColor>
                </Text>
                <Text useSetInnerHtml={false} size='large' as='div'>
                    <TextColor dangerouslySetInnerHTML={{__html: cookieMessageContent?.text}} />
                </Text>
            </Container>
        );

        return (
            <SnackBarContainer>
                <Snackbar
                    handleRequestClose={this.handleOkClick.bind(this)}
                    open={this.state.isOpen && !this.state.wait}
                    message={cookieMessageElement}
                    horizontal='center'
                    action={snackbarAction}
                />
            </SnackBarContainer>
        );
    }
}

export default CookieWarning;
