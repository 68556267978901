import PropTypes from 'prop-types';

const CustomHTML = ( props ) => {
    return (
        <div dangerouslySetInnerHTML={{__html: props.innerHTML }} />
    );
};

CustomHTML.propTypes = {
    innerHTML: PropTypes.any,
};

export default CustomHTML;
