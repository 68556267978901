import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';
import theme from '../../emotion/theme';
import Text from '../text/text';

const alignMap = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
};

const listStyles = {
    ul: css`
        list-style: none;
        line-height: 2.12em;
        color: ${ theme.colors.darkerText };
        margin-bottom: ${theme.spacing.base};
        max-width: ${theme.contentMaxWidth};
        padding-left: ${theme.spacing.large};
        margin-left: auto;
        margin-right: auto;
        max-width: ${theme.contentMaxWidth};
        font-weight: 300;
        width: 100%;
        box-sizing: border-box;
        & li::before {
            content: "•";
            color: ${ theme.colors.tomato };
            display: inline-block;
            width: ${ theme.spacing.small };
            margin-left: -${ theme.spacing.small };
            font-size: ${ theme.fontSizes.normal };
        }
    `,
    ol: css`
        list-style: none;
        max-width: ${theme.contentMaxWidth};
        line-height: 2.12em;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
        color: ${ theme.colors.darkerText };
        font-weight: 300;
        counter-reset: my-awesome-counter;
        width: 100%;
        margin-bottom: ${theme.spacing.base};
        max-width: ${theme.contentMaxWidth};
        & li {
            counter-increment: my-awesome-counter;
            margin-left: ${ theme.spacing.base };
        }
        & li::before {
            content: counter(my-awesome-counter) ".  ";
            color: ${ theme.colors.tomato };
            display: inline-block;
            width: ${ theme.spacing.small };
            margin-left: -${ theme.spacing.small };
            font-size: inherit;
            font-weight: 600;
        }
        
    `,
};

const List = ( {theme, content, children, tag = 'ul', alignSelf = 'left' } ) => {
    const listItems = (content || children).map( (item, index) => {
        if (typeof item === 'string') {
            return (<li key={index}><Text as="span">{item}</Text></li>);
        }
        return (<li key={index}><Text as="span" useSetInnerHtml={ false }>{item.props.children}</Text></li>);
    });
    let result = null;
    let listCss = '';
    switch (tag) {
        case 'ul':
            listCss = css`
                ${listStyles.ul}
                align-self: ${alignMap[alignSelf]};
                max-width: ${theme.contentMaxWidth};
            `;
            result = (<ul css={listCss}>{listItems}</ul>);
            break;
        case 'ol':
            listCss = css`
                ${listStyles.ol}
                align-self: ${alignMap[alignSelf]};
                max-width: ${theme.contentMaxWidth};
            `;
            result = (<ol css={listCss}>{listItems}</ol>);
            break;
    }
    return result;
};

List.propTypes = {
    children: PropTypes.any,
    content: PropTypes.array,
    tag: PropTypes.string,
    theme: PropTypes.object,
    alignSelf: PropTypes.oneOf(['center', 'left', 'right', null]),
};

export default withTheme(List);
