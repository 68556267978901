import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { setDataLayer } from '../../lib/gtm';

// The raw menu context.
export const MenuContext = React.createContext({});

// The menu provider used in this file.
const MenuProvider = ({ children }) => {
    // Open or closed.
    const [menuState, setMenuState] = useState('init');

    // Menu reset
    const resetMenu = () => setMenuState('init');

    // Menu toggle
    const toggleMenu = () => {
        if (menuState == 'init' || menuState == 'closed') {
            setDataLayer({ action: 'click', gtmCategory: 'hamburger-toggle', gtmLabel: 'open' });
            setMenuState('open');
        } else {
            setDataLayer({ action: 'click', gtmCategory: 'hamburger-toggle', gtmLabel: 'closed' });
            setMenuState('closed');
        }
    };

    const value = {
        toggleMenu,
        menuState,
        resetMenu
    };

    return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};
MenuProvider.propTypes = {
    children: PropTypes.any
};

// A 'useMenu' hook that can be used by any of the sub children to the menu provider.
export const useMenu = () => {
    const menu = useContext(MenuContext);
    return menu;
};

export default MenuProvider;
