import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx } from '@emotion/core';
import Text from '../text/text';
import { StyledContainer } from './week-number.style';

const getWeekNumber = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    const week1 = new Date(date.getFullYear(), 0, 4);
    const weekNumber = 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    return weekNumber;
};

const WeekNumber = () => {
    return (
        <StyledContainer><Text size="override" >{getWeekNumber()}</Text></StyledContainer>
    );
};

WeekNumber.propTypes = { };

export default WeekNumber;
