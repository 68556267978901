import { css } from '@emotion/core';

const fonts = css`
    /* BlinkMacSystemFont is Apple's font called San Francisco */
    /* Segoe UI is Windows system font */
    /* Roboto is Android's system font */
    body {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-style: normal;
        font-weight: 400;
    }

    html {
        /* Default */
        font-size: 16px;

        @media screen and (max-width: 768px) {
            font-size: 14px;
        }
    }
`;

export default fonts;
