// This loads the socket io library affter 3 seconds and simulates the interface untils its loaded

let temporaryListeners = { };
let temporaryEmits = { };

let socket = {
    on: (event, callback) => {
        if (!temporaryListeners[event]) {
            temporaryListeners[event] = [];
        }
        temporaryListeners[event].push(callback);
    },
    emit: (event, payload) => {
        if (!temporaryEmits[event]) {
            temporaryEmits[event] = [];
        }
        temporaryEmits[event].push(payload);
    },
};

let old = false;

if (typeof window !== 'undefined') {

    setTimeout(() => {
        requestAnimationFrame(() => {
            const script = document.createElement('script');
            script.async = true;
            script.src = '/socket.io/socket.io.js';
            script.addEventListener('load', () => {

                let userAgent = window.navigator.userAgent;
                old = (userAgent.indexOf('Android 4') > -1 && userAgent.indexOf('Safari') > -1);
                if (!old) {
                    const socketUrl = location.protocol.replace('https', 'wss').replace('http', 'ws') + '//' + location.host;
                    console.log('Socket', socketUrl); // eslint-disable-line
                    socket = io(socketUrl); // eslint-disable-line
                } else {
                    socket = io(location.protocol + '//' + location.host, { transports: ['xhr-polling'] }); // eslint-disable-line
                }

                Object.keys(temporaryListeners).forEach(event => {
                    temporaryListeners[event].forEach(callback => socket.on(event, callback));
                });

                Object.keys(temporaryEmits).forEach(event => {
                    temporaryEmits[event].forEach(payload => socket.on(event, payload));
                });

            });
            document.head.appendChild(script);
        });
    }, 5000);


} else {
    socket = {
        on: () => {
            console.log('empty');
        },
        emit: () => {
            console.log('empty');
        },
    };
}

export default socket;
