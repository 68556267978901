import styled from '@emotion/styled';

const SvgContainer = styled.div`
        height: 100%;
        width: 100%;
`;

const StyledSvg = styled.svg`
    text {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
    }

    .step {
        cursor: pointer;
        -webkit-transform: scale(1);
        -webkit-transform-origin: 50% 50%;
        -webkit-transition:.3s;
        transform: scale(1);
        transform-origin: 50% 50%;
        transition:.3s;
        transform-box: fill-box;
    }

    .step:hover {
        -webkit-transform: scale(2);
        -webkit-transform-origin: 50% 50%;
        -webkit-transition:.3s;
        transform: scale(1.1);
        transform-origin: 50% 50%;
        transition:.3s;
    }
`;

export {
    StyledSvg,
    SvgContainer,
};