import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useSectionContext } from '../section/section-provider';

import { css } from '@emotion/core';

const alignMap = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
};

const common = p => css`
    width: 100%;
    align-self: ${alignMap[p.alignSelf]};
    text-align: ${p.align};
    max-width: ${p.theme.contentMaxWidth};
    margin-left: ${p.autoCenter ? 'auto' : 'inherit'};
    margin-right: ${p.autoCenter ? 'auto' : 'inherit'};
`;

const headings = {
    xxl: styled.h1`
        ${common}
        padding-top: ${p => (p.first || p.belowDateDisplay ? '0rem' : '6rem')};
        padding-bottom: ${p => (p.last ? '0rem' : '4rem')};
        ${p => (p.noPadding ? 'padding: 0;' : '')}
        font-family: 'Montserrat ExtraBold', sans-serif;
        font-size: 5rem;
        max-width: 64rem;
        text-align: center;
        line-height: 1.2em;
        color: ${p => p.theme.colors.lighterText};
        @media screen and (${p => p.theme.breakpoints.mobile}) {
            font-size: 3.5rem;
        }
    `,
    xl: styled.h2`
        ${common}
        padding-top: ${p => (p.first || p.belowDateDisplay ? '0rem' : '2rem')};
        padding-bottom: ${p => (p.last ? '0rem' : '2rem')};
        ${p => (p.noPadding ? 'padding: 0;' : '')}
        font-family: 'Montserrat ExtraBold', sans-serif;
        font-size: 4rem;
        line-height: 1.2em;
        color: ${p => p.theme.colors.lighterText};
        @media screen and (${p => p.theme.breakpoints.mobile}) {
            font-size: 2.81rem;
        }
        transform: translate3d(0, calc( var(--section-position) * 20px ), 0);
     `,
    l: styled.h3`
        ${common}
        padding-top: ${p => (p.first || p.belowDateDisplay ? '0rem' : '2rem')};
        padding-bottom: ${p => (p.last ? '0rem' : '2rem')};
        ${p => (p.noPadding ? 'padding: 0;' : '')}
        font-family: 'Montserrat ExtraBold', sans-serif;
        font-size: 3rem;
        line-height: 1.2em;
        color: ${p => p.theme.colors.lighterText};
        @media screen and (${p => p.theme.breakpoints.mobile}) {
            font-size: 2.4rem;
        }
    `,
    m: styled.h4`
        ${common}
        padding-top: ${p => (p.first || p.belowDateDisplay ? '0rem' : '2rem')};
        padding-bottom: ${p => (p.last ? '0rem' : '1.2rem')};
        ${p => (p.noPadding ? 'padding: 0;' : '')}
        font-family: 'Montserrat Bold', sans-serif;
        font-size: 2rem;
        line-height: 1.38em;
        color: ${p => p.theme.colors.darkerText};
        @media screen and (${p => p.theme.breakpoints.mobile}) {
            font-size: 1.8rem;
        }
    `,
    s: styled.h5`
        ${common}
        padding-top: ${p => (p.first || p.belowDateDisplay ? '0rem' : '2rem')};
        padding-bottom: ${p => (p.last ? '0rem' : '1.2rem')};
        ${p => (p.noPadding ? 'padding: 0;' : '')}
        font-family: 'Montserrat Bold', sans-serif;
        font-size: 1.5rem;
        line-height: 1.5em;
        color: ${p => p.theme.colors.lighterText};
        @media screen and (${p => p.theme.breakpoints.mobile}) {
            font-size: 1.5rem;
        }
    `,
    xs: styled.h6`
        ${common}
        padding-top: ${p => (p.first || p.belowDateDisplay ? '0rem' : '2rem')};
        padding-bottom: ${p => (p.last ? '0rem' : '1.2rem')};
        ${p => (p.noPadding ? 'padding: 0;' : '')}
        font-family: 'Montserrat Bold', sans-serif;
        font-family: 'Montserrat Bold', sans-serif;
        font-size: 1.125rem;
        line-height: 1.25em;
        color: ${p => p.theme.colors.darkerText};
    `
};

const tagToStyle = {
    h1: headings.xxl,
    h2: headings.xl,
    h3: headings.l,
    h4: headings.m,
    h5: headings.s,
    h6: headings.xs
};

const Heading = ({ align, as, children, theme, spacing, noPadding, alignSelf = 'left', last = false, first = false, autoCenter, belowDateDisplay = false}) => {
    const { backgroundValue } = useSectionContext();

    let Component = tagToStyle[as] || headings.xxl;

    if (backgroundValue === 'black') {
        Component = styled(Component)`
            color: #ffffff;
        `;
    }

    const content = (typeof children == 'string') ? children.replace(/(<wbr>|<wbr\/>)/g, '&shy;') : children;

    return (
        <Component
            alignSelf={alignSelf}
            align={align}
            theme={theme}
            spacing={spacing}
            noPadding={noPadding}
            last={last}
            first={first}
            autoCenter={autoCenter}
            dangerouslySetInnerHTML={{ __html: content }}
            belowDateDisplay={ belowDateDisplay }
        />
    );
};

Heading.defaultProps = {
    spacing: 'base',
    autoCenter: true
};

Heading.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    align: PropTypes.oneOf(['left', 'center', 'right']),
    noPadding: PropTypes.bool,
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            lighterText: PropTypes.string.isRequired,
            darkerText: PropTypes.string.isRequired
        }).isRequired
    }),
    spacing: PropTypes.oneOf(['tiny', 'smaller', 'small', 'base', 'medium', 'large', 'xlarge']),
    alignSelf: PropTypes.oneOf(['center', 'left', 'right', null]),
    last: PropTypes.bool,
    first: PropTypes.bool,
    autoCenter: PropTypes.bool,
    belowDateDisplay: PropTypes.bool,
};

Heading.vcConvertToProps = atts => {
    return {
        as: atts.tag,
        align: atts.align,
    };
};

export default withTheme(Heading);
