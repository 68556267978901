import React from 'react';
//import { hot } from 'react-hot-loader/root';
import { css } from '@emotion/core';
import { withTheme } from '@emotion/react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import cookies from 'react-cookies';
import { Switch, Route } from 'react-router';
import { Helmet } from 'react-helmet-async';
import Footer from '../footer';
import PageContainer from '../page/page';
import PodcastContainer from '../podcast/loadable';
import NewsContainer from '../news/loadable';
import CookieWarning from '../../components/cookie-warning/cookie-warning';
import Header from '../header';
import GlobalStyles from './global-styles';
import GTM from '../../components/gtm/gtm';
import { useAppContext } from '../../app-context-provider/provider';
import MenuBackgroundProvider from '../header-menu/menu-background-provider';
import ErrorBoundary from '../error-boundary';
import PageTypeProvider from '../../page-type-context-provider/provider';


const rootCss = (colorOverride) => css`
    flex: 1;
    width: 100%;
    flex-direction: column;
    display: flex;
    svg {
        fill: ${colorOverride} !important;
    }
`;

const mainCss = css`
    flex: 1;
    display: flex;
    position: relative;
`;

const App = props => {

    const hasAcceptedCookies = cookies.load('accepted_cookie_terms') ? true : false;
    const [cookieNotice, setCookieNotice] = useState(hasAcceptedCookies);
    const context = useAppContext();
    const viewport = context.meta.viewport;

    if (!viewport.initiated) return null;

    const acceptedCookie = () => {
        setCookieNotice(true);
    };

    const { location } = props;
    const {
        meta: { info }
    } = context;

    return (
        <ErrorBoundary>
            <div css={rootCss(context.colorOverride)}>
                <GlobalStyles />
                <CookieWarning open={!cookieNotice} handleAccept={acceptedCookie.bind(this)} cookieMessageContent={info.cookieMessage} />

                <MenuBackgroundProvider>

                    <PageTypeProvider>

                        <Header />

                        <Helmet>
                            <html lang={info.language || 'sv'} />
                            <meta name='description' content={info.description} />
                            <meta name='keywords' content={info.keywords} />
                            <title>{info.siteName || 'No title'}</title>
                        </Helmet>


                        <GTM gtmCode={info.gtmCode} />

                        <div css={mainCss}>
                            <Switch location={location}>
                                <Route exact path='/' component={PageContainer} />
                                <Route exact path='/podcast/:slug' component={PodcastContainer} />
                                <Route exact path='/nyheter' component={NewsContainer} />
                                <Route path='/nyheter/:newsID' component={NewsContainer} />
                                <Route path='/:slug*' component={PageContainer} />
                            </Switch>
                        </div>

                    </PageTypeProvider>

                    <Footer />

                </MenuBackgroundProvider>
            </div>
        </ErrorBoundary>
    );
};

App.propTypes = {
    pageTitle: PropTypes.string,
    settings: PropTypes.object,
    anchorpointOffset: PropTypes.number,
    dispatch: PropTypes.func,
    menu: PropTypes.array,
    params: PropTypes.object,
    location: PropTypes.object,
    localization: PropTypes.object,
    viewport: PropTypes.object,
    history: PropTypes.object,
    generalOptions: PropTypes.object,
    renderType: PropTypes.string,
    routing: PropTypes.object,
    theme: PropTypes.object,
};

/*
class App extends React.PureComponent {

    static contextType = AppContext;

    componentDidMount() {
        const hasAcceptedCookies = cookies.load('accepted_cookie_terms') ? true : false;
        this.setState({
            hideCookieNotice: hasAcceptedCookies
        });
    }

    state = {
        hideCookieNotice: true
    };

    acceptedCookie() {
        this.setState({ hideCookieNotice: true });
    }

    render() {
   }
}
*/
export default withTheme(App);
