import { withTheme } from '@emotion/react';
import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { FooteContainerTop, LeftContainer, HeadingTopBlock, SubHeadingTopBlock } from './footer.style';

import React from 'react';
import PropTypes from 'prop-types';
import Section from '../../components/section/section';
import Grid from '../grid/grid';
import useIntersect from '../../lib/use-intersect';
import MagicBunny from './magic-bunny';

const slideInAnimation = keyframes`
    0% {
        transform: translate3d(0, 10rem, 0);
    }
    100% {
        transform: translate3d(0, 0rem, 0);
    }
`;

const slideOutAnimation = keyframes`
    0% {
        transform: translate3d(0, 0rem, 0);
    }
    100% {
        transform: translate3d(0, 10rem, 0);
    }
`;

const bunnyEye = keyframes`
    0% { fill: #292929 }
    5% { fill: #4CD0FF }
    10% { fill: #F29C35 }
    15% { fill: #D0118E }
    20% { fill: #292929 }
    100% { fill: #292929 }
`;

const FooterTop = ({ footerTopBlock, viewport, theme, footerRef }) => {

    if (!footerTopBlock?.top_block_heading && !footerTopBlock?.top_block_text) {
        return <></>;
    }
    const isIntersecting = useIntersect(footerRef, '0px', 0.2);
    let AnimatedContainer = styled.div`
        display: flex;
        justify-content: flex-end;
        height: 100%;
        flex-direction: column;
        svg {
            margin: 0 0 -1rem 0;
        }
        #bunnyGroup {
            animation: ${isIntersecting ? slideInAnimation : slideOutAnimation} .4s ease-in-out forwards;
            transform: translate3d(0, 10rem, 0);
        }
        .bunny-eye { animation: ${bunnyEye} 6s ease infinite; }
        .eye-elem--1 { animation-delay: 0s }
        .eye-elem--2 { animation-delay: .15s }
        .eye-elem--3 { animation-delay: .3s }
        @media screen and (${theme.breakpoints.mobile}) {
            #bunnyGroup {
                animation: none;
                transform: none;
            }
            svg {
                width: 50%;
                margin: 0 auto -1rem auto;
            }
        }
    `;

    const isMobile = viewport.width < 768 ? true : false;
    let gridItemWidth;
    if (isMobile) {
        gridItemWidth = [
            {
                col: { s: 1, e: 13 },
                row: { s: 1, e: 2 }
            },
            {
                col: { s: 1, e: 13 },
                row: { s: 2, e: 3 }
            }
        ];

    } else {
        gridItemWidth = [
            {
                col: { s: 2, e: 8 },
                row: { s: 1, e: 2 }
            },
            {
                col: { s: 9, e: 13 },
                row: { s: 1, e: 2 }
            }
        ];
    }

    let magicBunny = css`
        margin-bottom: -3rem; 
        padding: 6rem 0 0 0;
        @media screen and (${theme.breakpoints.mobile}) {
            width: 60%;
            margin: 0 auto -1rem auto;
            padding: 0rem;
        }
    `;

    // backgroundvalue set for FooterContainerTop to color the whole top footer, set on section so it can update the menu color if needed, and set on magicbunny so it uses the same background color as rest of footer
    const backgroundValue = 'lightGray';

    return (
        <FooteContainerTop backgroundValue={ backgroundValue }>
            <Section template={[{ width: '1/1' }]} viewport={viewport} verticalPadding="none" backgroundType={ 'color' } backgroundValue={ backgroundValue } width={ 'constrained' } >
                <Grid>
                    <Grid.Item col={gridItemWidth[0].col} row={gridItemWidth[0].row}>
                        <LeftContainer>
                            <HeadingTopBlock dangerouslySetInnerHTML={{__html: footerTopBlock.top_block_heading}} />
                            <SubHeadingTopBlock dangerouslySetInnerHTML={{__html: footerTopBlock.top_block_text?.replace(/(\n)/g, '<br/>')}} />
                        </LeftContainer>
                    </Grid.Item>
                    <Grid.Item col={gridItemWidth[1].col} row={gridItemWidth[1].row}>
                        <AnimatedContainer>
                            <MagicBunny css={magicBunny} backgroundValue={ backgroundValue } />
                        </AnimatedContainer>
                    </Grid.Item>
                </Grid>
            </Section>
        </FooteContainerTop>
    );
};

FooterTop.propTypes = {
    footerTopBlock: PropTypes.object,
    viewport: PropTypes.object,
    theme: PropTypes.any,
    footerRef: PropTypes.any
};

export default withTheme(FooterTop);
