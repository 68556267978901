import styled from '@emotion/styled';
import PropTypes from 'prop-types';

export const Section = styled.div`
    display: -ms-grid;
    -ms-grid-columns: 1fr 0rem ${props => props.theme.outerPadding} minmax(0, ${props => props.theme.maxWidth}px) ${props => props.theme.outerPadding} 0rem 1fr;
    position: relative;
    display: grid;
    margin: 0 auto;
    width: 100%;
    grid-template-columns: auto 0rem ${props => props.theme.outerPadding} minmax(0, ${props => props.theme.maxWidth}px) ${props => props.theme.outerPadding} 0rem auto;
    @media (${props => props.theme.breakpoints.mobile}) {
        grid-template-columns: auto 0rem ${props => props.theme.outerPaddingMobile} minmax(0, ${props => props.theme.maxWidth}px) ${props => props.theme.outerPaddingMobile} 0rem auto;
    }
`;

export const Placer = styled.div`
    -ms-grid-column: 4;
    grid-column: 4 / auto;
    position: relative;
`;

const OuterSection = ({ children, extraChildren }) => {
    return (
        <Section>
            {extraChildren}
            <Placer>{children}</Placer>
        </Section>
    );
};

OuterSection.propTypes = {
    children: PropTypes.any,
    extraChildren: PropTypes.any
};

export default OuterSection;
