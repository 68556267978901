import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';

const Iframe = (props) => {
    let { iframe } = props;
    return (
        <div className="iframe" dangerouslySetInnerHTML={{__html: iframe}} />
    );
};

Iframe.propTypes = {
    iframe: PropTypes.string
};

export default Iframe;
