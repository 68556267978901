import React from 'react';
import { withTheme } from '@emotion/react';
import PropTypes from 'prop-types';

function MagicBunny({ theme, backgroundValue }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 399 424"
        >
            <g  
                fill="#292929"
                id="bunnyGroup"
                fillRule="nonzero"
                stroke="none"
                strokeWidth="1"
                transform="translate(-1191 -6331) translate(1191 6331)"
            >
                <path
                    fill="#292929"
                    d="M124.492 181.197c-7.633-1.088-15.63 1.268-21.627 7.247-5.816 5.799-8.36 13.953-7.27 21.563l28.897-28.81z"
                    className="bunny-eye eye-elem--1"
                    transform="translate(106)"
                ></path>
                <path
                    fill="#292929"
                    d="M138.85 188.444c-1.273-1.268-2.545-2.355-3.999-3.261l-35.257 35.152c.908 1.45 1.999 2.718 3.271 3.986 1.272 1.269 2.544 2.356 3.998 3.262l35.258-35.152c-.909-1.45-2-2.718-3.271-3.987z"
                    className="bunny-eye eye-elem--2"
                    transform="translate(106)"
                ></path>
                <path
                    fill="#292929"
                    d="M117.222 231.57c7.634 1.086 15.63-1.27 21.628-7.249 5.815-5.798 8.36-13.952 7.27-21.562l-28.898 28.81z"
                    className="bunny-eye eye-elem--3"
                    transform="translate(106)"
                ></path>
                <path
                    fill="#292929"
                    d="M96.686 274.15c1.454 0 2.544-1.087 2.544-2.536v-11.597l9.996-8.516c.909-.906 1.454-2.537.909-3.805 0 0-5.453-12.14-13.267-12.14-7.815 0-13.267 12.14-13.267 12.14-.546 1.268-.182 2.899.908 3.805l9.996 8.516v11.597c-.364 1.268.727 2.536 2.18 2.536z"
                    className="svg-elem-8"
                    transform="translate(106)"
                ></path>
                <path
                    fill="#292929"
                    d="M82.692 166.338S124.31 83.17 49.07 0c0 0-60.338 89.874 33.622 166.338z"
                    className="svg-elem-9"
                    transform="translate(106)"
                ></path>
                <path
                    fill="#292929"
                    d="M99.048 167.426s95.414-15.04 106.682-126.657c0 0-112.86 5.799-106.682 126.657z"
                    className="svg-elem-10"
                    transform="translate(106)"
                ></path>
                <g fill="#292929">
                    <path
                        d="M96.868 9.328c-1.454.182-2.363 1.631-2.181 2.9.181 1.45 1.635 2.355 2.908 1.993l51.977-9.06c1.454-.181 2.363-1.63 2.181-2.9-.182-1.449-1.636-2.355-2.908-2.174L96.868 9.328z"
                        className="svg-elem-11"
                        transform="translate(106) translate(19.991 247.608)"
                    ></path>
                    <path
                        d="M97.776 18.026c-1.454-.181-2.726.725-2.908 2.174-.181 1.45.727 2.718 2.181 2.9l51.978 9.06c1.454.18 2.726-.726 2.908-1.994.182-1.45-.727-2.718-2-2.9l-52.159-9.24z"
                        className="svg-elem-12"
                        transform="translate(106) translate(19.991 247.608)"
                    ></path>
                    <path
                        d="M55.613 9.328c1.453.182 2.362 1.631 2.18 2.9-.181 1.45-1.635 2.355-2.907 2.174L2.908 5.342c-1.454-.181-2.363-1.63-2-2.9C1.09.994 2.545.088 3.818.27l51.796 9.06z"
                        className="svg-elem-13"
                        transform="translate(106) translate(19.991 247.608)"
                    ></path>
                    <path
                        d="M54.704 18.026c1.454-.181 2.726.725 2.908 2.174.181 1.45-.727 2.718-2.181 2.9L3.453 32.16C2 32.34.727 31.433.545 29.984c-.182-1.45.727-2.718 2.181-2.9l51.978-9.06z"
                        className="svg-elem-14"
                        transform="translate(106) translate(19.991 247.608)"
                    ></path>
                </g>
            </g>
            <rect id="hatShape" x="0" y="300" width="100%" height="150" fill={ theme.colors[backgroundValue]} />
            <g 
                id="hatGroup"
                fill={ theme.colors[backgroundValue] }>
                <path
                    fill="#292929"
                    d="M.182 295.713c0 4.53 3.816 8.335 8.36 8.335H38.71v111.255c0 4.53 3.816 8.335 8.36 8.335h98.866c4.544 0 8.36-3.806 8.36-8.335V304.048h30.17c4.543 0 8.36-3.805 8.36-8.335H.181zm145.755 33.702H47.252v-17.032h98.685v17.032z"
                    className="svg-elem-7"
                    transform="translate(106)"
                ></path>
            </g>
        </svg>
    );
}

MagicBunny.propTypes = {
    theme: PropTypes.object,
    backgroundValue: PropTypes.string,
};

export default withTheme(MagicBunny);