import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const Box = styled.div`
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    width: 100%;
    min-width: 288px;
    max-width: 568px;
    position: relative;
    padding: 1rem 1rem;
    background-color: #303030;
    color: white;
    font-size: 1rem;
    line-height: 1em;
    display: flex;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    flex-direction: column;
`;

const Message = styled.div`
    width: 100%;
`;
const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const show = css`
    transform: translateY(0px);
    opacity: 1;
`;

const hide = css`
    transform: translateY(300px);
    opacity: 0;
`;

class Snackbar extends React.Component {

    static propTypes = {
        handleRequestClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        action: PropTypes.array // An array with, for example, buttons [<Button>Ok</Button>, <Button>Reject</Button>]
    };

    static defaultProps = {
        action: []
    };

    constructor(props) {
        super(props);
        this.state = {
            render: true,
            timeout: -1
        };
    }

    // TODO: depricated, should be changed.
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.open !== this.props.open) {
            if (nextProps.open === false) {
                clearTimeout(this.state.timeout);
                let timeout = setTimeout(() => {
                    this.setState({ render: false });
                }, 1000);
                this.setState({ timeout });
            } else {
                clearTimeout(this.state.timeout);
                this.setState({ render: true });
            }
        }
    }

    render() {

        // OBSSS!!! The snackbar component will generate a hydration warning

        const { open, message, action } = this.props;

        if (typeof window === 'undefined' || !this.state.render) {
            return null;
        }

        // TODO: Kept this here, dunno what its for /Peter
        // Fix for IE 11. 'pointer-events': 'initial' is applied by default, but it doesnt work in IE.
        // let style = {};
        // if (!('pointer-events' in style)) {
        //     style.pointerEvents = 'auto';
        // }

        return (
            <div
                css={css`
                    position: fixed;
                    bottom: 0;
                    z-index: 100;
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    transition: all 0.5s ease-out;
                    pointer-events: auto;
                    ${open ? show : hide}
                `}
            >
                <Box>
                    <Message>{message}</Message>
                    <Actions>{action}</Actions>
                </Box>
            </div>
        );
    }
}

export default Snackbar;
