import React from 'react';
import Footer from '../../components/footer/footer';
import { useAppContext } from '../../app-context-provider/provider';
import { useQuery } from '../../graphql-client/graphql-client';
import { gql } from 'graphql-request';

const FooterContainer = () => {
    const context = useAppContext();

    const {
        meta: { viewport, settings }
    } = context;

    const { loading, error, data } = useQuery(gql`
        {
            footer {
                _id
                footerTopBlock
                officeInformation
                columnPageLinks
                socialMediaLinks
            }
        }
    `);

    if (loading || !viewport.initiated || error) return null;

    return <Footer viewport={viewport} version={settings.version} footer={data?.footer} />;
};
export default FooterContainer;
