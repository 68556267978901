import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

class TextBlock extends React.Component {

    static propTypes = {
        children: PropTypes.string,
        className: PropTypes.string
    };

    render () {

        const textBlockClasses = ClassNames(
            'text-block',
            this.props.className
        );

        return (
            <div className={textBlockClasses} dangerouslySetInnerHTML={{__html: this.props.children}}/>
        );
    }
}

export default TextBlock;
