// -----------------------------------
// --- Function to load more posts ---
// -----------------------------------


const loadMore = ({ postTypes, data, fetchMore, setMoreToLoad }) => {

    // get the postTypes prop with updated offset values
    const newPostTypes = {};

    Object.keys(data.list).forEach(type => {

        if (Array.isArray(data.list[type])) {

            newPostTypes[type] = {};

            newPostTypes[type].limit = postTypes[type].limit;

            newPostTypes[type].offset = data.list[type].length;

        }

    });

    fetchMore({

        variables: { postTypes: newPostTypes },

        updateQuery: (prev, { fetchMoreResult }) => {

            console.log('prev', prev);

            if (!fetchMoreResult) {
                setMoreToLoad(false);
                return prev;
            }

            // keep a count of how many resources we have depleted
            let depletedPostTypesCount = 0;

            // forge the result
            const list = {};

            Object.keys(prev.list).forEach(type => {

                if (Array.isArray(prev.list[type])) {

                    const results = fetchMoreResult.list[type];

                    //console.log('check ----', prev.list[type]);
                    const hasAllAlready = prev.list[type].reduce((exists, post) => {
                        return exists && results.find((item) => {
                            //console.log('has', item._id, post._id);
                            return item._id === post._id;
                        });
                    }, true);

                    if (results.length < postTypes[type].limit || hasAllAlready) {
                        depletedPostTypesCount++;
                    }

                    list[type] = [ ...prev.list[type], ...results ];

                } else {

                    // most likely this will only process __typename
                    list[type] = prev.list[type];

                }

            });

            // if we have depleted every post type, don't allow more loading
            if (depletedPostTypesCount === Object.keys(postTypes).length) {
                setMoreToLoad(false);
            }

            return Object.assign({}, prev, { list });
        }
    });
};

export default loadMore;
