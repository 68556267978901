/**
 * On browser, it adds eventlistener on resize and orientationchange and calls the callback whenever
 * an event is triggered.
 *
 * If onUnmount is called before onMount, nothing is done.
 *
 * On server, nothing is done.
 */
export default () => {
    let onResize = () => {};

    return {
        onMount: cb => {
            onResize = () => cb({
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight
            });

            if (typeof window !== 'undefined') {
                window.addEventListener('resize', onResize);
                window.addEventListener('orientationchange', onResize);

                // For now, we need to this on the next tick. It wont trigger a chnage otherwise
                setTimeout(onResize, 0);
                // onResize();
            }
        },
        onUnmount: () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', onResize);
                window.removeEventListener('orientationchange', onResize);
            }
        }
    };
};
