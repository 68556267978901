import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import { useMousePosition } from '../../lib/mouse-position';

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

const ANIMATION_MIN_X = 50;
const ANIMATION_MIN_Y = 30;
const ANIMATION_MAX_X = 600;
const ANIMATION_MAX_Y = 400;

const Hamburger = styled.button`
    outline: none;
    border-radius: 50%;
    border: 3px solid ${ props => {
        if ( props.active ) {
            return props.isHovering ? '#f2de65' : '#F29C35';
        } else {
            return 'transparent';
        }
    }};
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition-property: opacity, filter, border, background-color;
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    overflow: visible;
    transform: rotateZ(var(--position-rotation));
`;

const HamburgerBox = styled.span`
    width: 30px;
    height: 30px;
    display: inline-block;
    position: relative;
    bottom: 1px;
    left: 1px;
`;

const HamburgerInner = styled.span`
    display: block;
    top: 50%;
    margin-top: -1px;
    margin-left: -1px;
    ${ props => {
        if ( props.active ) {
            return 'transform: rotate(225deg); transition-duration: 0.3s;';
        } else {
            return 'transition-duration: 0.22s;';
        }
    }}
    transition-timing-function: ${ props => props.active ? 'cubic-bezier(0.215, 0.61, 0.355, 1)' : 'cubic-bezier(0.55, 0.055, 0.675, 0.19)' };
    &, &:before, &:after {
        width: 30px;
        height: 4px;
        background-color: ${ props => {
        if ( props.active ) {
            return props.isHovering ? '#f2de65' : '#F29C35';
        } else {
            return '#000';
        }
    }};
        position: absolute;
        transition-property: transform, background-color;
    }
    &:before, &:after {
        content: "";
        display: block; 
    }
    &:before {
        top: ${ props => props.active ? '0' : '-8px' };
        ${ props => props.active ? 'opacity: 0;' : null }
        transition: ${ props => props.active ? 'background-color 0.1s ease-in-out, top 0.1s ease-out, opacity 0.1s ease-out' : 'background-color 0.5s ease-in-out, top 0.1s 0.22s ease-in, opacity 0.12s ease-in' };
    }
    &:after {
        bottom: ${ props => props.active ? '0' : '-8px' };
        ${ props => props.active ? 'transform: rotate(-90deg);' : null }
        transition: ${ props => props.active ? 'background-color 0.1s ease-in-out, bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1)' : 'background-color 0.5s ease-in-out, bottom 0.1s 0.22s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)' };
    }
`;

// Create the animation login outside of react so we dont get to any hook mess
const createAnimation = () => {
    let current = 0;
    let goal = 0;
    let delta = 0;
    let element =  null;
    let frameRef = 0;
    const runAnimation = (newElement, newGoal, newDelta) => {
        goal = newGoal;
        delta = newDelta;
        element = newElement;
        window.cancelAnimationFrame(frameRef);
        animate();
    };
    const animate = () => {
        frameRef = window.requestAnimationFrame(() => {
            let angleDelta = (goal - current) / delta;
            angleDelta = Math.abs(angleDelta) < .001 ? (goal - current) : angleDelta;
            current += angleDelta;
            element.style.setProperty('--position-rotation', current + 'rad');
            if (goal === current) {
                return;
            }
            animate();
        });
    };
    return runAnimation;
};

const HamburgerIcon = ( props ) => {

    const [ hovering, setHovering ] = useState(false);
    const [ animation, setAnimation ] = useState(null);
    const hamburgerRef = useRef(null);
    const position = useMousePosition();

    useEffect(() => {
        setAnimation(() => createAnimation());
    }, []);

    const animate = (element, goal, delta) => {
        if (element && animation) {
            animation(element, goal, delta);
        }
    };

    useEffect(() => {
        const width = window.innerWidth;
        const menuDelta = window.pageYOffset < 100 ? width * .03 * 2: width * .01 * 2;
        const posX = width - position.x - menuDelta;
        const posY = position.y - menuDelta;
        if (posX < ANIMATION_MIN_X && posY < ANIMATION_MIN_Y) {
            animate(hamburgerRef.current, 0, 5);
        } else if ((posY < ANIMATION_MAX_Y && posX > ANIMATION_MIN_X && posX < ANIMATION_MAX_X) || (posX > ANIMATION_MIN_X && posX < ANIMATION_MAX_X && posY > ANIMATION_MIN_Y && posY < ANIMATION_MAX_Y)) {
            const rad = Math.atan(posY / posX) * -1;
            animate(hamburgerRef.current, rad, 2);
        } else {
            animate(hamburgerRef.current, 0, 5);
        }
    }, [position.x, position.y]);

    return(
        <Hamburger
            ref={hamburgerRef}
            aria-label='Menu toggle button'
            onMouseEnter={ () => setHovering(true) }
            onMouseLeave={ () => setHovering(false) }
            isHovering={ hovering }
            active={ props.active }>
            <HamburgerBox>
                <HamburgerInner
                    active={props.active}
                    isHovering={ hovering } />
            </HamburgerBox>
        </Hamburger>
    );
};

HamburgerIcon.propTypes = {
    active: PropTypes.bool,
};

export default HamburgerIcon;
