import { css } from '@emotion/core';
import Image from '../image';

const styles = (props) => {

    let { 
        width,
        backgroundType,
        backgroundValue,
        theme: {
            gap,
            outerPadding,
            outerPaddingMobile,
            spacing,
            colors,
            gradients,
        },
        verticalPadding,
        verticalMargin,
        visualEffect,
    } = props;

    const verticalMap = {
        'none': '0rem',
        'medium': spacing.base,
        'big': spacing.xlarge,
    };

    verticalPadding = verticalPadding || 'medium';
    verticalMargin = verticalMargin || 'none';

    const verticalMapMobile = {
        'none': '0rem',
        'medium': spacing.base,
        'big': spacing.base,
    };

    let sectionBase = css`
        padding-top: ${verticalMap[verticalPadding]};
        padding-bottom: ${verticalMap[verticalPadding]};
        margin-top: ${verticalMap[verticalMargin]};
        margin-bottom: ${verticalMap[verticalMargin]};
        grid-template-columns: auto 0rem ${props.theme.outerPadding} minmax(0, ${props.theme.maxWidth}px) ${props.theme.outerPadding} 0rem auto;
        @media ${props.theme.breakpoints.normalDesktop} {
            grid-template-columns: auto 0rem ${props.theme.outerPadding} minmax(0, ${props.theme.maxWidthNormalDesktop}px) ${props.theme.outerPadding} 0rem auto;
        }
        @media (${props.theme.breakpoints.mobile}) {
            padding-top: ${verticalMapMobile[verticalPadding]};
            padding-bottom: ${verticalMapMobile[verticalPadding]};
        }
    `;

    let sectionCss = css`
        ${sectionBase}
    `;

    let placerCss = '';
    let backgroundImage = null;

    if (width === 'normal') {

        if (backgroundType === 'color' || backgroundType === 'gradient') {

            const backgroundCss = backgroundValue ? (colors[backgroundValue] || gradients[backgroundValue]) : 'none';

            placerCss =
                css`
                    background: ${ backgroundCss };
                    padding-top: ${ verticalMap[verticalPadding] };
                    padding-bottom: ${ verticalMap[verticalPadding] };
                    margin-bottom: ${gap.col};
                    padding-left: ${gap.col};
                    padding-right: ${gap.col};
                    margin-left: -${gap.col};
                    margin-right: -${gap.col};
                `;


            sectionCss = css`
                padding-top: ${gap.col};
                grid-template-columns: auto 0rem ${props.theme.outerPadding} minmax(0, ${props.theme.maxWidth}px) ${props.theme.outerPadding} 0rem auto;
                @media ${props.theme.breakpoints.normalDesktop} {
                    grid-template-columns: auto 0rem ${props.theme.outerPadding} minmax(0, ${props.theme.maxWidthNormalDesktop}px) ${props.theme.outerPadding} 0rem auto;
                }
            `;
        }

        if (backgroundType === 'image') {

            sectionCss = css`
                ${sectionBase}
                @media ${props.theme.breakpoints.normalDesktop} {
                    grid-template-columns: auto 0rem ${props.theme.outerPadding} minmax(0, ${props.theme.maxWidthNormalDesktop}px) ${props.theme.outerPadding} 0rem auto;
                }
            `;

            backgroundImage = backgroundValue ? (
                <div
                    css={css`
                        margin-top: -${gap.col};
                        height: calc(100% + ${gap.col} + ${gap.col});
                        width: calc(100% + ${gap.col} + ${gap.col});
                        margin-left: -${gap.col};
                        position: absolute;
                        z-index: -9;
                    `}
                >
                    <Image
                        rounded={false}
                        src={backgroundValue.url}
                        jwt={backgroundValue.jwt}
                        isBackground={true}
                        cover={true}
                    />
                </div>
            ) : null;
        }

        if (visualEffect === 'cutoff') {
            sectionCss = css`
                ${sectionCss}
                box-shadow: inset 0px 12px 8px -10px rgba(0,0,0,.5), inset 0px -10px 8px -10px rgba(0,0,0,0.3);
            `;
        }

    } else if (width === 'full') {

        sectionCss = backgroundValue ? 
            css`
                ${sectionBase}
                background: ${ colors[backgroundValue] || gradients[backgroundValue] || 'none'};
                -ms-grid-columns: ${outerPadding} minmax(0, 1fr) ${outerPadding};
                grid-template-columns: ${outerPadding} minmax(0, 1fr) ${outerPadding};
                @media ${props.theme.breakpoints.normalDesktop} {
                    grid-template-columns: ${props.theme.outerPadding} minmax(0, 1fr) ${props.theme.outerPadding};
                }
                @media (${props.theme.breakpoints.mobile}) {
                    -ms-grid-columns: ${outerPaddingMobile} minmax(0, 1fr) ${outerPaddingMobile};
                    grid-template-columns: ${outerPaddingMobile} minmax(0, 1fr) ${outerPaddingMobile};
                }
            ` : css`
                ${sectionBase}
                -ms-grid-columns: ${outerPadding} minmax(0, 1fr) ${outerPadding};
                grid-template-columns: ${outerPadding} minmax(0, 1fr) ${outerPadding};
                @media ${props.theme.breakpoints.normalDesktop} {
                    grid-template-columns: ${props.theme.outerPadding} minmax(0, 1fr) ${props.theme.outerPadding};
                }
                @media (${props.theme.breakpoints.mobile}) {
                    -ms-grid-columns: ${outerPaddingMobile} minmax(0, 1fr) ${outerPaddingMobile};
                    grid-template-columns: ${outerPaddingMobile} minmax(0, 1fr) ${outerPaddingMobile};
                }
            `;

        placerCss = css`
            grid-column: 2 / auto;
        `;

        if (backgroundType === 'image') {
            backgroundImage = backgroundValue ? (
                <div
                    css={css`
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        z-index: -9;
                    `}
                >
                    <Image
                        rounded={false}
                        src={backgroundValue.url}
                        jwt={backgroundValue.jwt}
                        isBackground={true}
                        cover={true}
                    />
                </div>
            ) : null;
        }

        if (visualEffect === 'cutoff') {
            sectionCss = css`
                ${sectionCss}
                box-shadow: inset 0px 12px 8px -10px rgba(0,0,0,.5), inset 0px -10px 8px -10px rgba(0,0,0,0.3);
            `;
        }

    } else if ( width === 'jflow') {

        sectionCss = backgroundValue ? 
            css`
                margin-top: ${verticalMap[verticalMargin]};
                margin-bottom: ${verticalMap[verticalMargin]};
                grid-template-columns: auto 0rem ${props.theme.outerPadding} minmax(0, ${props.theme.contentMaxWidth}) ${props.theme.outerPadding} 0rem auto;
                -ms-grid-columns: auto 0rem ${props.theme.outerPadding} minmax(0, ${props.theme.contentMaxWidth}) ${props.theme.outerPadding} 0rem auto;
                @media ${props.theme.breakpoints.normalDesktop} {
                    grid-template-columns: auto 0rem ${props.theme.outerPadding} minmax(0, ${props.theme.contentMaxWidth}) ${props.theme.outerPadding} 0rem auto;
                }
                @media (${props.theme.breakpoints.mobile}) {
                    padding-top: ${verticalMapMobile[verticalPadding]};
                    padding-bottom: ${verticalMapMobile[verticalPadding]};
                     -ms-grid-columns: ${outerPaddingMobile} minmax(0, 1fr) ${outerPaddingMobile};
                    grid-template-columns: ${outerPaddingMobile} minmax(0, 1fr) ${outerPaddingMobile};
                }
            ` : css`
                margin-top: ${verticalMap[verticalMargin]};
                margin-bottom: ${verticalMap[verticalMargin]};
                padding-top: ${verticalMap[verticalPadding]};
                padding-bottom: ${verticalMap[verticalPadding]};
                -ms-grid-columns: ${outerPadding} minmax(0, 1fr) ${outerPadding};
                grid-template-columns: auto 0rem ${outerPadding} minmax(0, 1fr) ${outerPadding} 0rem auto;
                @media ${props.theme.breakpoints.normalDesktop} {
                    grid-template-columns: auto 0rem ${props.theme.outerPadding} minmax(0, ${props.theme.contentMaxWidth}) ${props.theme.outerPadding} 0rem auto;
                }
                @media (${props.theme.breakpoints.mobile}) {
                    -ms-grid-columns: ${outerPaddingMobile} minmax(0, 1fr) ${outerPaddingMobile};
                    grid-template-columns: ${outerPaddingMobile} minmax(0, 1fr) ${outerPaddingMobile};
                    padding-top: ${verticalMapMobile[verticalPadding]};
                    padding-bottom: ${verticalMapMobile[verticalPadding]};
                 }
            `;

        if (backgroundType === 'color' || backgroundType === 'gradient') {
            const backgroundCss = backgroundValue ? (colors[backgroundValue] || gradients[backgroundValue]) : 'none';
            let padding = gap.col.replace(/rem/, '') * 2;
            placerCss = css`
                background: ${ backgroundCss };
                padding-top: ${ verticalMap[verticalPadding] };
                padding-bottom: ${ verticalMap[verticalPadding] };
                padding-left: ${padding}rem;
                padding-right: ${padding}rem;
                margin-left: -${padding}rem;
                margin-right: -${padding}rem;
            `;
        }

        placerCss = css`
            ${placerCss}
            @media (${props.theme.breakpoints.mobile}) {
                grid-column: 2 / auto;
            }
        `;

    } else {

        if (backgroundType === 'color' || backgroundType === 'gradient') {
            sectionCss = backgroundValue ? 
                css`
                    ${sectionBase}
                    background: ${ colors[backgroundValue] || gradients[backgroundValue] || 'none'};
                ` : '';
        }

        if (backgroundType === 'image') {
            backgroundImage = backgroundValue ? (
                <div
                    css={css`
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        z-index: -9;
                    `}
                >
                    <Image
                        rounded={false}
                        src={backgroundValue.url}
                        jwt={backgroundValue.jwt}
                        isBackground={true}
                        cover={true}
                    />
                </div>
            ) : null;
        }

        if (visualEffect === 'cutoff') {
            sectionCss = css`
                ${sectionCss}
                box-shadow: inset 0px 12px 8px -10px rgba(0,0,0,.5), inset 0px -10px 8px -10px rgba(0,0,0,0.3);
            `;
        }

    }

    return {
        sectionCss,
        placerCss,
        backgroundImage
    };

};


export default styles;
