import identity from './identity-converter';
import stackBelow from './stack-below-converter';

/**
 * The mobile converter, i.e it return the layouts targeting small screen sizes. The 'config.grid' is spread
 * onto the Grid component. Can be used to control gap for example.
 *
 * @param {Number} gridCols Number of grid columns, most likely 12.
 * @param {Array} template Array of objects with 'width' information, for example {width: '1/3'}
 * @param {Array} children Array of columns.
 * @returns placement, children and extra grid config.
 */
const mobile = (gridCols, template, children) => {

    // TODO: On mobile, we only have one col
    gridCols = 1;

    const cols = template.length;

    // All supported layouts here for future reference ->

    // if (cols == 1 && template[0].width == '1/1') {}

    // Remove row and col gaps for this conversion and stack below but reorder children.
    if (cols == 2 && template[0].width == '1/2' && template[1].width == '1/2') {
        return stackBelow(gridCols, template, children);
    }

    /**
     * The 2 examples below are doing the same thing. The difference is how.
     *
     * The first example the placement is source based and children are rotated.
     * The second example the placement is destination based and children kept as is.
     * Note, that you could remove children if you want.
     */

    // if (cols == 2 && template[0].width == '1/2' && template[1].width == '1/2') {
    //     return {
    //         placement: [
    //             {
    //                 col: { s: 1, e: 1 + gridCols },
    //                 row: { s: 1, e: 2 }
    //             },
    //             {
    //                 col: { s: 6, e: 6 + 6 },
    //                 row: { s: 2, e: 3 }
    //             }
    //         ],
    //         children: [children[1], children[0]]
    //     };
    // }

    // if (cols == 2 && template[0].width == '1/2' && template[1].width == '1/2') {
    //     return {
    //         placement: [
    //             {
    //                 col: { s: 6, e: 6 + 6 },
    //                 row: { s: 2, e: 3 }
    //             },
    //             {
    //                 col: { s: 1, e: 1 + gridCols },
    //                 row: { s: 1, e: 2 }
    //             }
    //         ],
    //         children: children
    //     };
    // }

    /**
     * Example below showing how to include props that will be spread on the Grid and all Grid.Items.
     */
    // if (cols == 2 && template[0].width == '1/2' && template[1].width == '1/2') {
    //     return {
    //         placement: [
    //             {
    //                 col: { s: 1, e: 1 + gridCols },
    //                 row: { s: 1, e: 2 }
    //             },
    //             {
    //                 col: { s: 1, e: 1 + gridCols },
    //                 row: { s: 2, e: 3 }
    //             }
    //         ],
    //         children,
    //         config: {
    //             grid: {
    //                 gap: {
    //                     col: 16,
    //                     row: 0
    //                 }
    //             },
    //             item: [
    //                 {
    //                     align: 'center'
    //                 },
    //                 {
    //                     align: 'start'
    //                 }
    //             ]
    //         }
    //     };
    // }

    if (cols == 2 && template[0].width == '1/3' && template[1].width == '2/3') {
        return stackBelow(gridCols, template, children);
    }

    if (cols == 2 && template[0].width == '2/3' && template[1].width == '1/3') {
        return stackBelow(gridCols, template, children);
    }

    if (cols == 3 && template[0].width == '1/3' && template[1].width == '1/3' && template[2].width == '1/3') {
        return stackBelow(gridCols, template, children);
    }

    if (cols == 3 && template[0].width == '1/4' && template[1].width == '1/2' && template[2].width == '1/4') {
        return stackBelow(gridCols, template, children);
    }

    return identity(gridCols, template, children);

};

export default mobile;
