import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { setDataLayer } from '../../lib/gtm';
import { isExternalLink } from '../../lib/data-helper';
import styled from '@emotion/styled';
import Text from '../text/text';

// ----------------------------------------------------------------------------

const ButtonBase = styled.button`
    cursor: pointer;
    padding: 0.8rem 2.5rem .7rem;
    min-width: 10rem;
`;

const StyledContainer = styled.div`
    max-width: ${p => p.theme.contentMaxWidth};
 `;

const buttonVariants = {
    default: styled(ButtonBase)`
        background-color: ${p => (p.color === 'secondary' ? p.theme.colors.twentyfourOrange : '#2A2A2A')};
        color: ${p => (p.color === 'secondary' ? p.theme.colors.black : '#2A2A2A')};
        border-radius: 2rem;
        text-transform: uppercase;
        transition: background-color .3s ease-out;
        :hover {
            background-color: ${p => (p.color === 'secondary' ? p.theme.colors.pear : p.theme.colors.pear)};
        }
        :focus {
            outline: 0;
        }
    `
};

// ----------------------------------------------------------------------------

const sizes = {
    small: 'small',
    medium: 'normal',
    large: 'large',
};

const Button = ({ color, onClick, text, variant, inverted, size }) => {
    const Button = buttonVariants[variant];
    const translatedSize = sizes[size] || 'normal';
    return (
        <StyledContainer>
            <Button onClick={onClick} color={color} inverted={inverted}>
                <Text as='span' inverted={true} size={translatedSize}>
                    {`<strong>${text}</strong>`}
                </Text>
            </Button>
        </StyledContainer>
    );
};

Button.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
    variant: PropTypes.oneOf(['default']).isRequired,
    inverted: PropTypes.bool,
    color: PropTypes.string,
    size: PropTypes.string,
};

// ----------------------------------------------------------------------------

const ButtonWrapper = ({ onClick, url, gtmLabel, gtmCategory, openNewTab, ...rest }) => {
    const handleClick = e => {
        if (gtmLabel && gtmCategory) {
            setDataLayer({ action: 'click', gtmCategory, gtmLabel });
        }
        onClick(e);
    };

    if (url) {
        const target = openNewTab ? '_blank' : '_self';
        if (target === '_blank' || isExternalLink(url)) {
            return (
                <a href={url} target={target} onClick={handleClick}>
                    <Button {...rest} />
                </a>
            );
        } else {
            return (
                <Link to={url} target={target} onClick={handleClick}>
                    <Button {...rest} />
                </Link>
            );
        }
    } else {
        return <Button {...rest} onClick={handleClick} />;
    }
};

ButtonWrapper.defaultProps = {
    onClick: () => {},
    variant: 'default',
    text: 'Button',
    inverted: false
};

ButtonWrapper.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    inverted: PropTypes.bool,
    variant: PropTypes.oneOf(['default']).isRequired,
    gtmLabel: PropTypes.string,
    gtmCategory: PropTypes.string,
    url: PropTypes.string,
    openNewTab: PropTypes.bool
};

Button.parseProps = atts => {
    return {
        gtmLabel: atts.GTMlabel,
        gtmCategory: atts.GTMcategory
    };
};

export default ButtonWrapper;
