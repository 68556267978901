/*
    These should have the same value as the corresponnding variables in /css/variables.styl
*/

export default {
    colors: {
        next24hr: {
            black: '#000000',
            white: '#FFFFFF',
            blue: '#4CD0FF',
            green: '#4ADC65',
            yellow: '#F5DE49',
            tangerine: '#F29C35',
            orange: '#F15A22',
            red: '#C31F23',
            purple: '#D0118E',
        },
    },
    fonts: {
        mainFonts: 'SST,"Helvetica Neue",Helvetica,Arial,sans-serif'
    },
    breakpoints: {
        smallMobileMaxWidth: '320',
        mobileMaxWidth: '420',
        tabletMaxWidth: '768',
        desktopMaxWidth: 1300
    },
    padding: {
        desktop: {
            bottom: 24,
        },
        mobile: {
            bottom: 32,
        },
    },
    mobileMenu: {
        width: '80' // unit will be used as vw
    }
};

