import React from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    max-width: ${p => p.theme.contentMaxWidth};
    width: 100%;
    color: ${p => p.theme.colors.darkerGray};
    ${p => p.compensateForHeading ?  'margin-top: -3.5rem; padding-bottom: 2rem;' : ''}
    text-align: center;
`;

const DateDisplay = ({ date, author, compensateForHeading = false }) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    return (
        <StyledContainer compensateForHeading={compensateForHeading}>{ formattedDate } - { author }</StyledContainer>
    );
};

DateDisplay.propTypes = {
    date: PropTypes.string.isRequired,
    author: PropTypes.string,
    compensateForHeading: PropTypes.bool,
};

export default DateDisplay;
