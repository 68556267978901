import { css} from '@emotion/core';

export const innerCss = css`
    width: 100%;
    box-sizing: border-box;
    min-height: 160px;
    opacity: { this.state.visibles[item.id] ? 1 : 0.1 };
    transition: all .3s ease-out;
`;

export const textCss = css`
    padding: 2rem;
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    bottom: 0;
    left: 0;
    flex: 1;
    position: absolute;
`;

export const contentCss = css`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const typeCss = css`
    color: #292929;
    margin-bottom: 0.9rem;
    font-size: 0.8rem;
    font-family: 'Montserrat Bold';
`;
