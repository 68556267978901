import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSectionContext } from '../section/section-provider';
import { useAppContext } from '../../app-context-provider/provider';

const alignmentMap = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
};

const Column = styled.div`
    display: flex;
    flex: 1;
    flex-direction: ${p => p.direction || 'column'};
    justify-content: ${({verticalAlignment}) => getAlignment(verticalAlignment)};
    align-items: ${p => alignmentMap[p.alignment] || ''};
    text-align: ${p => p.alignment};
`;

const getAlignment = value => {
    let alignment = 'start';
    switch (value) {
        case 'center':
            alignment = 'center';
            break;
        case 'bottom':
            alignment = 'end';
            break;
    }
    return alignment;
};

const ColumnComponent = ({ alignment, children, gridItemWidth, itemIndex, itemTotal, direction = 'column' }) => {

    const context = useAppContext();
    const { isMobile } = context;

    if (isMobile()) {
        direction = 'column';
    }

    const { verticalAlignment } = useSectionContext();
    const elements = React.Children.toArray(children);
    const clonedChildren = elements.map((child, index) => {
        return React.cloneElement(child, {
            gridItemWidth,
            last: index === elements.length - 1,
            first: index === 0,
            itemIndex, 
            itemTotal,
            direction
        });
    });

    return <Column isMobile={isMobile} verticalAlignment={verticalAlignment} alignment={alignment} direction={direction}>{clonedChildren}</Column>;

};

ColumnComponent.propTypes = {
    children: PropTypes.any,
    gridItemWidth: PropTypes.number,
    itemIndex: PropTypes.number,
    itemTotal: PropTypes.number,
    alignment: PropTypes.oneOf(['left', 'center', 'right', null]),
    direction: PropTypes.oneOf(['column', 'row', null]),
};

export default ColumnComponent;
