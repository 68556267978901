import { isServer } from '../../lib/is-server';

// Create a checksum value to find a suitable height to start the masonry
// This is taken from the internetz
const calculateUneveness = (s) => {
    let hash = 0; 
    let strlen = s.length;
    let c;
    if ( strlen === 0 ) {
        return hash;
    }
    for ( let i = 0; i < strlen; i++ ) {
        c = s.charCodeAt( i );
        hash = ((hash << 5) - hash) + c;
        hash = hash & hash; // Convert to 32bit integer
    }
    hash = Math.abs(hash);
    let stringHash = hash.toString();
    stringHash = stringHash.substring(stringHash.length - 3);
    let total = Math.round(parseInt(stringHash, 10) / 10);
    return total * 4;
};

const hashCode = (text) => {
    let hash = 0;
    if (text.length == 0) {
        return hash;
    }
    for (let i = 0, ii = text.length; i < ii; i++) {
        const char = text.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
};

const calculateItemSize = (singleGridWidth, item, gridWidth, returnCached = false) => {

    if (returnCached && item.grid) {
        return item.grid;
    }

    let width = item.width || 1;
    width = Math.min(width, gridWidth);

    const image = item.image;

    let aspectRatio = 0;
    if (image) {
        aspectRatio = image.width / image.height;
        aspectRatio = Math.min( aspectRatio, 4/3 ); //  - Math.random() * .3 );
    } else {
        aspectRatio = 4 / 3 * 1.2;
    }

    const imageHeight = image ? Math.round(singleGridWidth * width / aspectRatio) : 200;
    const imageSize = image ? Math.round( ( imageHeight + Math.pow(aspectRatio, 3) + 2 * aspectRatio ) / 14 ) + 1: 5;
    const titleSize = item.title ? Math.max(1, Math.round(item.title.length / singleGridWidth * 16)) : 1;
    const preambleSize = item.preamble ? Math.max(1, Math.round(item.preamble.length / singleGridWidth * 10)) + 1: 0;
    const height = imageSize + titleSize + preambleSize;

    return {
        width,
        height,
        sizes: { preambleSize, aspectRatio, imageSize, titleSize, imageHeight },
    };

};

const parseSizes = (singleGridWidth, items, gridWidth, uneven, isMultiTypeList) => {

    let heights = [];

    // Calculate a consequent uneveness for the height
    const allUneveness = items.slice(0, 3).map(item => calculateUneveness(item.title));
    const minUneveness = Math.min.apply(null, allUneveness);
    const startHeightForUneveness = allUneveness.map(height => height - minUneveness);

    const parsedItems = items.map((originalItem, index) => {

        let item = {...originalItem};

        item.grid = {
            size: calculateItemSize(singleGridWidth, item, gridWidth),
            position: {},
        };

        item.grid.position.x = Math.min(index % 3, gridWidth - 1);

        if (!heights[item.grid.position.x]) {
            if (!uneven || index >= 3 || gridWidth === 1) {
                heights[item.grid.position.x] = 0;
            } else {
                heights[item.grid.position.x] = startHeightForUneveness[item.grid.position.x];
            }
        }

        const posY = heights[item.grid.position.x];
        item.grid.position.y = posY;

        // make some space for type label if we are to show it
        const typeLabelSpace = isMultiTypeList ? 30 : 0;

        const extraHeight = 32;

        const itemHeight = item.grid.size.sizes.imageHeight + extraHeight + 25 * item.grid.size.sizes.titleSize + typeLabelSpace + item.grid.size.sizes.preambleSize * 30 - 12; // item.grid.size.sizes.titleSize * 40;

        const itemWidth = item.grid.size.width * 1 / gridWidth * 100;
        const gapWidth = (gridWidth - 1) / gridWidth * 48;
        const itemWidthString = `(${itemWidth}% - ${gapWidth}px)`;
        heights[item.grid.position.x] += itemHeight + 72;

        item.grid.itemWidthString = itemWidthString;
        item.grid.itemWidth = itemWidth;
        item.grid.itemHeight = itemHeight;

        return item;

    });

    // Remove the last padding
    heights[0] -= 72;
    if (heights.length > 1) {
        heights[1] -= 72;
    }
    if (heights.length > 2) {
        heights[2] -= 72;
    }

    return {
        heights,
        items: parsedItems,
    };

};

const cache = {};

const create = (singleGridWidth, items, width = 3, uneven = false, isMultiTypeList = false) => {

    let key = null;
    if (isServer) {
        const keyString = `${singleGridWidth}-${JSON.stringify(items)}-${width}-${uneven}`;
        key = hashCode(keyString);
        if (cache[key]) {
            return cache[key];
        }
    }

    if (items.length === 0) {
        return items;
    }

    const cloned = items.map((item, index) => ({...item, __masonryIndex: index}));

    const withSizes = parseSizes(singleGridWidth, cloned, width, uneven, isMultiTypeList);
    const result = {items: withSizes.items, heights: withSizes.heights}; // masonryBuilder(withSizes, width, uneven, width === 1 ? 0 : 2);

    if (!isServer) {
        cache[key] = result;
    }

    return result;

};

export default create;
