import App from '../isomorphic/features/app/app';
import { request } from 'graphql-request';

import fetch from 'unfetch';
import React from 'react';
import { hydrate, render } from 'react-dom';

import { loadableReady } from '@loadable/component';
import { BrowserRouter, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

//import ApolloClient from 'apollo-client';
//import { ApolloProvider } from '@apollo/react-hooks';
//import { InMemoryCache } from 'apollo-cache-inmemory';
//import { createHttpLink } from 'apollo-link-http';

import AppContextProvider from '../isomorphic/app-context-provider/provider';
import EmotionThemeProvider from '../isomorphic/emotion/provider';
import { GraphqlContextProvider } from '../isomorphic/graphql-client/graphql-client';

// Get the data from a script tag and parse it. This is much more efficient than
// passing the data as a real javascript object (https://www.youtube.com/watch?v=ff4fgQxPaO0)
const getData = (id) => {
    try {
        return JSON.parse(document.getElementById(id).innerHTML); // eslint-disable-line
    } catch (err) {
        console.log("error", id, err); // eslint-disable-line
        return {};
    }
};

//const apolloClient = new ApolloClient({
//    cache: new InMemoryCache().restore(getData('apollo-state')),
//    link: createHttpLink({ uri: '/graphql', fetch })
//});

const appState = getData('app-state');
const clientCache = getData('client-cache');

const graphqlClient = (query, variables) => {
    return request('/graphql', query, variables);
};

// Since this will only be set in the frontend, we dont need to have the complete url
appState.meta.settings.apiUrl = appState.meta.settings.apiUrl.replace(/http(|s):\/\/.+?\//, '/');

//const render = process.env.NODE_ENV == 'production' ? hydrate : render;
hydrate(
    <HelmetProvider>
        <GraphqlContextProvider client={graphqlClient} cache={clientCache}>
            <AppContextProvider appState={appState}>
                <BrowserRouter>
                    <EmotionThemeProvider>
                        <Route component={App} />
                    </EmotionThemeProvider>
                </BrowserRouter>
            </AppContextProvider>
        </GraphqlContextProvider>
    </HelmetProvider>,
    document.querySelector('[data-root]')
);


//window.__PROGRESSIVE_LOADABLE =appState.meta.progressiveLoadableScriptFiles;

setTimeout(() => {
    //requestAnimationFrame(() => {
    //    let scriptsToLoad = appState.meta.progressiveLoadableScriptFiles;
    //    scriptsToLoad.forEach(scriptItem => {
    //        const script = document.createElement('script');
    //        script.async = true;
    //        script.src = scriptItem.file;
    //        document.head.appendChild(script);
    //    });
    //});
}, 1000);

// Hide the "add to home screen" button
if (window.BeforeInstallPromptEvent) {
    window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault();
    });
}

// ***************************** Serviceworker ******************************
if ('serviceWorker' in navigator) {
    let bundles = appState.meta.settings.bundles;
    let parsedBundles = bundles.map(bundle => encodeURIComponent(bundle)).join(',');

    // Register a service worker hosted at the root of the
    // site using the default scope.
    navigator.serviceWorker
        .register(`${appState.meta.settings.serviceWorkerScript}?version=${appState.meta.settings.version}&scripts=${parsedBundles}`)
        .then(() => {})
        .catch(function(error) {
            console.log('Service worker registration failed:', error); // eslint-disable-line
        });
}
