import React from 'react';
import styles from '../../lib/style-variables';
import { useAppContext } from '../../app-context-provider/provider';
import MobileMenu from './mobile';
import DesktopMenu from './desktop';
import MenuProvider from './menu-provider';

const HeaderMenu = () => {
    const context = useAppContext();
    const {
        meta: { viewport },
        isMobile,
    } = context;

    let Menu = null;
    if (viewport.initiated) {
        if (viewport && viewport.width <= styles.breakpoints.tabletMaxWidth) {
            Menu = MobileMenu;
        } else {
            Menu = DesktopMenu;
        }
    }

    // Override if mobile
    if (isMobile()) {
        Menu = MobileMenu;
    }

    return (
        <MenuProvider>
            <Menu />
        </MenuProvider>
    );
};

export default HeaderMenu;
