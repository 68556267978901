/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import { withTheme } from '@emotion/react';

import Grid from '../grid/grid';
import layoutStyles from './layout-styles';
import mobileLayoutConverter from './layout-converters/converters/section-mobile-layout-converter';
import identityConverter from './layout-converters/converters/identity-converter';
import * as helper from './layout-converters/helper';
import { SectionContext, useSectionContext } from './section-provider';

import { Section, Placer } from './outer-section';
import { useMenuBackground } from '../../features/header-menu/menu-background-provider';

const GRID_COLUMNS = 12;

const getConverter = viewport => {
    const converter = viewport.width <= 768 ? mobileLayoutConverter : identityConverter;
    return converter;
};

const getAlignment = value => {
    let alignment = 'start';
    switch (value) {
        case 'center':
            alignment = 'center';
            break;
        case 'bottom':
            alignment = 'end';
            break;
    }
    return alignment;
};

const SectionComponent = props => {

    const { viewport, children, template, theme, width, backgroundValue, backgroundType, verticalAlignment } = props;

    const sectionRef = useRef(null);

    const menuBackgroundContext = useMenuBackground();

    useEffect(() => {
        const onScroll = () => {
            if (sectionRef?.current) {
                const rect = sectionRef.current.getBoundingClientRect();
                const distTop = rect.y + rect.height / 2;

                // this checks if the section should set the background color of the header menu
                if (window.pageYOffset > 105 && -rect.y < rect.height - 81 && rect.y < 81) {

                    // dispatch actual background value if background is full width, otherwise dispatch null to use fallback background for menu
                    const backgroundValue = (width === 'full' || width === 'constrained') ? sectionContext.backgroundValue : null;

                    menuBackgroundContext.dispatch({ type: 'setMenuBackground', payload: backgroundValue });

                }
                // slideAmount is between [-0.5,..., 0.5]
                const slideAmount = distTop / window.innerHeight;
                const slideLimited = Math.max(0, Math.min(1, slideAmount));
                sectionRef.current.style.setProperty('--section-position', slideLimited);
            }
        };
        window.addEventListener('scroll', onScroll);
        onScroll();
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [ backgroundValue, backgroundType, width ]);

    const sectionItems = children ? (Array.isArray(children) ? children : [children]) : [];

    const sectionContext = { backgroundType, backgroundValue, verticalAlignment };

    const parentContext = useSectionContext();

    if (backgroundType === 'transparent' && parentContext.backgroundValue) {

        // if section is transparent and parent section is not, inherit style
        sectionContext.backgroundValue = parentContext.backgroundValue;
        sectionContext.backgroundType = parentContext.backgroundType;
        sectionContext.verticalAlignment = parentContext.verticalAlignment;

        let themeMaxWidth = theme.maxWidth;
        if (viewport.width < parseInt(theme.breakpoints.normalDesktop.match(/\d+/)[0], 10)) {
            themeMaxWidth = theme.maxWidthNormalDesktop;
        }
        sectionContext.sectionWidth = Math.min(themeMaxWidth, viewport.width);

    }

    if (sectionItems.length == template.length) {

        const converter = props.getConverter(viewport);
        const convertedData = converter(GRID_COLUMNS, template, sectionItems);
        const { converted, templateRows } = helper.getRowCount(convertedData);

        const items = converted.children.map((child, i) => {
            if (!child) {
                return null;
            }
            const conversionItemConfig = converted.config.item?.[i] || {};
            const alignment = getAlignment(verticalAlignment);
            return (
                <Grid.Item {...converted.placement[i]} viewport={viewport} theme={theme} align={alignment} key={i} {...conversionItemConfig} cols={convertedData.columns || GRID_COLUMNS} sectionWidth={props.width}>
                    { 
                        // Dont pass props to original html elements
                        React.cloneElement(child, child.type === 'div' || (child.type && child.type.displayName === 'EmotionCssPropInternal') ? {} : {
                            ltemIndex: i,
                            itemTotal: converted.children.length
                        })
                    }
                </Grid.Item>
            );
        });

        const { sectionCss, placerCss, backgroundImage } = layoutStyles(props);

        const conversionGridConfig = converted.config.grid || {};

        return (
            <SectionContext.Provider value={sectionContext}>
                <Section css={sectionCss} ref={sectionRef} data-width={width}>
                    {width === 'normal' ? null : backgroundImage}
                    <Placer css={placerCss}>
                        {width === 'normal' ? backgroundImage : null}
                        <Grid templateRows={templateRows} cols={convertedData.columns} gap={theme.gap} {...conversionGridConfig}>
                            {items}
                        </Grid>
                    </Placer>
                </Section>
            </SectionContext.Provider>
        );
    }

    console.warn('Section template array does not match Sections children array in length', sectionItems.length, template.length);
    return null;
};

SectionComponent.defaultProps = {
    getConverter
};

SectionComponent.propTypes = {
    getConverter: PropTypes.func,
    children: PropTypes.any,
    backgroundValue: PropTypes.any,
    backgroundType: PropTypes.string,
    verticalAlignment: PropTypes.string,
    width: PropTypes.string,
    visualEffect: PropTypes.string,
    template: PropTypes.arrayOf(
        PropTypes.shape({
            width: PropTypes.string,
            start: PropTypes.number,
            end: PropTypes.number,
        })
    ).isRequired,
    viewport: PropTypes.any,
    theme: PropTypes.any
};

export default withTheme(SectionComponent);
