import gql from 'graphql-tag';
import { useQuery } from '../../graphql-client/graphql-client';

// --------------------------------------------------------
// ---- Function to fetch missing highlighted posts -------
// --------------------------------------------------------

function fetchHighlightedPosts(highlightedPosts, actuallyHighlightedPosts) {

    const postsToFetch = highlightedPosts.filter(({ id }) => !actuallyHighlightedPosts.some(post => String(post.id) === String(id) || Number(post.id) === Number(id)));

    const podcastsToFetch = postsToFetch.filter(({ type }) => type === 'podcast');

    const regularPostsToFetch = postsToFetch.filter(({ type }) => type !== 'podcast');

    const posts = fetchPosts(regularPostsToFetch, podcastsToFetch);

    return posts;

}

function fetchPosts(regularPostsToFetch, podcastsToFetch) {

    const slugs = podcastsToFetch.map(({ id }) => id);
    const paths = regularPostsToFetch.map(({ permalink }) => permalink);

    const POST_QUERY = gql`
        query List($paths: [String!], $slugs: [String!], $includeEpisodes: Boolean!, $includeResources: Boolean!) {
            resources(siteId: "rawb", paths: $paths) @include(if: $includeResources) {
                _id
                permalink
                title
                type
                info
                resourceLastUpdated
            }
            selectEpisodes(slugs: $slugs) @include(if: $includeEpisodes) {
                _id
                permalink
                title
                type
                info
                resourceLastUpdated
            }
        }
    `;

    const posts = useQuery(POST_QUERY, {
        variables: {
            paths,
            slugs,
            includeEpisodes: slugs.length > 0,
            includeResources: paths.length > 0,
        },
    });

    return posts;

}

export default fetchHighlightedPosts;
