import React from 'react';
import ErrorPage from '../../components/error-page';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {

    constructor(props) {

        super(props);
        this.state= { hasError: false };

    }

    static getDerivedStateFromError() {

        return { hasError: true };

    }

    goBack = () => {

        window.history.back();

        // must use this timeout here, otherwise the same page will render again
        // and throw again, so the user gets trapped
        setTimeout(() => this.setState({ hasError: false }), 500);

    };

    render() {

        if (this.state.hasError) {

            return (
                <ErrorPage goBack={ this.goBack } />
            );
        } else {

            return this.props.children;

        }

    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.any,
};

export default ErrorBoundary;
