import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@emotion/react';
import { gql } from 'graphql-request';
import { redirectMatcher } from '@24hr/redirect-matcher';

import { useQuery } from '../../graphql-client/graphql-client';
import { useAppContext } from '../../app-context-provider/provider';
import DynamicModuleGenerator from '../../dynamic-module-generator';
import OldBlogPost from '../../components/old-blog-post/loadable';
import Effect from '../../components/effect';
import NotFound from '../../components/not-found/not-found';
import Loading from '../../components/loading';
import { useMenuBackground } from '../header-menu/menu-background-provider';
import { usePageTypeContext } from '../../page-type-context-provider/provider';
import { StyledForbidden } from './page.style';

const Page = props => {

    const path = props.location.pathname.replace(/\/$/, '');
    const context = useAppContext();
    const menuBackgroundContext = useMenuBackground();
    const pageTypeContext = usePageTypeContext();
    const theme = useTheme();

    const {
        meta: { viewport, info, settings },
        language
    } = context;

    useEffect(() => {
        if (process.env.NODE_ENV == 'production') {
            window.scrollTo(0, 0);
        }
    }, [path]);


    const {
        setCurrentPageType
    } = pageTypeContext;

    const siteName = info.siteName || '';

    let content;

    const { loading, error, data } = useQuery(
        gql`
        query Page($path: String!) {
            page: resource(path: $path) {
                _id
                blocks
                title
                content
                seo
                info
                effect
                customPageTitle
                redirect
                type
                author
                resourceLastUpdated
                error
                public
            }
        }
        `,
        { path } 
    );

    useEffect(() => {

        // let the menu background context know what the page background color is
        menuBackgroundContext.dispatch({ type: 'setPageBackground', payload: data?.page?.effect?.name });

        // set page type if applicable
        if (data && data.page && data.page.type) {
            setCurrentPageType(data.page.type);
        }

        if (data && data.page) {

            /*
             * I had to deactivate this in the frontend beacause it couldnt require the url module
             * Dont know why, but it wasnt working. / Camilo 2023-01-16
             */

            // Check if site should be redirected
            // const redirectData = redirectMatcher(settings.redirects, path);
            // if (redirectData) {
            //     window.location.replace(redirectData.url || '/');
            // }
        }

    }, [data]);

    if (error) {
        return <NotFound viewport={viewport}/>;
    }

    if (!viewport.initiated || error) return null;

    if (loading) {
        return (<Loading />);
    }

    const page = data?.page;


    if (!page) {
        return <NotFound viewport={viewport}/>;
    }

    if (page.blocks?.length) {
        if (page.type === 'post') {
            // Decorate a blog post with post info affter the heading, but only if that block doesnt exists
            if (page.blocks[0].blockName === 'core/heading' && !page.blocks.find(block => block.blockName === 'next24hr/post-info')) {
                page.blocks.splice(1, 0, {
                    blockName: 'next24hr/post-info',
                    author: page.author,
                    date: page.resourceLastUpdated,
                    compensateForHeading: true,
                });
            }
        }
        content = <DynamicModuleGenerator key='content' permalink={page.permalink} mobile={props.mobile} content={page.blocks} viewport={viewport} />;
    } else {
        content = <OldBlogPost viewport={viewport} content={page} />;
    }

    if (page.error === '401') {
        content = (<StyledForbidden>Super secret forbidden page</StyledForbidden>);
    }

    const effectCSS = Effect.getCSS(page.effect);

    const pageCSS = css`
        padding-top: 90px;
        margin-top: -170px;
        display: flex;
        flex: 1;
        min-height: 90vh;
        overflow-x: hidden;
        ${effectCSS}
        @media (${theme.breakpoints.mobile}) {
            padding-top: calc( 12vh + 4rem );
            margin-top: -12vh;
        }
    `;

    // look for custom page title, otherwise take post title
    const pageTitle = page?.seo?.page_title || page.customPageTitle || page.title;

    const url = settings.siteUrl + props.location.pathname.replace(/\/$/, '');

    return (
        <div css={pageCSS}>
            <Helmet>
                {pageTitle ? <title>{ `${ pageTitle} - ${ siteName }` }</title> : null}
                {page.seo && page.seo.use_canonical_url ? <link rel='canonical' href={page.seo.canonical_url} /> : <link rel='canonical' href={url} /> }
                {page.seo && page.seo.use_canonical_url ? <link rel='alternate' hreflang={language} href={page.seo.canonical_url} /> : null}
                {page.seo && page.seo.meta_description ? <meta name='description' content={page.seo.meta_description} /> : null}
                {page.seo && page.seo.meta_title ? <meta name='title' content={page.seo.meta_title} /> : null}
            </Helmet>
            {page.effect && <Effect effect={page.effect} />}
            {content}
        </div>
    );
};

Page.propTypes = {
    //         page: PropTypes.object,
    //         match: PropTypes.object,
    //         dispatch: PropTypes.func,
    //         viewport: PropTypes.object,
    location: PropTypes.object,
    //         history: PropTypes.object,
    //         user: PropTypes.object,
    mobile: PropTypes.string
    //         headerMenu: PropTypes.object,
    //         language: PropTypes.string,
    //         pages: PropTypes.object,
};

export default Page;
