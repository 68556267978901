import React from 'react';
import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useMenu } from '../menu-provider';
import { useQuery } from '../../../graphql-client/graphql-client';
import { withRouter } from 'react-router-dom';
import GlobalMenu from '../global-menu/global-menu';
import Logo from '../../../components/logo';
import Link from '../../../components/link';
import gql from 'graphql-tag';
import HamburgerIcon from '../hamburger-icon';
import { setDataLayer } from '../../../lib/gtm';
import { useEffect, useRef, useState } from 'react';
import { useMenuBackground } from '../menu-background-provider';
import { usePageTypeContext } from '../../../page-type-context-provider/provider';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: -5;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const HeaderContainer = styled.div`
    padding-left: ${p => p.theme.outerPadding};
    padding-right: ${p => p.theme.outerPadding};
    position: fixed;
    left: 0;
    right 0;
    transition: transform 0.8s, background-color 0.8s, padding-top 0.8s, padding-bottom 0.8s;
    z-index: 0;
`;

const Spacer = styled.div`
    height: 170px;
`;

const Nav = styled.nav`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LinkWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const getColorAnimation = (p) => {
    return keyframes`
        0% {
            color: ${p.theme.colors.darkerText};
        }
        50% {
            color: ${p.theme.colors.tomato};
        }
        100% {
            color: ${p.theme.colors.darkerText};
        }
    `;
};

const StyledLink = styled.a`
    color: ${p => p.theme.colors.darkerText};
    cursor: pointer;
    font-size: 1.25rem;
    font-family: 'Montserrat Bold';
    text-decoration: none;
    position: relative;
    @media screen and (${p => p.theme.breakpoints.tablet}) {
        font-size: 1rem;
    }
    transition: color .2s ease-out;
    overflow: hidden;
    padding-bottom: 1.5rem;
    margin-left: -1rem;
    padding-left: 1rem;
    margin-bottom: -1.5rem;
    height: 1em;
    display: block;
    box-sizing: content-box;
    &:hover {
        animation: ${p => getColorAnimation(p)} .6s ease-out alternate;
        & > div:nth-of-type(1) {
            transition: transform .4s ease-out 0s;
            transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
            transform: translate3d(0%, 0, 0);
        }
        & > div:nth-of-type(2) {
            transition: transform .4s ease-out .05s;
            transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
            transform: translate3d(0%, 0, 0);
        }
        & > div:nth-of-type(3) {
            transition: transform .4s ease-out .075s;
            transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
            transform: translate3d(0%, 0, 0);
        }
        & > div:nth-of-type(4) {
            transition: transform .4s ease-out .15s;
            transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
            transform: translate3d(0%, 0, 0);
        }
    }
    ${p => p.active ? `
        &:after {
            content: ' ';
            position: absolute;
            left: 1rem;
            right: 0;
            bottom: 11px;
            width: 100%;
            height: 4px;
            border-radius: 1px;
            background-color: ${p.theme.colors.tomato};
            z-index: 0;
        }` : '' }
`;

const colorBase = css`
    content: ' ';
    height: 10px;
    position: absolute;
    bottom: 0.4em;
    width: 100%;
    transform: translate3d(0, 2em, 0);
    z-index: 1;
 `;

const StyledColor1 = styled.div`
    ${colorBase}
    left: calc(0 + 1rem);
    background: ${p => p.theme.colors.sky};
    transform: translate3d(110%, 0, 0);
    transition: transform .1s ease-out;
 `;

const StyledColor2 = styled.div`
    ${colorBase}
    left: calc(2% + 1rem);
    width: 98%;
    background: ${p => p.theme.colors.pear};
    transform: translate3d(110%, 0, 0);
    transition: transform .2s ease-out;
 `;

const StyledColor3 = styled.div`
    ${colorBase}
    left: calc(5% + 1rem);
    width: 93%;
    background: ${p => p.theme.colors.lemon};
    transform: translate3d(110%, 0, 0);
    transition: transform .2s ease-out;
 `;

const StyledColor4 = styled.div`
    ${colorBase}
    left: calc(10% + 1rem);
    transform: translate3d(115%, 0, 0);
    background: ${p => p.theme.colors.tomato};
    transform: translate3d(110%, 0, 0);
    transition: transform .2s ease-out;
 `;

const StyledListItem = styled.li`
    display: inline;
    padding: 0 1.5rem;
    @media screen and (${p => p.theme.breakpoints.tablet}) {
        padding: 0 0.8rem;
    }
`;

const StyledHamburger = styled.div`
    right: 1rem;
    z-index: 99;
    padding-left: 1.5rem;
`;

const DesktopMenu = props => {

    const { menuState, toggleMenu } = useMenu();

    const { loading, error, data } = useQuery(gql`
        query {
            menu {
                header,
                extranet
            }
        }
    `);

    const menuBackgroundContext = useMenuBackground();
    const pageTypeContext = usePageTypeContext();
    const {
        currentPageType,
    } = pageTypeContext;

    const prevScroll = useRef(typeof window === 'object' ? window.pageYOffset : 0);

    const [ showMenu, setShowMenu ] = useState(true);

    const [ isMenuNearTop, setIsMenuNearTop ] = useState(true);

    useEffect(() => {

        function scrollHandler() {
            const currentScroll = window.pageYOffset;

            currentScroll < 100 ? setIsMenuNearTop(true) : setIsMenuNearTop (false);

            // compare previous page offset to determine if scrolling up or down
            const scrollDirection = prevScroll.current > currentScroll ? 'up' : 'down';

            // if scrolling up or near top of page, menu should be visible
            if (scrollDirection === 'up' || currentScroll < 80) {

                setShowMenu(true);

            // if scrolling down, menu should hide
            } else {

                setShowMenu(false);

            }

            // set current value as previous
            prevScroll.current = currentScroll;

        }

        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };

    }, []);

    if (loading || error) return null;
    const desktopMenuName = currentPageType === 'extranet' ? 'extranet' : 'header';

    const desktopMenu = data && data.menu && data.menu[desktopMenuName] || [];

    const menuItems = desktopMenu.map((menu, index) => {
        const activeLink = menu.url === props.history.location.pathname;
        return (
            <StyledListItem key={menu.ID}>
                <StyledLink active={activeLink}
                    index={index}
                    href={menu.url}
                    onClick={(e) => {
                        setDataLayer({ action: 'click', gtmCategory: 'desktop-menu', gtmLabel: menu.url });
                        e.preventDefault();
                        props.history.push(menu.url);
                    }}
                >
                    {menu.title}
                    <StyledColor1 />
                    <StyledColor2 />
                    <StyledColor3 />
                    <StyledColor4 />
                </StyledLink>
            </StyledListItem>
        );
    });

    // menu should shrink when user is not at top of page
    const verticalPadding = isMenuNearTop ? '3vw' : '1vw';

    const containerCss = css`
        ${ !showMenu ? 'transform: translate3d(0, -100%, 0);' : '' }
        background-color: ${ !showMenu || isMenuNearTop ? 'transparent' : menuBackgroundContext.value };
        padding-top: ${verticalPadding};
        padding-bottom: ${verticalPadding};
        width: 100%;
    `;

    return (
        <div>
            <HeaderContainer css={ containerCss }>
                <Nav>
                    <Link to='/' label="Till startsidan">
                        <Logo />
                    </Link>
                    <LinkWrapper>
                        <ul
                            css={css`
                                display: flex;
                                list-style-type: none;
                            `}
                        >
                            {menuItems}
                        </ul>
                        <StyledHamburger onClick={() => toggleMenu()}>
                            <HamburgerIcon active={menuState === 'open' ? true : false} />
                        </StyledHamburger>
                    </LinkWrapper>
                </Nav>
                <GlobalMenu />
            </HeaderContainer>
            <Spacer />
            {menuState === 'open' ? <Overlay onClick={() => toggleMenu()} /> : null}
        </div>
    );
};

DesktopMenu.propTypes = {
    history: PropTypes.object
};

export default withRouter(DesktopMenu);
