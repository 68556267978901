import styled from '@emotion/styled';

const StyledForbidden = styled.div`
    margin: 4rem;
    padding: 4rem;
    width: 100%;
    text-align: center;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${ p => p.theme.colors.berry};
    color: white;
    @media ${p => p.theme.breakpoints.mobile} {
        margin: 4rem 2rem;
    }
`;

export {
    StyledForbidden,
};
