import { convertToSpan } from '../util';

/**
 * The identity converter, i.e it return same layout converted from width based to col/row based.
 *
 * @param {Number} gridCols Number of grid columns, most likely 12.
 * @param {Array} template Array of objects with 'width' information, for example {width: '1/3'}
 * @param {Array} children Array of columns.
 * @returns placement, children and empty grid config.
 */
const identity = (gridCols, template, children) => {
    let colStart = 1;
    let colEnd = 1;

    const placement = template.map(t => {
        colEnd += convertToSpan(gridCols, t.width);
        const converted = {
            col: {
                s: t.start || colStart,
                e: t.end || colEnd
            },
            row: {
                s: 1,
                e: 2
            }
        };
        colStart = colEnd;
        return converted;
    });

    return {
        placement,
        children,
        config: {
            grid: {
                gap: {
                    col: '4rem',
                    row: '4rem',
                }
            }
        },
        columns: gridCols,
    };
};

export default identity;
