import React from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const DateContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: ${p => p.theme.contentMaxWidth};
    width: 100%;
    color: ${p => p.theme.colors.darkerGray};
`;

const DateDisplay = ({ date, marginBottom = '1rem' }) => {

    const formattedDate = dayjs(date).format('YYYY-MM-DD');

    const ContainerWithProps = styled(DateContainer)`
        margin-bottom: ${marginBottom};
    `;

    return (
        <ContainerWithProps>{ formattedDate }</ContainerWithProps>
    );
};

DateDisplay.propTypes = {
    date: PropTypes.string.isRequired,
    marginBottom: PropTypes.string,
};

export default DateDisplay;