import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';

const logos = {
    color: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 586.8 200" xml:space="preserve"> <path d="M174,60l-64,77h64V60z"></path> <path d="M44,168l54.6-66.4c12.8-15.7,18.9-26,18.9-43.6c0-34.5-24.1-58-59.5-58H0v31h58c15.3,0,25,11,25,27c0,8.7-2.5,14.5-9.4,23L0,170v30h174v-32H44z"></path> <path d="M485,119c24.4-8.5,40-29.8,40-57.5C525,25.3,498.1,0,460,0h-84v200h34v-77h39l44,77h39L485,119z M410,31h48c20,0,33,11.2,33,30c0,18.7-13,31-33,31h-48V31z"></path> <path d="M325,0v92h-83V0h-34v137h17v31h-17v32h34v-77h83v77h34V0H325z"></path> <g> <path d="M543,5v26h-6V5h-9V0h24v5H543z M580,31V11l-6,13h-6l-6-13v20h-6V0h7l8,17l8-17h7v31H580z"></path> </g> </svg>',
    white: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 586.8 200" xml:space="preserve"> <path fill="#ffffff" d="M174,60l-64,77h64V60z"></path> <path fill="#ffffff" d="M44,168l54.6-66.4c12.8-15.7,18.9-26,18.9-43.6c0-34.5-24.1-58-59.5-58H0v31h58c15.3,0,25,11,25,27c0,8.7-2.5,14.5-9.4,23L0,170v30h174v-32H44z"></path> <path fill="#ffffff" d="M485,119c24.4-8.5,40-29.8,40-57.5C525,25.3,498.1,0,460,0h-84v200h34v-77h39l44,77h39L485,119z M410,31h48c20,0,33,11.2,33,30c0,18.7-13,31-33,31h-48V31z"></path> <path fill="#ffffff" d="M325,0v92h-83V0h-34v137h17v31h-17v32h34v-77h83v77h34V0H325z"></path> <g> <path fill="#ffffff" d="M543,5v26h-6V5h-9V0h24v5H543z M580,31V11l-6,13h-6l-6-13v20h-6V0h7l8,17l8-17h7v31H580z"></path> </g> </svg>',
};

const sizes = {
    'default': css`width: 105px; height: 36px;`,
    'small': css`width: 85px; height: 36px;`,
};

const Logo = ({size, variant}) => {

    const logo = variant == 'white' ? logos.white : logos.color;

    let cssToUse = sizes[size] ? sizes[size] : sizes.default;

    return (
        <div dangerouslySetInnerHTML={{__html: logo}} css={cssToUse} />
    );
};

Logo.propTypes = {
    variant: PropTypes.string,
    width: PropTypes.string,
    size: PropTypes.string,
};

export default Logo;
