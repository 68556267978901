import React, { useEffect, useRef } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';
import { rgba } from 'emotion-rgba';
import { useHistory } from 'react-router-dom';

const typographies = (theme, inverted) => ({
    small: css`
        font-size: 0.94rem;
        line-height: 1.47em;
        color: ${inverted ? theme.invertedColors.lighterText : theme.colors.lighterText};
        strong {
            font-weight: 600;
        }
        em {
            font-style: italic;
        }
    `,
    normal: css`
        font-size: 1.25rem;
        line-height: 1.7em;
        color: ${inverted ? theme.invertedColors.lighterText : theme.colors.darkerText};
        strong {
            font-weight: 600;
        }
        em {
            font-style: italic;
        }
    `,
    large: css`
        font-size: 1.75rem;
        line-height: 1.57em;
        color: ${inverted ? theme.invertedColors.black : theme.colors.black};
        font-style: normal;
        strong {
            font-weight: 600;
        }
        em {
            font-style: italic;
        }
    `,
    override: css`
        color: ${inverted ? theme.invertedColors.black : theme.colors.black};
    `,
});

const Text = ({ as, size, theme, children, useSetInnerHtml, inverted, color, italic, ...rest }) => {

    const textStyle = typographies(theme, inverted)[size];
    const contentRef = useRef(null);
    const history = useHistory();

    useEffect(() => {

        const allATags = contentRef.current.querySelectorAll('a');

        allATags.forEach(a => {
            const url = a.href?.replace(`${window.location.protocol}//${window.location.hostname}`, '').replace(/\/$/, '');
            if (!url.match(/^http/)) {
                a.addEventListener('click', (e) => {
                    e.preventDefault();
                    history.push(url);
                });
            }
        });

    }, []);

    // hardcode orange color underscore for now
    const Component = styled[as]`
        ${textStyle}
        ${ italic ? 'font-style: italic;' : '' }
        a {
            color: inherit;
            text-decoration: none;
            border-bottom: 2px solid ${rgba(color, .25)};
            font-weight: 400;
            padding-bottom: 0.15em;
            transition: all .1s ease-in;
            &:hover {
                padding-bottom: 0.5em;
                border-bottom-color: ${rgba(color, 1)};
            }
        }
    `;

    // from paragraphs we sometimes get raw html tags, that require us to set innerhtml
    if (useSetInnerHtml) {
        return (
            <Component {...rest} ref={contentRef} dangerouslySetInnerHTML={ { __html: children } } />
        );
    } else {
        // some other types of content requires us to render the children this way
        return <Component {...rest} ref={contentRef}>{children}</Component>;
    }

};

Text.defaultProps = {
    as: 'div',
    size: 'normal',
    inverted: false,
    useSetInnerHtml: true,
    color: 'black'
};

Text.propTypes = {
    as: PropTypes.string,
    size: PropTypes.oneOf(['small', 'normal', 'large', 'override']),
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            lighterText: PropTypes.string.isRequired,
            darkerText: PropTypes.string.isRequired,
            black: PropTypes.string.isRequired,
            orange: PropTypes.string.isRequired
        })
    }).isRequired,
    children: PropTypes.any,
    useSetInnerHtml: PropTypes.bool,
    inverted: PropTypes.bool,
    italic: PropTypes.bool,
    color: PropTypes.string,
};

export default withTheme(Text);
