import React from 'react';
import { css } from '@emotion/core';
import theme from '../../../emotion/theme';

const effectCss = css`
    height: 100%;
    width: 100vw;
    overflow: hidden;
    background-size: 56px 56px;
	background-position: 0% 0%;
    background: repeating-linear-gradient(
        45deg, 
        ${theme.colors.skyLight}     0px,
        ${theme.colors.skyLight}     20px,
        ${theme.colors.lemonLight}   0, 
        ${theme.colors.lemonLight}   40px,
        ${theme.colors.pearLight}    0, 
        ${theme.colors.pearLight}    60px,
        ${theme.colors.orangeLight}  0, 
        ${theme.colors.orangeLight}  80px,
        ${theme.colors.tomatoLight}  0, 
        ${theme.colors.tomatoLight}  100px,
        ${theme.colors.berryLight}   0,
        ${theme.colors.berryLight}   120px
    );
`;


const EffectStripes = () => {
    return (
        <div css={effectCss} />
    );
};

EffectStripes.backgroundColor = '#fd648dee';
EffectStripes.css = '#fd648dee';

export default EffectStripes;


