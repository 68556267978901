import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { useTheme } from '@emotion/react';

import Section from '../section/section';
import Heading from '../heading/heading';
import Image from '../image';
import convertWysiwyg from '../wysiwyg/wysiwyg';
import DateDisplay from '../date-display';

const OldBlogPost = ({ viewport, content }) => {
    const theme = useTheme();

    const children = convertWysiwyg(content.content);

    const image = content?.info?.image;
    if (image) {
        image.src = image.url;
    }
    const imageCss = css`
        margin-left: auto;
        margin-right: auto;
        max-width: ${theme.contentMaxWidth};
        padding-bottom: ${theme.spacing.large};
     `;

    const title = content.title?.length > 20 ? content.title.replace(/\+/g, ' + ') : content.title;

    // if a user browses directly to the permalinks for news, this component will be used to display them
    // in those cases we need a fallback value for date
    const date = Array.isArray(content.blocks) ? content.blocks[0]?.date : content.resourceLastUpdated;

    return (
        <Section template={[{ width: '1/1' }]} viewport={viewport}>
            <>
                <Heading alignSelf='center' belowDateDisplay>{title}</Heading>
                <DateDisplay date={ date } />
                {image && (
                    <div css={imageCss}>
                        <Image {...image} width={null} height={null} ratio={image.width / image.height} />
                    </div>
                )}
                {children}
            </>
        </Section>
    );
};

OldBlogPost.propTypes = {
    children: PropTypes.array,
    viewport: PropTypes.object,
    content: PropTypes.object
};

export default OldBlogPost;
