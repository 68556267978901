import React, { useRef, useEffect } from 'react';
import marked from 'marked';
import { parse } from 'node-html-parser';
import styled from '@emotion/styled';

import Paragraph from '../paragraph/paragraph';
import Link from '../link';
import Text from '../text/text';
import List from '../list/list';
import Heading from '../heading/heading';
import Iframe from '../iframe';
import Video from '../video/video';

// this is how the other components do it 'under the hood', so I thought it was best to do the same for lists
const UlContainer = styled.div`
    max-width: 950px;
    align-self: center;
    width: 100%;
`;

const convertMap = {
    // Ignore  scripts for now
    script: {
        props: item => ({ attributes: item.rawAttributes }),
        Component: (props) => {
            const scriptRef = useRef(null);
            useEffect(() => {
                const script = scriptRef.current;
                script.async = true;
                Object.entries(props.attributes).forEach(item => { // eslint-disable-line
                    script[item[0]] = item[1] || '';
                });
            }, [props.attributes]); // eslint-disable-line
            return <script ref={scriptRef} />;
        }
    },
    em: {
        Component: ({ children }) => <em>{children}</em> // eslint-disable-line
    },
    strong: {
        Component: ({ children }) => <strong>{children}</strong> // eslint-disable-line
    },
    iframe: {
        analyze: item => {
            if (item.rawAttributes.src.match(/youtube/)) {
                return {
                    Component: Video,
                    props: { url: item.rawAttributes.src }
                };
            }
        },
        Component: ({ children }) => <Iframe>{children}</Iframe>, // eslint-disable-line
        props: item => ({ ...item.rawAttributes })
    },
    li: {
        Component: ({ children }) => <>{children}</> // eslint-disable-line
    },
    ul: {
        Component: (
            { children, ...rest } // eslint-disable-line
        ) => (
            <UlContainer>
                <List {...rest}>{children}</List>
            </UlContainer>
        )
    },
    h1: {
        Component: Heading,
        props: () => ({ as: 'h1' })
    },
    h2: {
        Component: Heading,
        props: () => ({ as: 'h2' })
    },
    h3: {
        Component: Heading,
        props: () => ({ as: 'h3' })
    },
    h4: {
        Component: Heading,
        props: () => ({ as: 'h4' })
    },
    p: {
        Component: Paragraph
    },
    a: {
        Component: Link,
        props: item => ({ to: item.rawAttributes.href })
    }
};

const parseList = (list = [], depth = 0) => {
    const children = list.reduce((all, item, index) => {
        let key = `key-${depth}-${index}`;
        if (item.childNodes.length > 0 || item.tagName) {
            let Component = convertMap[item.tagName]?.Component;
            if (!Component) {
                Component = Paragraph;
                console.log('No parser for "%s"', item.tagName); // eslint-disable-line
            } else {
                const converter = convertMap[item.tagName];
                if (converter.analyze) {
                    let result = converter.analyze(item);
                    if (!result) {
                        result = converter;
                    }
                    all.push(<result.Component {...result.props} key={key} alignSelf='center'></result.Component>);
                } else {
                    const subContent = parseList(item.childNodes, depth + 1);
                    const props = converter?.props?.(item) || {};

                    // may contain react elements, so we can't set innerhtml
                    props.useSetInnerHtml = false;
                    props.alignSelf = 'center';

                    all.push(
                        <Component {...props} key={key}>
                            {subContent}
                        </Component>
                    );
                }
            }
        } else {
            // Our special case of markdown to make the preamble
            if (item.text.match(/^!/)) {
                all.push(
                    <Text as='span' size='large' alignSelf='center' key={key}>
                        {item.text.replace(/^!/, '')}
                    </Text>
                );
            } else if (!item.text.match(/^(\r|\n)$/)) {
                all.push(item.text);
            }
        }
        return all;
    }, []);
    return children;
};

const convertWysiwyg = html => {
    html = html?.replace(/##(\w)/g, '## $1');
    html = html?.replace(/&lt;script/g,  '<script').replace(/&lt;\/script/g, '</script');
    const root = parse(marked(html || ''));
    const children = parseList(root.childNodes);
    return children;
};

export default convertWysiwyg;
