import styled from '@emotion/styled';

const StyledContainer = styled.div`
    max-width: ${p => p.theme.contentMaxWidth};
    margin-left: auto;
    margin-right: auto;
    font-size: 18rem;
    font-weight: bold;
    padding-bottom: 2rem;
 `;

export {
    StyledContainer,
};

