import React, { useRef, useEffect } from 'react';
import { useMenu } from '../menu-provider';
import styled from '@emotion/styled';
import HamburgerIcon from '../hamburger-icon';
import GlobalMenu from '../global-menu/global-menu';
import Logo from '../../../components/logo';
import Link from '../../../components/link';

const StyledNav = styled.nav`
    display: flex;
    justify-content: space-between;
`;

const StyledLogoLink = styled.div`
    position: fixed;
    padding-left: 1.7rem;
    padding-top: 1.7rem; 
    width: 
`;

const StyledLogoLinkContainer = styled.div`
    top: 0;
    transition: translate3d .5s ease-out;
    transform: translate3d(0, calc( var(--logo-position) * 400px ), 0);
`;

const StyledHamburger = styled.div`
    position: fixed;
    z-index: 99;
    top: 0.9rem;
    right: 0.9rem;
`;

const MobileMenu = () => {

    const { menuState, toggleMenu } = useMenu();

    const logoRef = useRef(null);

    useEffect(() => {
        const onScroll = () => {
            if (logoRef?.current) {
                const rect = logoRef.current.getBoundingClientRect();
                const distTop = rect.y + rect.height / 2;
                // slideAmount is between [-0.5,..., 0.5]
                const slideAmount = distTop / window.innerHeight;
                const slideLimited = Math.max(-1, Math.min(1, slideAmount));
                logoRef.current.style.setProperty('--logo-position', slideLimited);
            }
        };
        window.addEventListener('scroll', onScroll);
        onScroll();
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);


    return (
        <StyledNav ref={logoRef}>
            <StyledLogoLink>
                <StyledLogoLinkContainer>
                    <Link to='/' label="Till startsidan">
                        <Logo size="small"/>
                    </Link>
                </StyledLogoLinkContainer>
            </StyledLogoLink>
            <StyledHamburger onClick={toggleMenu}>
                <HamburgerIcon active={menuState === 'open' ? true : false} />
            </StyledHamburger>
            <GlobalMenu />
        </StyledNav>
    );
};

export default MobileMenu;
