import { withTheme } from '@emotion/react';
import { css } from '@emotion/core';
import { Footer, FooterContainerBottom, FooterVersion } from './footer.style';
import identityConverter from '../section/layout-converters/converters/identity-converter';

import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import TextBlock from '../text-block';
import FooterTop from './footer-top';
import Link from '../link';
import Logo from '../logo';
import Section from '../section/section';

let columnHeading = css`
    font-family: Montserrat Bold;
    font-size: 1.125rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    align-items: center;
    line-height: 2em;
    margin-bottom: 1.4em;
`;

let locationStyle = css`
    display: flex;
    flex-direction: column;
    line-height: 1.8em; 
    font-size: 1.25rem;
    margin-top: 2rem;
    a:hover {
        text-decoration: underline;
    }
`;

let contactLinkContainer = css`
    margin-bottom: .5rem;
`;

let columnListStyle = css`
    list-style-type: none;
    line-height: 2.5em;
    font-size: 1.3125rem;
    a:hover {
        text-decoration: underline;
    }
`;


const getPageLinks = (columnPageLinks) => {

    if (!columnPageLinks?.links) {
        return null;
    }

    const columnLink = columnPageLinks.links.map((link, index) => {
        return (
            <li css={columnListStyle} key={`column-page-link-${index}`}>
                <Link to={link.link_url}>{link.link_title}</Link>
            </li>
        );
    });

    return (
        <div>
            <h6 css={columnHeading}>{columnPageLinks.heading}</h6>
            <ul>
                {columnLink}
            </ul>
        </div>
    );

};

const getSocialMedia = (socialMediaLinks) => {
    if (!socialMediaLinks?.social_media_link) {
        return null;
    }

    const socialMediaLink = socialMediaLinks.social_media_link.map((link, index) => {
        return (
            <li css={columnListStyle} key={`social-media-${index}`}>
                <Link to={link.social_media_url}>{link.social_media}</Link>
            </li>
        );
    });

    return (
        <div>
            <h6 css={columnHeading}>{socialMediaLinks.social_media_heading}</h6>
            <ul>
                {socialMediaLink}
            </ul>
        </div>
    );
};

const getContact = (officeInformation, theme) => {

    const contactBlock = css`
        @media screen and (${theme.breakpoints.mobile}) {
            text-align: center;
        }
    `;

    return (
        <div css={contactBlock}>
            <div>
                <Link to="/" label="Till startsidan"><Logo variant={'white'} /></Link>
            </div>
            <div css={locationStyle}>
                <Link to={officeInformation?.address_information?.map_location}>
                    <TextBlock>{officeInformation?.address_information?.address}</TextBlock>
                </Link>
                <Link to={officeInformation?.address_information?.map_location}>
                    <TextBlock>{officeInformation?.address_information?.zipcode_city}</TextBlock>
                </Link>
            </div>
            <div css={locationStyle}>
                <div css={contactLinkContainer}><Link to={`Tel: ${officeInformation?.phone}`} target={'_blank'}>{officeInformation?.phone}</Link></div>
                <div css={contactLinkContainer}><Link to={`mailto: ${officeInformation?.contact_email}`} target={'_blank'}>{officeInformation?.contact_email}</Link></div>
            </div>
        </div>
    );
};

const footerMobile = (gridCols, template, children) => {
    return {
        placement: [
            {
                col: { s: 1, e: 1 + gridCols },
                row: { s: 2, e: 3 }
            },
            {
                col: { s: 1, e: 1 + gridCols },
                row: { s: 1, e: 2 }
            }
        ],
        children: [children[0]],
        config: {}
    };
};

const getConverter = (viewport) => {
    const converter = viewport.width <= 768 ? footerMobile : identityConverter;
    return converter;
};

const FooterComponent = ({ version, footer, viewport, theme }) => {

    if (!footer) {
        return <></>;
    }

    const ref = useRef();

    let { footerTopBlock, officeInformation, columnPageLinks, socialMediaLinks } = footer;

    return (
        <Footer>
            <FooterTop footerRef={ref} footerTopBlock={footerTopBlock} viewport={viewport} />
            <FooterContainerBottom ref={ref}>
                <Section template={[{ width: '1/3' }, { width: '1/3' }, { width: '1/3' }]} viewport={viewport} verticalPadding="none" getConverter={getConverter}>
                    {getContact(officeInformation, theme)}
                    {getPageLinks(columnPageLinks)}
                    {getSocialMedia(socialMediaLinks)}
                </Section>
            </FooterContainerBottom>
            <FooterVersion>{version}</FooterVersion>
        </Footer>
    );
};

FooterComponent.propTypes = {
    footer: PropTypes.shape({
        officeInformation: PropTypes.object,
        columnPageLinks: PropTypes.object,
        footerTopBlock: PropTypes.object,
        socialMediaLinks: PropTypes.object,
    }),
    version: PropTypes.string,
    viewport: PropTypes.object,
    theme: PropTypes.any
};

export default withTheme(FooterComponent);
