import styled from '@emotion/styled';

/*
 * FOOTER CONTAINER TOP
 */

export const FooteContainerTop = styled.div`
    background-color: ${props => props.theme.colors[props.backgroundValue]};
    color: ${props => props.theme.colors.almostBlack};
    padding: 3rem 0 0 0;
`;

export const LeftContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 0 0 3rem 0;
`;

export const HeadingTopBlock = styled.div`
    font-size: 3.5rem;
    font-family: ${props => props.theme.fonts.header1};
    padding-bottom: 33px;
    line-height: 1.4em;
    @media screen and (${props => props.theme.breakpoints.mobile}) {
        font-size: 1rem;
    }
    @media screen and (${props => props.theme.breakpoints.tablet}) {
        font-size: 2.375rem;
    }
`;

export const SubHeadingTopBlock = styled.div`
    font-size: 1.75rem;
    font-family: ${props => props.theme.fonts.header2};
    line-height: 1.6;
    a {
        color: ${props => props.theme.colors.almostBlack};
    }
    @media screen and (${props => props.theme.breakpoints.mobile}) {
        font-size: 1.375rem;
    }
`;

/*
 * FOOTER
 */

export const Footer = styled.footer`
    position: relative;
    z-index: 1;
    color: white;
    a {
        color: white;
        text-decoration: none;
    }
`;

export const FooterVersion = styled.div`
    color: ${props => props.theme.colors.darkGray};
    background-color: ${props => props.theme.colors.almostBlack};
    line-height: 1.4;
    font-size: 0.8rem;
    text-align: end;
    padding: 0 0.4rem 0.4rem 0
`;

export const FooterContainerBottom = styled.div`
    background-color: ${props => props.theme.colors.almostBlack};
    position: relative;
    padding: 6rem 0 4rem 0;
    @media screen and (${props => props.theme.breakpoints.mobile}) {
        padding: 2rem 0;
    }
`;
