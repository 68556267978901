import React from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useAppContext } from '../../app-context-provider/provider';

export const gridHookValue = '12345';

/**
 * Calculate the pixel width of the content.
 */
const calcPixelWidth = ({ theme, viewport, spanCol, cols, colGap, sectionWidth }) => {

    let themeMaxWidth = theme.maxWidth;
    if (viewport.width < parseInt(theme.breakpoints.normalDesktop.match(/\d+/)[0], 10)) {
        themeMaxWidth = theme.maxWidthNormalDesktop;
    }

    let maxWidth = Math.min(themeMaxWidth, viewport.width);
    let paddingProp = 'outerPadding';
    if (viewport.width < parseInt(theme.breakpoints.mobile.match(/\d+/)[0], 10)) {
        paddingProp = 'outerPaddingMobile';
    }
    const outerPadding = parseFloat(theme[paddingProp].replace('vw'), 10) / 100;
    const dynamicPadding = outerPadding * viewport.width * 2;
    if (sectionWidth === 'full') {
        maxWidth = viewport.width - outerPadding * 2;
        //} else {
        // const dynamicPadding = (parseFloat(theme.outerPadding.replace('vw'), 10) / 100) * viewport.width * 2;
        // maxWidth = maxWidth; //  - dynamicPadding;
    }
    let sectionWidthAfterPadding = maxWidth; // 0;


    if (sectionWidth !== 'full' && themeMaxWidth >= viewport.width) {
        // console.log('----------------------here');
        sectionWidthAfterPadding -= dynamicPadding;
    } else if (sectionWidth !== 'full' && themeMaxWidth < viewport.width) {
        // console.log('----------------------here------------', dynamicPadding);
        //sectionWidthAfterPadding -= dynamicPadding;
    }


    // console.log('sss', sectionWidth, sectionWidthAfterPadding);


    //     if (viewport.width - dynamicPadding > theme.maxWidth) {
    //         sectionWidthAfterPadding = theme.maxWidth;
    //     } else if (viewport.width > theme.maxWidth) {
    //         sectionWidthAfterPadding = viewport.width - dynamicPadding;
    //     } else {
    //         sectionWidthAfterPadding = underWidthMinusPadding;
    //     }
    const gridItemWidth = (spanCol / cols) * (sectionWidthAfterPadding - (cols / spanCol - 1) * colGap);
    //     console.log('-------');
    //     console.log('gridItemWidth', gridItemWidth);
    //     console.log('spanCol', spanCol);
    //     console.log('cols', cols);
    //     console.log('maxWidth', maxWidth);
    //     console.log('dynamicPadding', dynamicPadding);
    //     console.log('sectionWidthAfterPadding', sectionWidthAfterPadding);

    return gridItemWidth;

};

/**
 * Calculate the gap in pixels. Handles if gap is 'rem' based.
 */
const calcGap = ({ theme }) => {
    let colGap = theme.gap.col.includes('rem') ? parseInt(theme.gap.col.replace(/rem/, ''), 10) * theme.baseSize : theme.gap.col;
    return colGap;
};

const Item = ({ children, col, row, justify, align, columnCount, columnIndex, cols, sectionWidth }) => {

    const theme = useTheme();
    const {
        meta: { viewport }
    } = useAppContext();

    let colGap = calcGap({ theme });

    // This piece of code is IE11 specific to calculate the grid gap since it's not supported in IE11. 'gap' is optional.
    const spanCol = col.e - col.s;
    const spanRow = row.e - row.s;
    const gapLeftRight = colGap;
    let marginLeft = 0;
    let marginRight = 0;
    if (columnCount > 0) {
        if (columnIndex < columnCount - 1) {
            marginRight = gapLeftRight / 2;
        }
        if (columnIndex > 0) {
            marginLeft = gapLeftRight / 2;
        }
    }
    // ---- end of IE11 gap specific code

    // Give all children the exact pixel width.
    const gridItemWidth = calcPixelWidth({ theme, viewport, spanCol, cols, colGap, sectionWidth });
    const elements = React.Children.toArray(children);
    const clonedChildren = elements.map((child, index) => {
        if (typeof child.type == 'object' || child.type === 'div' || child.type?.displayName === 'EmotionCssPropInternal') {
            return child;
        } else {
            return React.cloneElement(child, {
                gridItemWidth,
                columnIndex: index,
                columnCount: elements.length,
            });
        }
    });
    // ---- end of exact pixelwidth stuff.

    return (
        <div
            css={css`
                /* ---- Default start ---- */
                /* IE11 */
                -ms-grid-column: ${col.s};
                -ms-grid-column-span: ${spanCol};
                -ms-grid-row: ${row.s};
                -ms-grid-row-span: ${spanRow};
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    margin-right: ${marginRight}px;
                    margin-left: ${marginLeft}px;
                }

                /* Chrome */
                grid-column: ${col.s} / ${col.e};
                grid-row: ${row.s} / ${row.e};
                justify-self: ${justify};
                align-self: ${align};
                display: flex;
                flex-direction: column;
                height: 100%;
                /* ---- Default end ---- */
            `}
        >
            {clonedChildren}
        </div>
    );
};

Item.defaultProps = {
    // This prop is checked by the Grid component to
    // warn if the Grid contains any children that
    // are NOT Grid.Items.
    gridHookId: gridHookValue,
    justify: 'stretch',
    align: 'stretch'
};

Item.propTypes = {
    children: PropTypes.any,
    row: PropTypes.shape({
        s: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        e: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    col: PropTypes.shape({
        s: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        e: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    justify: PropTypes.oneOf(['start', 'end', 'center', 'stretch']),
    align: PropTypes.oneOf(['start', 'end', 'center', 'stretch']),
    columnCount: PropTypes.number,
    columnIndex: PropTypes.number,
    cols: PropTypes.number,
    sectionWidth: PropTypes.string,
};

export default Item;
