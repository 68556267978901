import React, { Suspense } from 'react';
//import ProcessImage from './process-image';
import { SvgContainer } from './process-image-style';

const ProcessMap = (props) => {

    if (typeof window === 'undefined') {
        return <div/>;
    }

    const ProcessImage = React.lazy(() => import('./process-image'));

    return (
        <SvgContainer >
            <Suspense fallback={<div>Loading...</div>}>
                <ProcessImage props={props}/>
            </Suspense>
        </SvgContainer>
    );

};

export default ProcessMap;
