import React from 'react'; //eslint-disable-line
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isExternalLink } from '../../lib/data-helper';
import { setDataLayer } from '../../lib/gtm';

const LinkWrapper = (props) => {

    function handleClick (e) {
        const { gtmLabel, gtmCategory, onClick } = props;
        if (gtmLabel && gtmCategory) {
            setDataLayer({action: 'click', gtmCategory, gtmLabel});
        }
        if (onClick) {
            // If a link has no anchoroint, scroll to top of page
            // const anchorPoint = props.to.split('#')[1];
            // if (!anchorPoint) {
            //     window.scrollTo(0,0);
            // }
            onClick(e);
        }
    }

    const extraProps = {};
    if (props.title) {
        extraProps.title = props.title;
    }
    if (props.label) {
        extraProps['aria-label'] = props.label;
    }


    const { to, children, target } = props;

    if (to && isExternalLink(to) || target === '_blank') {
        let rel = '';
        if (target === '_blank') {
            rel = 'noopener';
        }
        return (
            <a href={to} target={target} onClick={handleClick} rel={rel} {...extraProps}>{ children }</a>
        );
    }

    return (
        <Link data-id={props.dataId} to={to || '#'} onClick={handleClick} {...extraProps}>{children || ' '}</Link>
    );

};

LinkWrapper.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    title: PropTypes.string,
    label: PropTypes.string,
    gtmLabel: PropTypes.string,
    gtmCategory: PropTypes.string,
    dataId: PropTypes.string,
    target: PropTypes.oneOf([ '_blank', '_self', ]),
    onClick: PropTypes.func,
};


export default LinkWrapper;
