import React from 'react';
import PropTypes from 'prop-types';

const Block = (props) => {
    return (
        <div className="block">{props.children}</div>
    );
};

Block.propTypes = {
    children: PropTypes.any,
};

export default Block;




