import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { setDataLayer } from '../../lib/gtm';
import { isExternalLink } from '../../lib/data-helper';
import styled from '@emotion/styled';
import Text from '../text/text';

// ----------------------------------------------------------------------------

const ButtonBase = styled.button`
    background-color: ${p => p.theme.colors.almostBlack};
    border-radius: 2rem;
    cursor: pointer;
    :hover {
        background-color: ${p => p.theme.colors.black};
    }
    :focus {
        outline: 0;
    }
`;

const buttonVariants = {
    medium: styled(ButtonBase)`
        padding: 0.5rem 1rem;
        min-width: 10rem;
        margin-bottom: 2rem;
    `
};

// ----------------------------------------------------------------------------

const Button = ({ onClick, text, size }) => {
    const Button = buttonVariants[size];

    return (
        <Button onClick={onClick}>
            <Text as='span' inverted>
                {text}
            </Text>
        </Button>
    );
};
Button.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
    size: PropTypes.oneOf(['medium']).isRequired
};

// ----------------------------------------------------------------------------

const ButtonWrapper = ({ onClick, url, openNewTab, ...rest }) => {
    const handleClick = e => {
        setDataLayer({ action: 'click', gtmCategory: 'button', gtmLabel: 'load-more' });
        onClick(e);
    };

    if (url) {
        const target = openNewTab ? '_blank' : '_self';
        if (target === '_blank' || isExternalLink(url)) {
            return (
                <a href={url} target={target} onClick={handleClick}>
                    <Button {...rest} />
                </a>
            );
        } else {
            return (
                <Link to={url} target={target} onClick={handleClick}>
                    <Button {...rest} />
                </Link>
            );
        }
    } else {
        return <Button {...rest} onClick={handleClick} />;
    }
};

ButtonWrapper.defaultProps = {
    onClick: () => {},
    size: 'medium',
    text: 'Button'
};

ButtonWrapper.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['medium']).isRequired,
    url: PropTypes.string
};

export default ButtonWrapper;
