/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '../graphql-client/graphql-client';
import { gql } from 'graphql-request';

import getFeatureSupport from '../lib/feature-support';
import createViewPort from './viewport-handler';

import socket from '../lib/socket';

export const AppContext = React.createContext({});

const AppContextProvider = props => {

    let data = props.mockData;

    const { loading, error, data: queryData } = useQuery(gql`
        query {
            siteInfo {
                _id
                siteName
                cookie {
                    title
                    text
                    buttonText
                }
                socialMediaUrls {
                    instagram
                    facebook
                    twitter
                }
            }
            gtm {
                _id
                gtm
            }
        }
    `, {}, { skip: !!data } );

    let hasLoaded = !loading && !error;

    if (!data) {
        data = queryData;
    }

    const { appState } = props;

    if (hasLoaded) {

        appState.meta.info.gtmCode = data?.gtm?.gtm;
        appState.meta.info.siteName = data?.siteInfo?.siteName;
        appState.meta.info.cookieMessage = {
            title: data?.siteInfo?.cookie?.title,
            text: data?.siteInfo?.cookie?.text,
            buttonText: data?.siteInfo?.cookie?.buttonText
        };
        appState.meta.info.socialMediaUrls = {
            instagram: data?.siteInfo?.socialMediaUrls?.instagram,
            facebook: data?.siteInfo?.socialMediaUrls?.facebook,
            twitter: data?.siteInfo?.socialMediaUrls?.twitter,
        };

    }

    const [viewport, setViewport] = useState(appState.meta.viewport);
    const [colorOverride, setColorOverride] = useState('');

    const extendAppState = {
        connectionType: () => appState.meta.settings.connectionType,
        isMobile: () => !!appState.meta.settings.mobile,
        imageHost: () => appState.meta.settings.imageHost,
        imageFormats: appState.meta.settings.imageFormats,
        featureSupport: getFeatureSupport(appState.meta.settings.userAgent),
        localize: id => {
            let language = data.meta.localization.languages[data.meta.localization.currentLanguage];
            if (!language) {
                return `No language strings found for "${data.meta.localization.currentLanguage}"`;
            }
            return language[id] || `No localization for "${id}"`;
        }
    };

    if (typeof window !== 'undefined') {
        appState.meta.settings.connectionType = navigator.connection ? navigator.connection.effectiveType : '4g';
    }

    appState.meta.viewport = viewport;
    appState.colorOverride = colorOverride;

    const value = {
        ...appState,
        ...extendAppState
    };

    // state = state;

    useEffect(() => {

        const viewportHandler = createViewPort();

        viewportHandler.onMount(({ width, height }) => {
            setViewport({
                width,
                height,
                initiated: true
            });
        });

        socket.on('svg-color-override', (payload) => {
            setColorOverride(payload.color);
        });

        socket.on('system.version', (payload) => {
            console.log(payload);
        });


        return () => {
            viewportHandler.onUnmount();
            socket.disconnect();
        };

    }, []);

    if (!hasLoaded) {
        return null;
    }

    const { children } = props;
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

AppContextProvider.propTypes = {
    mockData: PropTypes.object,
    children: PropTypes.any.isRequired,
    appState: PropTypes.shape({
        colorOverride: PropTypes.string,
        meta: PropTypes.shape({
            info: PropTypes.shape({
                siteName: PropTypes.string,
                gtmCode: PropTypes.string,
                cookieMessage: PropTypes.shape({
                    title: PropTypes.string,
                    text: PropTypes.string,
                    buttonText: PropTypes.string,
                }),
                socialMediaUrls: PropTypes.shape({
                    instagram: PropTypes.string,
                    facebook: PropTypes.string,
                    twitter: PropTypes.string,
                }),
            }),
            localization: PropTypes.shape({
                currentLanguage: PropTypes.string,
                languages: PropTypes.object,
            }),
            settings: PropTypes.shape({
                apiUrl: PropTypes.string.isRequired,
                captchaKey: PropTypes.string.isRequired,
                connectionType: PropTypes.string.isRequired,
                imageHost: PropTypes.string.isRequired,
                imageFormats: PropTypes.object.isRequired,
                language: PropTypes.string,
                mobile: PropTypes.any, // todo
                offline: PropTypes.bool.isRequired,
                role: PropTypes.string.isRequired,
                serviceWorkerScript: PropTypes.string,
                showErrorsInFrontend: PropTypes.oneOf(['true', 'false']).isRequired,
                siteId: PropTypes.string.isRequired,
                siteUrl: PropTypes.string.isRequired,
                userAgent: PropTypes.shape({
                    browser: PropTypes.shape({
                        major: PropTypes.string.isRequired,
                        name: PropTypes.string.isRequired,
                        version: PropTypes.string.isRequired
                    }).isRequired,
                    cpu: PropTypes.object.isRequired,
                    device: PropTypes.object.isRequired,
                    engine: PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        version: PropTypes.string.isRequired
                    }).isRequired,
                    os: PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        version: PropTypes.string.isRequired
                    }).isRequired,
                    ua: PropTypes.string.isRequired
                }).isRequired,
                version: PropTypes.string.isRequired
            }).isRequired,
            viewport: PropTypes.shape({
                width: PropTypes.number.isRequired,
                height: PropTypes.number.isRequired,
                initiated: PropTypes.bool.isRequired
            }).isRequired
        }).isRequired,
        routing: PropTypes.shape({
            history: PropTypes.shape({
                path: PropTypes.string.isRequired
            }).isRequired
        }).isRequired
    }).isRequired
};

export default AppContextProvider;

// A 'useAppContext' hook that can be used by any of the sub children to this provider.
export const useAppContext = () => {
    const menu = useContext(AppContext);
    return menu;
};
