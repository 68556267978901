const red = '#B8001E';
const twentyfourOrange = '#F15A22';

// colors from inVision:
const sky = '#4CD0FF';
const pear = '#4ADC65';
const lemon = '#F5DE49';
const orange = '#F29C35';
const tomato = '#F15A22';
const berry = '#C31F23';
const plummy = '#D0118E';

const skyLight = '#cef1fe';
const pearLight = '#ccf4d3';
const lemonLight = '#fbf5ce';
const orangeLight = '#fbe2c8';
const tomatoLight = '#f9cfc2';
const berryLight = '#ecc0c0';
const plummyLight = '#f0bddd';

const darkerGray = '#777777';
const darkGray = '#D2D2D2';
const gray = '#F0F0F0';
const lightGray = '#FAFAFA';

const theme = {
    header: {
        backgroundColor: '#f68d2e',
    },
    footer: {
        backgroundColor: '#f68d2e',
    },
    fonts: {
        header1: 'Montserrat ExtraBold',
        header2: 'Montserrat Bold'
    },
    colors: {
        sky,
        pear,
        lemon,
        orange,
        tomato,
        twentyfourOrange,
        berry,
        plummy,
        darkerGray,
        darkGray,
        gray,
        lightGray,
        skyLight,
        pearLight,
        lemonLight,
        orangeLight,
        tomatoLight,
        berryLight,
        plummyLight,
        // keeping old names as aliases for sake of simplicity
        blue: sky,
        green: pear,
        yellow: lemon,
        lightOrange: tomato,
        red: berry,
        purple: plummy,
        black: '#000000',
        almostBlack: '#2a2a2a',
        lighterText: '#292929',
        darkerText: '#171717',
        primaryColor: orange,
        secondaryColor: gray,
        errorColor: red,
        white: '#FFFFFF',
        transparent: 'transparent',
    },
    invertedColors: {
        lighterText: '#ffffff',
        darkerText: '#E8E8E8',
        black: '#FFFFFF',
        almostBlack: '#EEEEEE',
    },
    maxWidth: 1600,
    maxWidthNormalDesktop: 1300,
    outerPadding: '3.5vw',
    outerPaddingMobile: '7.5vw',
    contentMaxWidth: '768px',

    gap: {
        col: '1rem',
        row: '1rem',
    },
    baseSize: 16,
    breakpoints: {
        mobile: 'max-width: 768px',
        tablet: 'max-width: 1000px',
        normalDesktop: '(max-width: 1700px) and (min-width: 769px)',
    },
    gradients: {
        'green-blue':
            'linear-gradient(225deg, rgb(153,228,255) 0%, rgb(197,229,153) 100%)',
        'blue-purple':
            'linear-gradient(45deg, rgb(153, 228, 255) 0%, rgb(235,153,214) 100%)',
        'red-yellow':
            'linear-gradient(225deg, rgba(250,239,164) 0%, rgb(250,239,164) 24.67%, rgba(234,148,148) 100%)',
    },
    spacing: {
        tiny: '0.5rem',
        // (smaller is basesize, which is 16px)
        smaller: '1rem',
        small: '1.5rem',
        base: '2rem',
        medium: '3rem',
        large: '4rem',
        xlarge: '6rem',
    },
    fontSizes: {
        xxsmall: '1rem',
        xsmall: '1.5rem',
        small: '1.75rem',
        normal: '2rem',
        large: '3rem',
        xlarge: '6rem',
    },
};

export default theme;
